import React from 'react';

import loader from '../images/loading.svg';

interface Props {
  isError?: string | React.ReactNode;
  loading?: boolean;
  text: JSX.Element | JSX.Element[] | React.ReactNode;
}

export const Status: React.FC<Props> = ({isError = false, text = '', loading = false}) => {
  if (!isError) return null;

  return (
    <div id="statusMessage" className="status">
      {loading && <img src={loader} width="20px" />}
      <span>{text}</span>
    </div>
  );
};

export default Status;
