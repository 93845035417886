/* eslint-disable prefer-const */
import React from 'react';

const MyNFTSection: React.FC = () => {
  return (
    <section className="pt-105 pb-90 content_19">
      <div className="container px-xl-0">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10">
            <h2 className="small mb-30">Your Diviner Hexagrams Tokens</h2>

            <div className="" id="nftListEmpty">
              <div className="">Your Diviner Hexagrams Tokens will appear here</div>
            </div>

            <div id="nftList" className="row"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyNFTSection;
