import React, {useEffect, useState} from 'react';

import {Trigram} from '../components/symbols';

interface Props {
  closeLoader?: () => void;
  style: any;
}

const Loader: React.FC<Props> = ({closeLoader, ...props}) => {
  const [showHint, setShowHint] = useState(false);

  const mainStage = () => {
    setShowHint(true);
  };

  useEffect(() => {
    const timer = () => setTimeout(() => mainStage(), 2000);
    const timerId = timer();
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <div className={`page-loader ${showHint && 'fill-inverse'}`} onClick={closeLoader} {...props}>
      <div className="loader_stage">
        <div className="loader_stage__elem loader_stage__elem--animation"></div>

        <div className="loader_stage__elem ">
          <Trigram />
        </div>

        <span className={`loader_stage__hint ${showHint && 'show'}`}>Click me</span>
      </div>
    </div>
  );
};

export default Loader;
