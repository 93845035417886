import ReactDOM from 'react-dom';
import Layout from './core/Layout';
import {MetaMaskProvider} from 'metamask-react';
import {Web3ReactProvider} from '@web3-react/core';
import {Web3Provider} from '@ethersproject/providers';

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <MetaMaskProvider>
      <Layout />
    </MetaMaskProvider>
  </Web3ReactProvider>,
  document.getElementById('root')
);
