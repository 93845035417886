/* eslint-disable */
import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="pt-30 pb-30 text-center footer_2">
      <div className="container px-xl-0">
        <div className="row justify-content-center">
          <div className="col-12 lh-40">
            <a href="https://etherscan.io/address/TODO_CONTRACT_ADDRESS" target="_blank" className="link color-main mx-20">
              Smart Contract
            </a>
            <a href="https://twitter.com/HexagramsArt" target="_blank" className="link color-main mx-20">
              Twitter
            </a>
            <a href="https://opensea.io/collection/TODO_OPENSEA" target="_blank" className="link color-main mx-20">
              OpenSea
            </a>
            <a href="terms.txt" target="_blank" className="link color-main mx-20">
              Terms and Disclaimers
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
