/* eslint-disable prefer-const */
import React from 'react';

const FAQSection: React.FC = () => {
  return (
    <section className="pt-40 pb-40 content_19 mb-30">
      <div className="container px-xl-0">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-11">
            <h2 className="small text-lg-center">I have more questions</h2>
            <div className="row justify-content-center justify-content-lg-between">
              <div className="col-lg-6 mt-45 block">
                <div className="f-heading medium f-18 title">What's the cost of a mint pass (Creator Hexagram Token)?</div>
                <div className="mt-10 color-heading text-adaptive">
                  Each mint pass (Creator Hexagram Token) is priced at 0.025 ETH for non-community members, and 0.01 ETH for community members.
                  <br />
                </div>
              </div>
              <div className="col-lg-6 mt-45 block">
                <div className="f-heading medium f-18 title">How many NFTs are there in this collection?</div>
                <div className="mt-10 color-heading text-adaptive">Project Hexagrams (for fortune telling) collection contains 8192 Diviner Hexagrams Tokens.</div>
              </div>
              <div className="col-lg-6 mt-45 block">
                <div className="f-heading medium f-18 title">What’s the cost of a hexagram NFT?</div>
                <div className="mt-10 color-heading text-adaptive">
                  Each Diviner Hexagrams Token costs 0.088 ETH for non-mint pass owners, special discounts are available for mint pass owners. You can mint multiple
                  tokens per transaction.
                </div>
              </div>
              <div className="col-lg-6 mt-45 block">
                <div className="f-heading medium f-18 title">What does it mean when I mint a Diviner Hexagrams Token?</div>
                <div className="mt-10 color-heading text-adaptive">
                  First off, congratulations on securing one of the 8192 diviner tokens! You now own a divination result. When on-chain divination starts, a portion of
                  the tips from divinations will also go back to token owners.
                </div>
              </div>
              <div className="col-lg-6 mt-45 block">
                <div className="f-heading medium f-18 title">When can I do on-chain divination?</div>
                <div className="mt-10 color-heading text-adaptive">On-chain divination will be implemented when 25% of the collection is minted.</div>
              </div>
              <div className="col-lg-6 mt-45 block">
                <div className="f-heading medium f-18 title">And how does tipping work for on-chain divination?</div>
                <div className="mt-10 color-heading text-adaptive">
                  The tipping function will be enabled when 50% of the collection is minted. 10% of all on-chain divination tips are donated toward the carbon offset of
                  this collection, the rest are decided by the community.
                </div>
              </div>
              <div className="col-lg-6 mt-45 block">
                <div className="f-heading medium f-18 title">Where is the metadata stored?</div>
                <div className="mt-10 color-heading text-adaptive">
                  Project Hexagrams (for fortune telling) is an ERC-721 collection on the Ethereum blockchain. Everything is generated from the smart contract, including
                  the SVG output.
                  <br />
                  <br />
                  The project has no external dependencies other than the Ethereum blockchain. Check out our smart contract
                  <a href="https://etherscan.io/address/TODO_CONTRACT_ADDRESS#code" target="_blank">
                    source code
                  </a>
                  .
                </div>
              </div>
              <div className="col-lg-6 mt-45 block">
                <div className="f-heading medium f-18 title">Please give me more technical details.</div>
                <div className="mt-10 color-heading text-adaptive">
                  Project Hexagrams (for fortune telling) is a ERC-721 collection on Ethereum. Each token's tokenURI contains the full metadata and SVG images. The
                  contract itself is made to be composible so other creative uses can be built on top by the community.
                </div>
              </div>
              <div className="col-lg-6 mt-45 block">
                <div className="f-heading medium f-18 title">This all sounds very fascinating, where do I learn more?</div>
                <div className="mt-10 color-heading text-adaptive">
                  Join our Discord community and{' '}
                  <a href="https://twitter.com/HexagramsArt" target="_blank">
                    Twitter
                  </a>{' '}
                  family for more updates!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
