/* eslint-disable prefer-const */
import React, {useLayoutEffect, useState} from 'react';
export interface Props {
  onClickMint: () => void;
  isAvailable?: boolean;
}

const MintSection: React.FC<Props> = ({onClickMint, isAvailable}) => {
  return (
    <section className="pt-20 pb-20 feature_5" id="mintSection">
      <div className="container px-xl-0">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10">
            <h2 className="small">Very fascinating! How do I mint one?</h2>
            <div className="mt-10 mb-40 f-22 color-heading text-adaptive description">
              <p>
                There are a total of 8192 possible hexagram readings. Each reading can be minted for 0.088 ETH. The readings you receive will be randomly assigned at the
                time of transaction. Minting starts on 2021-10-?? at 12:00pm UTC<span id="textTimeDetail"></span>.
              </p>
              <ul className="list-unstyled">
                <li>05:00am - San Francisco 🇺🇸</li>
                <li>08:00am - New York 🇺🇸</li>
                <li>09:00am - Buenos Aires 🇦🇷</li>
                <li>14:00pm - Berlin 🇩🇪, Paris 🇫🇷, Madrid 🇪🇸</li>
                <li>20:00pm - Beijing 🇨🇳, Hong Kong 🇭🇰, Taipei 🇹🇼, Singapore 🇸🇬</li>
                <li>21:00pm - Seoul 🇰🇷, Tokyo 🇯🇵</li>
              </ul>
            </div>
            <button className="btn md action-3 mb-2" onClick={onClickMint} id="btnMint" disabled={true}>
              ䷼ Mint a Diviner Hexagrams Token (0.?? ETH + gas)
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MintSection;
