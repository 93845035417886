/* eslint-disable prefer-const */
import React, {useEffect, useState} from 'react';
import {useWeb3React} from '@web3-react/core';
import {Web3Provider} from '@ethersproject/providers';
import {InjectedConnector} from '@web3-react/injected-connector';
import AOS from 'aos';

import 'aos/dist/aos.css';
import '../scss/framework.scss';
import '../scss/style.scss';

import HexagramSection from '../sections/Hexagram';
import MintSection from '../sections/Mint';
import LeadSection from '../sections/Lead';
import Header from '../sections/Header';
import TotalSupplySection from '../sections/TotalSupply';
import MintPassSection from '../sections/MintPass';
import MyNFTSection from '../sections/MyNFT';
import FAQSection from '../sections/FAQ';
import CallToActionSection from '../sections/CallToAction';
import Footer from '../sections/Footer';

import Loader from '../components/loader';
import Status from '../components/status';

import {useDelayUnmount} from '../utils/helpers';

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [
    1, // Mainet
    3, // Ropsten
    4, // Rinkeby
    5, // Goerli
    42, // Kovan
  ],
});

let accounts: string | any[],
  addr: any,
  provider: {getBalance: (arg0: any) => any; getSigner: () => any},
  contract: {
    claimOneFreeNft: () => any;
    tokenURI: (arg0: number) => any;
    MINT_FEE: () => any;
    mintNfts: (arg0: any, arg1: {value: any}) => any;
    totalSupply: () => any;
    numFreeMints: () => any;
    balanceOf: (arg0: any) => any;
    tokenDetailOfOwnerByIndex: (arg0: any, arg1: number) => PromiseLike<[any, any, any, any]> | [any, any, any, any];
  },
  nftPrice = 8,
  isSoldOut = false,
  totalSupply: number,
  numFreeMints: number,
  SALES_START_TIMESTAMP = 1631880900,
  MAX_NFT_SUPPLY = 1508,
  claimSupply: any,
  CONTRACT_DEPLOYED = true,
  simulationEnabled = false,
  simulatedTotalSupply = 0,
  prevTotalSupply = 0;

const TARGET_CHAINID = '0x1'; // Mainnet
// const TARGET_CHAINID = '0x4'; // Rinkeby
const contractAddress = '0'; //MooncakeContract.address;

export interface IStatusProps {
  isError?: boolean;
  loading?: boolean;
  text: JSX.Element | JSX.Element[] | React.ReactNode;
}

const Layout = () => {
  const [statusMessage, setStatusMessage] = useState<IStatusProps>({
    text: null,
    isError: false,
    loading: false,
  });
  const [loaderScreen, setLoaderScreen] = useState(true);
  const [isMounted, setIsMounted] = useState(true);
  const shouldRenderChild = useDelayUnmount(isMounted, 500);
  const mountedStyle = {opacity: 1, transition: 'opacity 500ms ease-in'};
  const unmountedStyle = {opacity: 0, transition: 'opacity 500ms ease-in'};

  const onClickMint = async (numToMint: any) => {
    try {
      console.log('About to mint', numToMint);
      setStatusMessage({
        isError: true,
        loading: false,
        text: 'Please sign the transaction in Metamask',
      });

      // const tx = await contract.mintNfts(numToMint, {value: nftPrice.mul(numToMint)});
      // const receipt = await handleTransaction(tx);
      // handleSuccessMintModal(receipt);
    } catch (error) {
      console.error(error);
      setStatusMessage({
        isError: true,
        loading: false,
        text: (
          <>
            Encountered errors. Please try again.
            <br />
            {error}
          </>
        ),
      });
    }
  };

  async function handleTransaction(tx: {hash: any; wait: () => any}) {
    console.log('Tx is ', tx);
    setStatusMessage({
      isError: true,
      loading: true,
      text: (
        <>
          Transaction in progress.... waiting for confirmation. The transaction hash is{' '}
          <a target="_blank" href="https://etherscan.io/tx/${tx.hash}">
            ${tx.hash}
          </a>
        </>
      ),
    });
    const confirmed = await tx.wait();
    // const events = confirmed.events;

    setStatusMessage({
      isError: false,
      loading: false,
      text: `Transaction confirmed!`,
    });

    // populateMooncakes();
    return confirmed;
  }

  // NEW
  const {chainId, account, activate, active} = useWeb3React<Web3Provider>();

  const handleLoadScreenClicked = () => {
    setIsMounted(!isMounted);
    const timer = () => setTimeout(() => setLoaderScreen(false), 600);
    const timerId = timer();
    return () => {
      clearTimeout(timerId);
    };
  };

  useEffect(() => {
    if (!loaderScreen) {
      AOS.init({
        easing: 'ease',
        once: true,
        offset: 50,
        duration: 600,
      });
    }
  }, [loaderScreen]);

  return (
    <div>
      {loaderScreen && <Loader style={isMounted ? mountedStyle : unmountedStyle} closeLoader={() => handleLoadScreenClicked()} />}
      <Status {...statusMessage} />
      <Header />
      <LeadSection />
      <HexagramSection />
      <MintSection onClickMint={() => onClickMint(1)} isAvailable={active} />
      <TotalSupplySection />
      <MintPassSection onClickMint={() => onClickMint(1)} isAvailable={active} />
      <MyNFTSection />
      <FAQSection />
      <CallToActionSection />
      <Footer />
    </div>
  );
};

export default Layout;
