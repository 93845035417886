/* eslint-disable max-len */
import React from 'react';

type Position = 'top' | 'left' | 'right' | 'bottom' | 'top-left' | 'top-right';

interface Props {
  $color?: string;
  $strokeColor?: string;
  $type?: number | string;
  $rotate?: number | string;
  transform?: any;
  refer?: any;
  id?: string;
  text?: string;
  desc?: string;
  position?: Position;
}

const TooltipControl: React.FC<Props> = ({transform, children, text, desc, id}) => {
  return (
    <g id={id} className="svg-group" data-tip={desc ? `${text}  ${desc}` : text} data-for="svgTooltip">
      <g transform={transform}>{children}</g>
    </g>
  );
};

export const Trigram: React.FC = () => {
  return (
    <>
      <svg width="620px" height="580px" viewBox="0 0 550 580" preserveAspectRatio="xMinYMin meet" className="trigram">
        <g stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(120, 81)">
          <g id="group-42">
            <path d="M195.207357,140.479946 C188.345698,140.479946 182.784557,146.041087 182.784557,152.901762 C182.784557,159.762437 188.345698,165.324562 195.207357,165.324562 C202.068032,165.324562 207.629173,159.762437 207.629173,152.901762 C207.629173,146.041087 202.068032,140.479946 195.207357,140.479946"></path>
            <path d="M204.423327,284.25162 C220.961155,281.550242 241.159517,269.423551 241.159517,240.179603 C241.159517,220.301943 226.765282,195.396335 197.554782,195.182861 C197.368853,195.173024 197.181941,195.173024 197.181941,195.173024 C180.066652,194.132216 155.772936,182.641028 155.772936,150.569782 C155.772936,131.336479 169.2513,107.393962 196.590707,105.672399 C245.423091,106.299048 284.960019,146.196028 284.960019,195.173024 C284.960019,241.505699 249.583372,279.725385 204.423327,284.25162 M195.207554,251.912794 C188.345895,251.912794 182.784754,246.351653 182.784754,239.490978 C182.784754,232.630303 188.345895,227.068178 195.207554,227.068178 C202.067245,227.068178 207.62937,232.630303 207.62937,239.490978 C207.62937,246.351653 202.067245,251.912794 195.207554,251.912794 M287.301345,195.173024 C287.301345,144.514799 246.088107,103.301561 195.428897,103.301561 C144.770672,103.301561 103.557434,144.514799 103.557434,195.173024 C103.557434,245.831249 144.770672,287.045471 195.428897,287.045471 C246.088107,287.045471 287.301345,245.831249 287.301345,195.173024"></path>
          </g>

          <TooltipControl id="group-01" transform="translate(149.394299, 0.000000)" text="☰ Heaven">
            <polygon points="0 14.7877356 89.2104186 14.7877356 89.2104186 0 0 0" />
            <polygon points="0 38.2462469 89.2104186 38.2462469 89.2104186 23.4585113 0 23.4585113" />
            <polygon points="0 61.7047583 89.2104186 61.7047583 89.2104186 46.9170227 0 46.9170227" />
          </TooltipControl>

          <TooltipControl id="group-02" transform="translate(25.281106, 25.280712)" text="☱ Lake">
            <polygon points="27.0431993 90.1252081 90.1242243 27.0441831 79.6679417 16.5879004 16.5869166 79.6689254" />
            <polygon points="43.6306078 106.712518 106.711633 43.6314932 96.2553502 33.1752105 33.1743251 96.2562355" />
            <polygon points="37.6963305 46.2968665 27.2400478 35.8405838 0 63.0816153 10.4562827 73.537898" />
            <polygon points="73.5370126 10.4562827 63.0807299 0 35.8396984 27.2410315 46.2969648 37.6973142" />
          </TooltipControl>

          <TooltipControl id="group-03" transform="translate(-0.000000, 149.394299)" text="☲ Fire">
            <polygon points="0 89.2114024 14.7867518 89.2114024 14.7867518 0.000983750371 0 0.000983750371" />
            <polygon points="46.9170227 89.2104186 61.7037745 89.2104186 61.7037745 0 46.9170227 0" />
            <polygon points="23.4585113 38.5246483 38.2452632 38.5246483 38.2452632 0 23.4585113 0" />
            <polygon points="23.4585113 89.2104186 38.2452632 89.2104186 38.2452632 50.6857704 23.4585113 50.6857704" />
          </TooltipControl>

          <TooltipControl id="group-07" transform="translate(256.007163, 256.007557)" text="☶ Mountain">
            <polygon points="33.1743251 96.2554485 43.6315916 106.711731 106.712617 43.6307062 96.2553502 33.1744235" />
            <polygon points="90.1242243 27.0431993 79.6679417 16.5869166 52.4278939 43.8279481 62.8841766 54.2832471" />
            <polygon points="43.8274563 52.4267134 16.5874085 79.6677449 27.0427074 90.1240276 54.2847227 62.8839798" />
            <polygon points="46.2963746 37.6963305 73.5374061 10.4562827 63.0811234 0 35.8400919 27.2400478" />
            <polygon points="27.2410315 35.8393049 -2.27373675e-13 63.0803364 10.4562827 73.5366191 37.6973142 46.2965713" />
          </TooltipControl>

          <TooltipControl id="group-05" transform="translate(256.007065, 25.280811)" text="☴ Wind">
            <polygon points="106.712617 63.081025 43.6315916 0 33.1743251 10.4562827 96.2553502 73.5373077" />
            <polygon points="90.1243227 79.6689254 27.0432977 16.5869166 16.587015 27.0441831 79.66804 90.1252081" />
            <polygon points="37.6973142 60.4156518 10.4562827 33.1746203 0 43.6309029 27.2410315 70.8719345" />
            <polygon points="35.8402886 79.4714867 63.0803364 106.712518 73.5376028 96.2562355 46.2965713 69.015204" />
          </TooltipControl>

          <TooltipControl id="group-06" transform="translate(326.296225, 149.394299)" text="☵ Water">
            <polygon points="23.4575276 89.2114024 38.2452632 89.2114024 38.2452632 0.000983750371 23.4575276 0.000983750371" />
            <polygon points="-8.52651283e-14 89.2114024 14.7877356 89.2114024 14.7877356 50.6867541 -8.52651283e-14 50.6867541" />
            <polygon points="-5.68434189e-14 38.5246483 14.7877356 38.5246483 14.7877356 4.26325641e-14 -5.68434189e-14 4.26325641e-14" />
            <polygon points="46.9160389 38.5246483 61.7037745 38.5246483 61.7037745 4.26325641e-14 46.9160389 4.26325641e-14" />
            <polygon points="46.9160389 89.2114024 61.7037745 89.2114024 61.7037745 50.6867541 46.9160389 50.6867541" />
          </TooltipControl>

          <TooltipControl id="group-04" transform="translate(25.280712, 256.007163)" text="☳ Thunder">
            <polygon points="33.174817 10.4562827 96.255842 73.5373077 106.712125 63.081025 43.6310997 0" />
            <polygon points="10.4562827 33.174817 0 43.6310997 27.2410315 70.8721312 37.6963305 60.4148648" />
            <polygon points="35.8397968 79.4715851 63.0808283 106.711633 73.537111 96.2563339 46.2970632 69.0153024" />
            <polygon points="43.8279481 54.2841325 54.2852146 43.8278498 27.0431993 16.587802 16.5879004 27.0440847" />
            <polygon points="52.4280906 62.8841766 79.6681384 90.1242243 90.1244211 79.6689254 62.8843733 52.4269101" />
          </TooltipControl>

          <TooltipControl id="group-08" transform="translate(149.394299, 326.296225)" text="☷ Earth">
            <polygon points="0 61.7037745 38.5246483 61.7037745 38.5246483 46.9170227 0 46.9170227" />
            <polygon points="50.6867541 61.7037745 89.2104186 61.7037745 89.2104186 46.9170227 50.6867541 46.9170227" />
            <polygon points="0 38.2452632 38.5246483 38.2452632 38.5246483 23.4585113 0 23.4585113" />
            <polygon points="50.6867541 38.2452632 89.2104186 38.2452632 89.2104186 23.4585113 50.6867541 23.4585113" />
            <polygon points="0 14.7867518 38.5246483 14.7867518 38.5246483 0 0 0" />
            <polygon points="50.6867541 14.7867518 89.2104186 14.7867518 89.2104186 0 50.6867541 0" />
          </TooltipControl>
        </g>
      </svg>
    </>
  );
};

export const Hexagram: React.FC = () => {
  return (
    <svg width="800px" viewBox="0 0 400 400" preserveAspectRatio="xMinYMin meet">
      <g stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0, 0)">
        <g>
          {/* CIRCLE */}
          <g id="group-001" transform="translate(195.006608, -0.000000)">
            <path d="M0,24.3918123 C4.71209573,24.4191684 9.39390429,24.6341096 14.0415177,25.0405437 L14.2134706,23.193027 C9.5091909,22.7817078 4.76973903,22.5648127 0,22.5374566 L0,24.3918123 Z"></path>
            <path d="M0,19.9332494 C4.85083045,19.9606055 9.67137375,20.1814087 14.4557679,20.6005439 L14.6326058,18.705154 C9.79056838,18.2811337 4.90945076,18.0573996 0,18.0300434 L0,19.9332494 Z"></path>
            <path d="M0,15.4747842 C4.98956518,15.5021403 9.94884321,15.7297825 14.870018,16.1606418 L15.050764,14.2164016 C10.0699918,13.7806573 5.05111651,13.5500841 0,13.522728 L0,15.4747842 Z"></path>
            <path d="M0,11.0162213 C5.12927692,11.0435775 10.2263127,11.2770817 15.2842682,11.720642 L15.4698992,9.72852855 C10.3503923,9.27910619 5.19082824,9.04267095 0,9.01531481 L0,11.0162213 Z"></path>
            <path d="M0,6.55716991 C5.26898865,6.58452605 10.5028051,6.82486931 15.6975414,7.28113071 L15.8880574,5.240167 C10.6307928,4.77804357 5.33151698,4.5357463 0,4.50741315 L0,6.55716991 Z"></path>
            <path d="M0,2.09860701 C5.40674637,2.12596316 10.7802746,2.37216845 16.1117915,2.84113091 L16.3062156,0.752293948 C10.9102163,0.277469456 5.47318272,0.0273561435 0,0 L0,2.09860701 Z"></path>
          </g>
          <g id="Group-31" transform="translate(83.678437, 25.723275)">
            <path d="M13.7916975,28.7877492 C17.6098335,26.1400653 21.5305551,23.6467482 25.5567932,21.322453 L24.6540405,19.7016015 C20.5779751,22.0532528 16.6093803,24.5768571 12.744348,27.2577591 L13.7916975,28.7877492 Z"></path>
            <path d="M11.2719035,25.1087387 C15.2033722,22.3828944 19.2403574,19.8163019 23.3867671,17.4245933 L22.4605663,15.7617306 C18.2633522,18.1807953 14.1765398,20.7786519 10.1962209,23.5386914 L11.2719035,25.1087387 Z"></path>
            <path d="M8.75210963,21.4297282 C12.7978879,18.6257234 16.9511366,15.9858556 21.217718,13.5277107 L20.267092,11.8218597 C15.9477524,14.3093148 11.7446763,16.9814238 7.64907084,19.8196237 L8.75210963,21.4297282 Z"></path>
            <path d="M1.18608422,10.3816565 C5.57088323,7.34414754 10.0807389,4.49422358 14.7078352,1.83383862 L13.6868648,-7.10542736e-14 C9.00310224,2.69164912 4.43853429,5.57576826 1.24344979e-14,8.65040339 L1.18608422,10.3816565 Z"></path>
            <path d="M10.9275092,9.31066346 C12.8893355,8.08256801 14.8697249,6.88573673 16.8764935,5.73091667 L15.8789712,3.93908927 C13.8438695,5.11051842 11.836124,6.32395878 9.84791858,7.56866332 L10.9275092,9.31066346 Z"></path>
            <path d="M8.14529174,8.64844938 C6.25869484,9.85798173 4.38968403,11.0929162 2.54802937,12.3698619 L3.70578045,14.0610578 C5.52203298,12.8016982 7.36564165,11.5833728 9.22488241,10.3914265 L8.14529174,8.64844938 Z"></path>
            <path d="M13.28131,13.1065238 C15.181585,11.9136005 17.1014,10.7499874 19.0466172,9.62936253 L18.0735201,7.88052335 C16.1009468,9.01678031 14.1547526,10.1960255 12.2271215,11.4055578 L13.28131,13.1065238 Z"></path>
            <path d="M10.5258624,12.4877864 C8.69886285,13.6631236 6.88749534,14.8628859 5.10152997,16.0997744 L6.2319249,17.750913 C7.99346515,16.5306336 9.77845351,15.3474804 11.580051,14.1887524 L10.5258624,12.4877864 Z"></path>
          </g>
          <g id="Group-32" transform="translate(69.330433, 35.530550)">
            <path d="M15.5789329,28.5931297 C19.1078755,25.6210801 22.7413575,22.7936273 26.4842642,20.1303113 L25.4359377,18.5993443 C21.6461348,21.2949014 17.9677105,24.1565495 14.3957797,27.1657253 L15.5789329,28.5931297 Z"></path>
            <path d="M12.7328193,25.1612038 C16.3672784,22.1012238 20.108231,19.1907255 23.9634932,16.4502261 L22.8878106,14.8792019 C18.9836981,17.6519424 15.1968262,20.5995669 11.5184019,23.6956961 L12.7328193,25.1612038 Z"></path>
            <path d="M9.88670568,21.7292779 C13.6257043,18.5813674 17.4760815,15.5878237 21.4427223,12.7701409 L20.3396835,11.1590595 C16.3232155,14.0109374 12.425942,17.0406303 8.64102414,20.226644 L9.88670568,21.7292779 Z"></path>
            <path d="M13.8949669,12.8064855 C15.5509906,11.5402869 17.2236234,10.2955823 18.9226353,9.089958 L17.7922404,7.43979634 C16.0697803,8.66202975 14.3727224,9.92334337 12.6922736,11.2081051 L13.8949669,12.8064855 Z"></path>
            <path d="M11.0955541,12.4386431 C9.29493367,13.8455305 7.51580734,15.282705 5.76306014,16.7570057 L7.04000584,18.2967658 C8.76832791,16.8429822 10.5230291,15.4253477 12.2972704,14.0380005 L11.0955541,12.4386431 Z"></path>
            <path d="M8.52993866,5.67737679 C10.2944099,4.33301774 12.0754903,3.01210681 13.8829497,1.73125308 L12.6978425,-4.26325641e-14 C10.8640039,1.29843981 9.05752143,2.63693684 7.26862504,4.00083599 L8.52993866,5.67737679 Z"></path>
            <path d="M5.67053776,5.22844294 C3.75560771,6.71935276 1.86314878,8.24348076 4.97379915e-14,9.80864297 L1.33947403,11.4246094 C3.17722067,9.87996434 5.04330046,8.37733046 6.93185137,6.90596074 L5.67053776,5.22844294 Z"></path>
            <path d="M11.2110361,9.24002599 C12.9227491,7.93572414 14.6500942,6.65389342 16.4028414,5.41114289 L15.2450903,3.71994702 C13.466941,4.98028363 11.7141938,6.27872345 9.97805566,7.60256539 L11.2110361,9.24002599 Z"></path>
            <path d="M8.38016377,8.83114934 C6.52287703,10.2790709 4.68610739,11.7592337 2.8776709,13.2784767 L4.18685777,14.85634 C5.96891512,13.3566371 7.78125963,11.8979684 9.61314424,10.4686099 L8.38016377,8.83114934 Z"></path>
          </g>
          <g id="Group-34" transform="translate(42.073163, 59.170752)">
            <path d="M18.987313,29.4796642 C22.3452797,25.254117 25.9133024,21.1751206 29.6894272,17.253422 L28.3773093,15.9422812 C24.5552653,19.910876 20.9432773,24.0377456 17.5432995,28.3150741 L18.987313,29.4796642 Z"></path>
            <path d="M15.5172839,26.6793721 C18.9739281,22.3307223 22.6484443,18.1354623 26.5359477,14.1004311 L25.1906117,12.755095 C21.255235,16.8379995 17.5347995,21.0830867 14.0361442,25.4835178 L15.5172839,26.6793721 Z"></path>
            <path d="M8.57800731,21.0787879 C12.2320065,16.4858868 16.1185329,12.0551686 20.2317245,7.7944492 L18.816044,6.38072278 C14.6540022,10.6912694 10.7196026,15.1747459 7.02066114,19.8223593 L8.57800731,21.0787879 Z"></path>
            <path d="M5.10221387,18.274197 C8.85586757,13.5581932 12.8488875,9.01023438 17.0734577,4.63715943 L15.6235821,3.18728383 C11.3472306,7.61214004 7.30633744,12.2148112 3.50774151,16.9865042 L5.10221387,18.274197 Z"></path>
            <path d="M8.24983129,7.61770897 C10.094417,5.53961907 11.9790599,3.49083932 13.920369,1.48407079 L12.4353213,1.52766688e-13 C10.4686099,2.03314767 8.55758791,4.10928356 6.6885771,6.21472961 L8.24983129,7.61770897 Z"></path>
            <path d="M5.35887312,7.73035766 C3.5260115,9.83873473 1.7341841,11.977399 -8.52651283e-14,14.1551434 L1.63257557,15.4731233 C3.34331154,13.323712 5.11169082,11.2133809 6.92012731,9.13333703 L5.35887312,7.73035766 Z"></path>
            <path d="M18.2049273,16.5619955 C19.8902612,14.6607435 21.6117442,12.7848937 23.3840315,10.947147 L22.0035233,9.56761581 C20.2068108,11.4297876 18.4618797,13.3300625 16.7530977,15.2576937 L18.2049273,16.5619955 Z"></path>
            <path d="M15.4246638,16.7733217 C13.7510541,18.7038838 12.1135935,20.6608251 10.5288911,22.6529386 L12.0481341,23.87908 C13.6123193,21.9133457 15.2263318,19.9827836 16.8755165,18.0776236 L15.4246638,16.7733217 Z"></path>
          </g>
          <g id="Group-35" transform="translate(30.216815, 74.909035)">
            <path d="M20.4801769,28.3006144 C23.3105607,23.838632 26.3549087,19.5134303 29.5995427,15.3279403 L28.1555291,14.1623732 C24.8698609,18.3986674 21.7893637,22.7756504 18.9228307,27.2923451 L20.4801769,28.3006144 Z"></path>
            <path d="M16.7372702,25.8756877 C19.6516765,21.2837636 22.785909,16.8325282 26.1282435,12.5258896 L24.6471037,11.3300354 C21.262758,15.6884552 18.0904223,20.1934259 15.1388898,24.8410393 L16.7372702,25.8756877 Z"></path>
            <path d="M9.25038224,21.0260296 C12.3338104,16.1742222 15.6487888,11.4718965 19.1865244,6.92393763 L17.6291782,5.66653203 C14.0474774,10.2692032 10.6924418,15.0281952 7.57091043,19.9386229 L9.25038224,21.0260296 Z"></path>
            <path d="M1.76154024,16.1752969 C5.01301331,11.0636061 8.50873765,6.10823605 12.2418742,1.31797992 L10.6083217,-8.52651283e-14 C6.82926584,4.84692243 3.29055327,9.86188975 -9.14823772e-14,15.0341549 L1.76154024,16.1752969 Z"></path>
            <path d="M10.7988377,10.7359185 C12.388425,8.49759979 14.0229546,6.2895682 15.7112194,4.11963982 L14.1167471,2.83194706 C12.4040571,5.0311856 10.7470564,7.26852734 9.13695195,9.53615624 L10.7988377,10.7359185 Z"></path>
            <path d="M7.98232729,11.1896397 C6.54319873,13.2628446 5.13728836,15.3575436 3.78413626,17.4854608 L5.50464229,18.5992466 C6.83923129,16.5006396 8.22657857,14.4342737 9.64519001,12.389402 L7.98232729,11.1896397 Z"></path>
            <path d="M18.036687,15.95635 C19.5315049,13.84895 21.069311,11.7689061 22.6569443,9.72501133 L21.1377013,8.4988699 C19.5266199,10.5701208 17.9692737,12.6784978 16.4529617,14.814231 L18.036687,15.95635 Z"></path>
            <path d="M15.2994118,16.4677145 C13.9482137,18.4178167 12.6273028,20.3874591 11.3552421,22.3893426 L12.9936797,23.4503702 C14.2481543,21.4758428 15.5505021,19.5335566 16.8831371,17.6098335 L15.2994118,16.4677145 Z"></path>
          </g>
          <g id="Group-30" transform="translate(99.118245, 17.020113)">
            <path d="M11.8715893,29.0337591 C16.1508718,26.6313034 20.5356708,24.4027548 25.0338024,22.3696071 L24.2932325,20.6686412 C19.7403886,22.7252369 15.3008773,24.9821188 10.9688366,27.4129075 L11.8715893,29.0337591 Z"></path>
            <path d="M9.70195401,25.1371695 C14.1072701,22.6643695 18.6220108,20.3713385 23.2520381,18.2795705 L22.4929051,16.5346394 C17.8052345,18.6498555 13.2348045,20.9721966 8.77673016,23.4743068 L9.70195401,25.1371695 Z"></path>
            <path d="M7.53329573,21.2410685 C12.0646455,18.6979241 16.7083509,16.3404107 21.4722279,14.1909995 L20.6925778,12.4011261 C15.8700805,14.5759395 11.1697088,16.961786 6.58364675,19.5352175 L7.53329573,21.2410685 Z"></path>
            <path d="M2.19318111,11.6480505 L3.19070334,13.4389009 C7.97216643,10.7560448 12.8806402,8.27835984 17.9102626,6.01268495 L17.0915323,4.13390409 C12.0013356,6.42595812 7.03326451,8.93295327 2.19318111,11.6480505"></path>
            <path d="M9.14857831,5.24456352 C11.4494254,4.09462849 13.7746976,2.98377366 16.1292799,1.9247001 L15.2910095,-1.50990331e-13 C12.905163,1.07372863 10.5486267,2.19923854 8.21553841,3.36480566 L9.14857831,5.24456352 Z"></path>
            <path d="M6.41794668,4.27752385 C4.25681134,5.38544766 2.11423909,6.52365863 2.84217094e-14,7.70876585 L1.02097036,9.54260447 C3.1058993,8.37312934 5.21916139,7.24957344 7.35098657,6.15728171 L6.41794668,4.27752385 Z"></path>
            <path d="M13.1158053,13.2375402 C15.2847567,12.1520875 17.4732482,11.101807 19.6910498,10.1023307 L18.8918596,8.26849209 C16.6437708,9.2816464 14.4249922,10.345605 12.2267307,11.4457128 L13.1158053,13.2375402 Z"></path>
            <path d="M10.4299205,12.3602873 C8.39774987,13.4056827 6.38121128,14.4794114 4.39105184,15.5971052 L5.36414895,17.3449674 C7.32695225,16.2438826 9.31515768,15.1838321 11.3199722,14.1521147 L10.4299205,12.3602873 Z"></path>
          </g>
          <g id="Group-26" transform="translate(174.061377, 0.000098)">
            <path d="M2.58916129,25.2575365 C7.99004563,24.7113907 13.4397802,24.4231742 18.930549,24.39191 L18.930549,22.5375543 C13.3743209,22.5688184 7.85912694,22.859966 2.39278326,23.4129509 L2.58916129,25.2575365 Z"></path>
            <path d="M2.1159,20.8237896 C7.67310516,20.2610346 13.2811146,19.9650021 18.9301582,19.9327609 L18.9301582,18.0295549 C13.2127242,18.0617961 7.53827846,18.3617367 1.91365994,18.9313306 L2.1159,20.8237896 Z"></path>
            <path d="M1.64263872,16.3901403 C7.35714171,15.8107763 13.122449,15.5069277 18.9297674,15.4746865 L18.9297674,13.5226303 C13.0530816,13.5548714 7.21742997,13.862628 1.43551363,14.4488311 L1.64263872,16.3901403 Z"></path>
            <path d="M1.16947514,11.9559048 C7.03932194,11.3609087 12.962904,11.0472901 18.9304513,11.0160259 L18.9304513,9.01511941 C12.8915826,9.04638357 6.89667919,9.36391024 0.956488019,9.9667224 L1.16947514,11.9559048 Z"></path>
            <path d="M0.696213853,7.52215787 C6.72238148,6.91055266 12.8042384,6.58911798 18.9300605,6.55687681 L18.9300605,4.50809706 C12.729986,4.53936122 6.57680771,4.86470393 0.47834171,5.48412518 L0.696213853,7.52215787 Z"></path>
            <path d="M10.5492129,0.221780164 L10.6596144,2.31745616 C13.4079299,2.18751448 16.1640613,2.11326209 18.9299628,2.09860701 L18.9299628,0 C16.1269351,0.0146550769 13.3336775,0.0908614768 10.5492129,0.221780164"></path>
            <path d="M8.5370708,0.326808215 C5.68030781,0.490945076 2.83429187,0.713702245 0,1.00094175 L0.222757169,3.0878247 C3.01992285,2.80449322 5.82881259,2.58466706 8.64747238,2.42248421 L8.5370708,0.326808215 Z"></path>
          </g>
          <g id="Group-28" transform="translate(134.692174, 4.590654)">
            <path d="M7.45787093,27.8619391 C12.3135864,26.3026389 17.2279222,24.9690269 22.1950163,23.8591491 L21.8081222,22.0458276 C16.7804539,23.1674295 11.8045667,24.5176506 6.89120796,26.0964908 L7.45787093,27.8619391 Z"></path>
            <path d="M6.09446028,23.6156794 C11.0908645,22.01046 16.150774,20.6387448 21.2624649,19.4985798 L20.8648238,17.636408 C15.6906046,18.7902511 10.5701208,20.1785754 5.51216522,21.8033349 L6.09446028,23.6156794 Z"></path>
            <path d="M4.73104963,19.3694197 C9.87009659,17.7182811 15.0726489,16.3084627 20.3308905,15.1370335 L19.9225023,13.2279655 C14.6017324,14.4130727 9.33567479,15.8395002 4.1350765,17.510179 L4.73104963,19.3694197 Z"></path>
            <path d="M3.36763897,15.1226716 C8.64835168,13.4265907 13.9945237,11.9776921 19.3983391,10.7749987 L18.9792039,8.81805747 C13.5118832,10.0344289 8.10025181,11.4999365 2.75603376,13.2175116 L3.36763897,15.1226716 Z"></path>
            <path d="M10.9116818,8.26263006 C13.4167229,7.59435855 15.9354421,6.97689131 18.4658854,6.41413636 L18.0369801,4.40932184 C15.4723417,4.97989283 12.9204043,5.60517611 10.381168,6.28321767 L10.9116818,8.26263006 Z"></path>
            <path d="M8.43673237,6.81294985 C6.07140296,7.4704743 3.71779761,8.17294099 1.37689333,8.92523493 L2.00413062,10.8763142 C4.31279373,10.1347673 6.63415791,9.44207065 8.96724615,8.79236224 L8.43673237,6.81294985 Z"></path>
            <path d="M9.7572525,3.95491675 C12.336546,3.26710514 14.9285406,2.63107481 17.5332363,2.05268777 L17.095538,-1.8274271e-13 C14.4546931,0.586203076 11.8275264,1.23102646 9.21306064,1.92763112 L9.7572525,3.95491675 Z"></path>
            <path d="M7.26891814,2.4578518 C4.83324436,3.13491635 2.40929464,3.85790014 0,4.6316882 L0.640915363,6.62966369 C3.01796884,5.86564568 5.40967739,5.15340894 7.81213299,4.48513744 L7.26891814,2.4578518 Z"></path>
          </g>
          <g id="Group-27" transform="translate(153.756963, 1.213831)">
            <path d="M5.10045526,26.8088253 C10.3098466,25.7106715 15.5759042,24.858723 20.8898351,24.2578649 L20.6934571,22.4132792 C15.3140669,23.0219534 9.9854809,23.8836719 4.71258423,24.9955038 L5.10045526,26.8088253 Z"></path>
            <path d="M4.16731767,22.4475721 C9.5281448,21.3171772 14.9475922,20.4408036 20.4168669,19.8233363 L20.2146269,17.9308774 C14.6798928,18.5551837 9.19498605,19.4423043 3.76967658,20.5863773 L4.16731767,22.4475721 Z"></path>
            <path d="M3.23515708,18.0864166 C8.74839701,16.9247575 14.3202572,16.0239588 19.9438988,15.3898825 L19.7357967,13.4485733 C14.0437648,14.0894886 8.40546821,15.0010344 2.82774594,16.1773486 L3.23515708,18.0864166 Z"></path>
            <path d="M2.30299648,13.7251634 C7.9666952,12.5302862 13.6909682,11.6060393 19.4689766,10.9553539 L19.2579435,8.96519444 C13.4095908,9.62271889 7.61595036,10.5596668 1.88483829,11.7682222 L2.30299648,13.7251634 Z"></path>
            <path d="M0.438675302,5.00226625 C6.40426861,3.74388365 12.4353213,2.76980953 18.5230402,2.08688295 L18.299306,7.10542736e-14 C12.1402657,0.690742625 6.03691468,1.6765408 1.98951966e-13,2.94957848 L0.438675302,5.00226625 Z"></path>
            <path d="M10.8441707,7.6231802 C13.5504749,7.19720597 16.2665492,6.82789803 18.9963015,6.52111842 L18.7784293,4.48308572 C16.0125278,4.79377335 13.2593274,5.16796632 10.517851,5.59980258 L10.8441707,7.6231802 Z"></path>
            <path d="M8.52857085,5.92387519 C5.98835752,6.35180343 3.45986826,6.82858193 0.942126044,7.35909572 L1.37103129,9.36391024 C3.85457833,8.84023549 6.34984942,8.36931902 8.85489057,7.9472528 L8.52857085,5.92387519 Z"></path>
          </g>
          <g id="Group-46" transform="translate(10.528793, 249.740976)">
            <path d="M27.1904435,10.2722319 C25.7562,6.88104711 24.439197,3.45664414 23.2345497,1.50990331e-14 L21.4691015,0.567639979 C22.6903579,4.07606539 24.0269009,7.55322663 25.4826385,10.9942387 C25.9848192,12.1812999 26.5016549,13.3585911 27.0282606,14.5309973 L28.7282495,13.7914044 C28.2045748,12.6238832 27.6896931,11.4524541 27.1904435,10.2722319"></path>
            <path d="M18.973635,13.743238 C17.4368059,10.1078019 16.0269875,6.43523967 14.7402717,2.72848222 L12.882008,3.32445534 C14.1863098,7.08690209 15.6156683,10.8141766 17.1759455,14.503348 C17.6918042,15.7206964 18.221341,16.9292517 18.7616249,18.131945 L20.5514982,17.352295 C20.0141454,16.1554637 19.4855856,14.9537474 18.973635,13.743238"></path>
            <path d="M10.7579012,17.2143418 C9.11653254,13.3336775 7.61487566,9.41295589 6.24609148,5.45510813 L4.29403523,6.08136841 C5.68235952,10.0978365 7.20453351,14.0762014 8.86935024,18.011578 C9.39693301,19.2601906 9.94014786,20.4990331 10.4931328,21.7329905 L12.3719136,20.9152373 C11.8218597,19.6881188 11.2825529,18.4551383 10.7579012,17.2143418"></path>
            <path d="M6.64949689,18.949796 C4.95732401,14.947006 3.40877089,10.9022048 1.99797548,6.81930038 L-1.04805054e-13,7.46021575 C1.4293585,11.6027175 2.99940574,15.707116 4.71600375,19.7665723 C5.25042555,21.02984 5.80047944,22.2843145 6.35932637,23.5329271 L8.28304946,22.6956337 C7.72713355,21.4538602 7.18098768,20.2052476 6.64949689,18.949796"></path>
            <path d="M12.9809786,10.8284409 C12.107536,8.5950072 11.2761047,6.34984942 10.4925466,4.09101357 L8.58738656,4.70261878 C9.37973772,6.9936958 10.2248472,9.27011774 11.1090368,11.5357926 L12.9809786,10.8284409 Z"></path>
            <path d="M14.8653284,15.4787899 C14.4755034,14.5564971 14.0934944,13.6312732 13.7193014,12.7040954 L11.8473596,13.4114471 C12.2313226,14.3620731 12.6221246,15.311722 13.0226967,16.25844 C13.5434405,17.4914205 14.0827473,18.7136539 14.6288932,19.9319793 L16.4637088,19.1327891 C15.9195169,17.9213027 15.3841181,16.7049314 14.8653284,15.4787899"></path>
            <path d="M21.3246024,7.67613388 C20.5058721,5.5833889 19.723291,3.47989686 18.9876061,1.36468076 L17.1752616,1.94697582 C17.9216935,4.09247907 18.7140447,6.22625827 19.5444991,8.34831341 L21.3246024,7.67613388 Z"></path>
            <path d="M23.0820392,12.0077838 C22.7361794,11.1910075 22.4000896,10.3713002 22.0669309,9.55061592 L20.2858505,10.2237724 C20.6268253,11.0669279 20.9746392,11.9091063 21.329292,12.7493307 C21.8383117,13.951047 22.3619864,15.1439703 22.8954312,16.3320085 L24.6403624,15.5709215 C24.1098486,14.3897223 23.5881279,13.2036381 23.0820392,12.0077838"></path>
          </g>
          <g id="Group-41" transform="translate(0.000000, 180.197946)">
            <path d="M24.3913238,13.7962894 C24.3913238,9.93223412 24.5222425,6.08771894 24.7772408,2.26567489 L22.9297241,2.09372199 C22.6698408,5.97243234 22.5369681,9.87459082 22.5369681,13.7962894 C22.5369681,14.5417443 22.5408761,15.2862222 22.5506461,16.0297231 L24.4059789,16.0297231 C24.3962088,15.2862222 24.3913238,14.5417443 24.3913238,13.7962894"></path>
            <path d="M15.4733187,13.7962894 C15.4733187,9.65378766 15.6159614,5.53375704 15.8953849,1.43717454 L13.9501677,1.25642859 C13.6668362,5.4126084 13.5212625,9.59321334 13.5212625,13.7962894 C13.5212625,14.5417443 13.5261475,15.2862222 13.5359175,16.0297231 L15.4879738,16.0297231 C15.4782037,15.2862222 15.4733187,14.5417443 15.4733187,13.7962894"></path>
            <path d="M11.0147558,13.7962894 C11.0147558,9.51505293 11.1632606,5.25628758 11.4534311,1.02292437 L9.46034064,0.837293394 C9.16528509,5.13220793 9.0138493,9.4525246 9.0138493,13.7962894 C9.0138493,14.5417443 9.01873433,15.2862222 9.02752737,16.0297231 L11.0294109,16.0297231 C11.0196408,15.2862222 11.0147558,14.5417443 11.0147558,13.7962894"></path>
            <path d="M6.5561929,13.7962894 C6.5561929,9.3763182 6.71055971,4.97979513 7.0134313,0.608674194 L4.97149059,0.419135199 C4.66373397,4.85180746 4.50643615,9.31183586 4.50643615,13.7962894 C4.50643615,14.5417443 4.51229818,15.2862222 4.52011422,16.0297231 L6.57084798,16.0297231 C6.56205493,15.2862222 6.5561929,14.5417443 6.5561929,13.7962894"></path>
            <path d="M2.09763001,13.7962894 C2.09763001,9.23660647 2.25785885,4.70232568 2.57147749,0.195401025 L0.481663527,-1.11910481e-13 C0.163159856,4.57238399 -1.02140518e-13,9.17212413 -1.02140518e-13,13.7962894 C-1.02140518e-13,14.5417443 0.00390802051,15.2862222 0.0127010666,16.0297231 L2.11130808,16.0297231 C2.10349204,15.2862222 2.09763001,14.5417443 2.09763001,13.7962894"></path>
            <path d="M19.9728181,10.1960255 L18.0715661,10.1100491 C18.046164,11.3371675 18.0295549,12.5652629 18.0295549,13.7962894 C18.0295549,14.5417443 18.0344399,15.2862222 18.042256,16.0297231 L19.946439,16.0297231 C19.9376459,15.2862222 19.9327609,14.5417443 19.9327609,13.7962894 C19.9327609,12.5945731 19.94937,11.3948108 19.9728181,10.1960255"></path>
            <path d="M20.024404,8.18046393 C20.0918173,6.06427082 20.1944029,3.95491675 20.3350916,1.85142472 L18.4397017,1.67458679 C18.2951049,3.80836598 18.1915424,5.94898422 18.123152,8.09546448 L20.024404,8.18046393 Z"></path>
          </g>
          <g id="Group-33" transform="translate(55.917423, 46.646133)">
            <path d="M17.2535197,28.3352981 C17.5544373,28.0304725 17.8475389,27.7197849 18.1504104,27.4169133 C21.1566552,24.4106685 24.2596235,21.5333884 27.4524763,18.7792109 L26.269323,17.3518065 C23.0315281,20.1421331 19.8855715,23.0594704 16.8392696,26.1057724 C16.536398,26.408644 16.2432964,26.7193316 15.9423789,27.0241572 L17.2535197,28.3352981 Z"></path>
            <path d="M14.1012127,25.1828933 C14.4021303,24.8780677 14.6942548,24.5673801 14.9981034,24.2645085 C18.1020487,21.1595862 21.3076025,18.1894906 24.6059718,15.3473827 L23.3915544,13.881875 C20.0482429,16.7620862 16.7987239,19.772239 13.6517903,22.9181955 C13.3489187,23.2210671 13.0558172,23.5317547 12.7548996,23.8365803 L14.1012127,25.1828933 Z"></path>
            <path d="M7.79552391,18.8772046 C8.09644149,18.572379 8.38856602,18.2616913 8.69241461,17.9588197 C11.9927379,14.6584964 15.4034628,11.5017929 18.9138422,8.48284701 L17.6359195,6.94308693 C14.0796209,10.00209 10.6229767,13.1988508 7.27771119,16.5441163 C6.9748396,16.8469879 6.68076106,17.1576755 6.38082048,17.4625011 L7.79552391,18.8772046 Z"></path>
            <path d="M4.63745253,15.7191332 C4.93837011,15.4143076 5.23635668,15.108505 5.54020527,14.8056334 C8.9382291,11.4066326 12.4495855,8.15515949 16.0645045,5.04730619 L14.7553176,3.46944291 C11.0915484,6.61833043 7.53427274,9.91279172 4.09032966,13.3567348 C3.78745807,13.6596064 3.48849451,13.965409 3.18855393,14.2702346 L4.63745253,15.7191332 Z"></path>
            <path d="M1.48504779,12.5662399 C1.78596537,12.2614143 2.08395194,11.9556117 2.38682352,11.6527401 C5.88450188,8.1560388 9.49648983,4.81077324 13.2179024,1.61498947 L11.8784283,1.12798659e-13 C8.10718854,3.23681798 4.44635033,6.62604877 0.902752737,10.1696464 C0.600858153,10.4715409 0.300917579,10.7773436 -3.37507799e-14,11.0821692 L1.48504779,12.5662399 Z"></path>
            <path d="M16.9319873,16.2243425 C18.5196207,14.7578578 20.1277711,13.3197063 21.7593697,11.9147729 L20.5136881,10.412139 C18.8586415,11.8366125 17.2270429,13.2943042 15.6169384,14.781306 L16.9319873,16.2243425 Z"></path>
            <path d="M14.1479135,16.1623027 C12.907117,17.3347089 11.6760906,18.5198161 10.4646042,19.7313024 C10.1617326,20.034174 9.86863108,20.3448617 9.56869051,20.6496873 L10.9482217,22.0301955 C11.2481623,21.7253699 11.5422409,21.4146823 11.8451125,21.1108337 C13.0351047,19.9218184 14.244637,18.7572283 15.4639394,17.6053393 L14.1479135,16.1623027 Z"></path>
          </g>
          <g id="Group-37" transform="translate(12.681624, 108.776917)">
            <path d="M22.7057945,25.0084979 C23.4463644,23.0613267 24.2230835,21.1239255 25.0379058,19.1982484 C26.1878408,16.4792432 27.4100742,13.7993181 28.693859,11.1555422 L27.0436973,10.3075018 C25.7403725,12.9923119 24.4976219,15.7132712 23.3301008,18.4752646 C22.5094165,20.4155968 21.7258584,22.3676531 20.9794265,24.3294794 L22.7057945,25.0084979 Z"></path>
            <path d="M18.5553791,23.3765086 C19.309627,21.3941652 20.1000242,19.4235458 20.9295015,17.4626965 C22.1234018,14.6401287 23.3935085,11.8595721 24.7271205,9.11711874 L23.0339706,8.24660717 C21.6798415,11.0320488 20.3892177,13.8546166 19.1767543,16.7211496 C18.3404379,18.696654 17.5441788,20.6829054 16.7840688,22.6799039 L18.5553791,23.3765086 Z"></path>
            <path d="M6.10335103,18.4804428 C6.8986332,16.3945368 7.73201857,14.3193779 8.60546116,12.2559431 C9.9293031,9.12464168 11.3371675,6.03925949 12.8212383,2.99784253 L10.9981467,2.06089461 C9.4906278,5.14725381 8.0612693,8.27855524 6.71691025,11.4577299 C5.83760563,13.5367968 4.99835823,15.6285648 4.19623702,17.7301028 L6.10335103,18.4804428 Z"></path>
            <path d="M1.95303325,16.8484534 C2.7629705,14.7283523 3.60905694,12.6180212 4.4971546,10.5203912 C5.86496178,7.28552723 7.31972241,4.09951351 8.85362046,0.959419034 L6.98656366,2.84217094e-14 C5.43019449,3.18601372 3.95296274,6.41990069 2.56366145,9.70361492 C1.66970176,11.817854 0.816776286,13.9438172 8.17124146e-14,16.0805274 L1.95303325,16.8484534 Z"></path>
            <path d="M17.7444648,13.586331 C18.7087689,11.3929545 19.7160612,9.22498011 20.7604796,7.07849984 L19.0243415,6.18551716 C17.965268,8.36228458 16.9433206,10.5605461 15.9653385,12.7842098 L17.7444648,13.586331 Z"></path>
            <path d="M15.1664414,14.6344621 C15.1195451,14.7458407 15.0697179,14.8562423 15.0237986,14.9666438 C14.1728272,16.9763434 13.3619129,18.997767 12.5881249,21.0299377 L14.4053544,21.7441284 C15.1732804,19.7275898 15.9773556,17.7217983 16.8205111,15.7277308 C16.8615453,15.6310073 16.9045335,15.5342838 16.9465447,15.4375603 L15.1664414,14.6344621 Z"></path>
            <path d="M13.6789511,11.7525901 C14.6745193,9.4908232 15.7150298,7.25445847 16.7936434,5.03958784 L15.0135401,4.12511105 C13.9192943,6.36929182 12.8641288,8.63692072 11.8548825,10.9299518 L13.6789511,11.7525901 Z"></path>
            <path d="M11.0561808,12.7810834 C10.9956065,12.9247031 10.9311242,13.0673459 10.8705498,13.2119426 C10.0059003,15.2568144 9.18033097,17.3134102 8.39188784,19.379776 L10.2540596,20.1125298 C11.0356637,18.0608191 11.854394,16.0198554 12.7122045,13.9915927 C12.7678938,13.861651 12.8255371,13.7336634 12.8802494,13.6037217 L11.0561808,12.7810834 Z"></path>
          </g>
          <g id="Group-50" transform="translate(46.927803, 304.947334)">
            <path d="M27.14003,8.97770011 C24.2353938,6.07306387 21.4489752,3.07952016 18.7788201,-5.86197757e-14 L17.3504386,1.18413021 C20.0567428,4.3076156 22.8841957,7.34414754 25.8288891,10.289818 C26.1327377,10.5917126 26.4424484,10.8857911 26.748251,11.1867087 L28.0584148,9.87556782 C27.7545662,9.57465024 27.4438786,9.2815487 27.14003,8.97770011"></path>
            <path d="M17.6819365,18.4356959 C14.4832217,15.2369812 11.4164026,11.9366578 8.48147921,8.53961101 L6.94171913,9.81753372 C9.91572273,13.2604998 13.023576,16.6077193 16.2672331,19.8503994 C16.5701047,20.154248 16.8817693,20.4473495 17.1856179,20.7482671 L18.6003213,19.3335637 C18.2964727,19.0316691 17.9857851,18.7395445 17.6819365,18.4356959"></path>
            <path d="M14.5296294,21.5890777 C11.2322371,18.2916854 8.07162556,14.8897535 5.04779469,11.3881672 L3.47090841,12.696377 C6.53381949,16.2458366 9.7374193,19.6956418 13.0807308,23.0379763 C13.3826254,23.3408479 13.689405,23.6407885 13.9932536,23.940729 L15.4431292,22.4918304 C15.1383036,22.1909128 14.832501,21.8919493 14.5296294,21.5890777"></path>
            <path d="M11.3762477,24.7413847 C7.98017788,21.3453149 4.7267508,17.8417745 1.61596648,14.2346716 L-4.79616347e-14,15.5741456 C3.15377255,19.2300988 6.45116485,22.7834665 9.89217691,26.2254555 C10.1960255,26.5283271 10.5008511,26.8272907 10.8056767,27.1272312 L12.2907245,25.6441375 C11.9849219,25.3432199 11.6800963,25.0452333 11.3762477,24.7413847"></path>
            <path d="M15.9607466,10.2122438 C14.5851233,8.72621899 13.2339252,7.21967708 11.9130143,5.69359508 L10.4103804,6.93927661 C11.7498545,8.48880674 13.1205926,10.0158658 14.515756,11.5243617 L15.9607466,10.2122438 Z"></path>
            <path d="M17.2526404,11.5871831 L15.8076498,12.900278 C17.0044811,14.1684306 18.2159675,15.4258362 19.45481,16.6637017 C19.7567046,16.9675503 20.0683692,17.2606519 20.3731948,17.5605924 L21.752726,16.1800842 C21.4488775,15.8801436 21.1381898,15.5870421 20.8343412,15.2831935 C19.6189468,14.0677991 18.4289546,12.8328646 17.2526404,11.5871831"></path>
            <path d="M23.9876253,12.1309842 C22.783955,10.9273138 21.6056868,9.70508043 20.4410967,8.47112295 L19.0322553,9.75002266 C20.2173625,11.0074283 21.4161478,12.2511558 22.6413122,13.4763202 C22.9451608,13.7791918 23.2558484,14.0732703 23.560674,14.3741879 L24.9060101,13.0278749 C24.6011845,12.7269573 24.2914739,12.4338557 23.9876253,12.1309842"></path>
            <path d="M19.208507,7.15626945 C17.8954121,5.73863501 16.6057653,4.30341448 15.3454287,2.84767684 L13.880898,4.06209422 C15.1588207,5.54030297 16.4680076,6.99604061 17.7996656,8.43614617 L19.208507,7.15626945 Z"></path>
          </g>
          <g id="Group-54" transform="translate(107.524202, 345.995325)">
            <path d="M20.4563379,4.27830545 C17.3035424,2.94567046 14.2074132,1.51142693 11.1572031,0 L10.3091627,1.65016166 C13.4004069,3.1821057 16.5385474,4.63491232 19.7333541,5.98708742 C21.1099544,6.56938247 22.4924166,7.13213743 23.8807409,7.67730629 L24.5587825,5.95093823 C23.1860903,5.4116314 21.8182831,4.85473847 20.4563379,4.27830545"></path>
            <path d="M13.5143257,20.7118248 C9.95021101,19.2043059 6.44471662,17.5922474 2.99784253,15.8776034 L2.06089461,17.700695 C5.55271094,19.4378101 9.10412457,21.0713627 12.7161125,22.5993987 C14.2304705,23.2403141 15.7536215,23.8607123 17.2806805,24.4605935 L18.0310204,22.5525024 C16.5195935,21.9584833 15.0140286,21.3459011 13.5143257,20.7118248"></path>
            <path d="M11.7787738,24.820229 C8.11109656,23.2687449 4.50497064,21.6088132 0.959419034,19.8443419 L-3.55271368e-14,21.7104217 C3.59147085,23.4983411 7.24547002,25.1807439 10.9610205,26.7527452 C12.5115277,27.4083156 14.0688738,28.042392 15.631105,28.6559512 L16.3990311,26.7029179 C14.8524319,26.0961977 13.3126719,25.4689604 11.7787738,24.820229"></path>
            <path d="M11.7797508,15.0844707 C9.5101679,14.0644773 7.26305611,13.0034497 5.04232346,11.8994339 L4.12686966,13.6805143 C6.37984348,14.7991852 8.65821943,15.8758448 10.9600435,16.9095162 L11.7797508,15.0844707 Z"></path>
            <path d="M15.2497799,16.6034205 C14.7046111,16.3738243 14.1653042,16.1354351 13.6230664,15.8989998 L12.8033591,17.7240454 C13.357321,17.9673197 13.9122599,18.210594 14.4701298,18.4470292 C15.9512696,19.0723125 17.4382714,19.6770787 18.9311352,20.2642587 L19.6638891,18.402087 C18.1866573,17.8217459 16.7152876,17.2238188 15.2497799,16.6034205"></path>
            <path d="M13.6068481,11.0161236 C11.4085866,10.0283714 9.23181914,9.00153903 7.08045385,7.93367243 L6.18747117,9.66981054 C8.37107763,10.7533092 10.5771552,11.7957737 12.8076579,12.797204 L13.6068481,11.0161236 Z"></path>
            <path d="M16.9853318,12.4950163 C16.4714271,12.2771441 15.9614305,12.0534099 15.4504568,11.8306528 L14.6502896,13.6127101 C15.1739643,13.8403523 15.6976391,14.0699485 16.2252218,14.2936827 C17.6702124,14.9052879 19.123019,15.495399 20.5807107,16.067924 L21.2949014,14.2516715 C19.8528419,13.6850085 18.4156673,13.0997824 16.9853318,12.4950163"></path>
            <path d="M18.7208837,8.38661201 C18.2372662,8.18241794 17.7585337,7.97138483 17.2778472,7.76230573 L16.4981971,9.49942085 C16.9906077,9.71338497 17.4830182,9.93028011 17.9793368,10.1403362 C19.3911093,10.7363093 20.8077667,11.3137194 22.2302862,11.8715893 L22.9278678,10.101256 C21.5190264,9.5472941 20.1170241,8.9767231 18.7208837,8.38661201"></path>
            <path d="M15.4339454,6.94885126 C13.3070052,5.99236324 11.2015592,4.99972604 9.11858425,3.96605461 L8.24904969,5.6601815 C10.3613348,6.707531 12.496091,7.71580029 14.6542953,8.68401237 L15.4339454,6.94885126 Z"></path>
          </g>
          <g id="Group-29" transform="translate(116.252179, 9.850459)">
            <path d="M9.74582154,28.7299105 C10.407254,28.4407169 11.0638015,28.1446844 11.7281649,27.8633069 C15.7661271,26.1555019 19.8529396,24.6128109 23.9827402,23.2313256 L23.4160773,21.4649003 C19.2335183,22.8639717 15.0949246,24.4262029 11.0051811,26.1555019 C10.3349556,26.4398104 9.67254616,26.737797 9.00525165,27.0299215 L9.74582154,28.7299105 Z"></path>
            <path d="M7.9654251,24.6415348 C8.64053564,24.3455023 9.31271517,24.0426307 9.99271074,23.7553912 C14.1527986,21.9958049 18.3636907,20.4071946 22.619525,18.9836981 L22.0372299,17.1713536 C17.7276603,18.6114592 13.46401,20.2205866 9.25116384,22.002644 C8.56432924,22.2928145 7.88726469,22.5986171 7.20629211,22.8966036 L7.9654251,24.6415348 Z"></path>
            <path d="M6.18502866,20.5531592 C6.87577128,20.2502876 7.56162888,19.9415539 8.25725653,19.6474754 C12.53947,17.8361079 16.8744417,16.2006013 21.2563097,14.7380246 L20.6583826,12.8787839 C16.2218023,14.3599237 11.8330953,16.0149703 7.49714654,17.848809 C6.79467985,18.1458185 6.10198322,18.4584602 5.40537856,18.7632858 L6.18502866,20.5531592 Z"></path>
            <path d="M2.61856914,12.3640976 C3.33862192,12.0485249 4.06160571,11.7368603 4.78654352,11.4300807 C9.31398527,9.51515063 13.8961393,7.78878257 18.5290976,6.24511447 L17.9028373,4.29305823 C13.2122357,5.85528943 8.57146138,7.6041286 3.98735332,9.54250677 C3.25557648,9.8522174 2.52673066,10.166813 1.79983884,10.4853167 L2.61856914,12.3640976 Z"></path>
            <path d="M0.837293394,8.27523342 C1.57297825,7.95282173 2.31061712,7.63529507 3.05118701,7.32167642 C7.6997774,5.3559421 12.4079651,3.5826778 17.1659801,1.99797548 L16.5240877,-5.15143483e-14 C11.7064754,1.60326541 6.94064442,3.39802383 2.23343372,5.38916028 C1.4860248,5.70570994 0.741546891,6.02616762 -2.66453526e-14,6.35151033 L0.837293394,8.27523342 Z"></path>
            <path d="M12.981174,12.9532317 C15.272251,12.0856511 17.5750521,11.2639898 19.8925083,10.4911788 L19.2809031,8.58601875 C16.9321828,9.36957687 14.5961635,10.2019852 12.2738223,11.0812898 L12.981174,12.9532317 Z"></path>
            <path d="M10.3955299,11.8112104 C8.83818378,12.4208616 7.28572263,13.0432138 5.74205453,13.6958533 C5.02493277,13.9997019 4.31660405,14.3182055 3.60534432,14.6298702 L4.40355751,16.4646858 C5.10993221,16.1559522 5.81142189,15.8394025 6.52170462,15.5384849 C8.0419246,14.8965926 9.56996062,14.2830333 11.1028817,13.6831522 L10.3955299,11.8112104 Z"></path>
          </g>
          <g id="Group-48" transform="translate(26.359305, 279.840061)">
            <path d="M28.6368996,11.3762477 C26.048813,7.68316832 23.6121622,3.88945741 21.3298782,1.98063788e-13 L19.7080497,0.901775732 C22.0206209,4.84203741 24.4856048,8.68362157 27.1069095,12.4235972 L28.6368996,11.3762477 Z"></path>
            <path d="M21.2779016,16.4158355 C18.5334942,12.496091 15.9532236,8.46887584 13.5341589,4.34005217 L11.828308,5.28872415 C14.2757058,9.4712831 16.8891945,13.5493025 19.6677971,17.5188743 L21.2779016,16.4158355 Z"></path>
            <path d="M10.2341287,23.9790276 C7.25621708,19.7202623 4.45416637,15.3423023 1.83383862,10.8529638 L-8.61533067e-14,11.8739341 C2.65256892,16.420916 5.4888148,20.8526112 8.50385262,25.1651119 L10.2341287,23.9790276 Z"></path>
            <path d="M21.4905956,8.74009246 L19.8697441,9.7385917 C21.0118631,11.503063 22.1813382,13.2489711 23.3869625,14.9714312 L24.9579868,13.8957485 C23.7689715,12.1986906 22.6161055,10.4772076 21.4905956,8.74009246"></path>
            <path d="M20.4058268,7.04078745 C19.3907185,5.43263701 18.3941732,3.8108085 17.4318232,2.17041689 L15.7679834,3.09564074 C16.7449886,4.76241149 17.7552119,6.40768812 18.7859523,8.03928669 L20.4058268,7.04078745 Z"></path>
            <path d="M9.01189529,14.0687761 C7.89127041,12.2916038 6.79311665,10.4997764 5.73208908,8.68352387 L3.94026168,9.6810461 C5.01594432,11.5236778 6.13070717,13.3418843 7.26794114,15.1454358 L9.01189529,14.0687761 Z"></path>
            <path d="M8.34977891,16.8461086 C9.60620751,18.7923028 10.8939003,20.7189569 12.2226272,22.6172779 L13.9138231,21.4595268 C12.6036592,19.587585 11.3345296,17.68731 10.0947101,15.7694489 L8.34977891,16.8461086 Z"></path>
            <path d="M12.8149854,11.723573 C11.7295327,10.00209 10.6655742,8.26692888 9.63678777,6.50929666 L7.88794859,7.48239376 C8.93041306,9.26640512 10.0109807,11.0269684 11.1120655,12.7738535 L12.8149854,11.723573 Z"></path>
            <path d="M13.8978979,13.4237573 L12.194978,14.4740379 C13.4133034,16.3596577 14.6609389,18.2257375 15.9486317,20.0654382 L17.5997704,18.9350433 C16.3296637,17.1207447 15.0986372,15.2810441 13.8978979,13.4237573"></path>
          </g>
          <g id="Group-47" transform="translate(17.722189, 265.362018)">
            <path d="M28.9474895,12.7381928 C26.5831371,8.59862212 24.3985536,4.34767281 22.381038,-1.96287431e-13 L20.6800721,0.741546891 C22.7220128,5.14393199 24.9339524,9.44861658 27.327615,13.6399686 L28.9474895,12.7381928 Z"></path>
            <path d="M21.1553851,17.0764864 C18.650344,12.6848484 16.3348418,8.1759697 14.2020396,3.56255149 L12.4121662,4.34220159 C14.5713476,9.0132631 16.9132289,13.5797851 19.4495342,18.0261354 L21.1553851,17.0764864 Z"></path>
            <path d="M13.360252,21.415757 C10.7135451,16.7720516 8.26419327,12.0081746 6.01317345,7.12901101 L4.13439259,7.94774131 C6.41179155,12.8874792 8.89045355,17.7119305 11.5694016,22.4132792 L13.360252,21.415757 Z"></path>
            <path d="M5.04427747,15.3457218 C3.96859482,13.2217127 2.92319934,11.0791404 1.9247001,8.90921205 L6.03961325e-14,9.74845945 C1.01315432,11.947698 2.07222787,14.1205574 3.1625656,16.2748537 L5.04427747,15.3457218 Z"></path>
            <path d="M5.96305309,17.1395032 L4.08134122,18.0696121 C5.22541422,20.2717817 6.4036824,22.4524571 7.62982384,24.6067534 L9.46366246,23.5857831 C8.25510712,21.4627509 7.09149401,19.3113856 5.96305309,17.1395032"></path>
            <path d="M13.0473173,11.3919775 C12.036117,9.394979 11.0532498,7.38234844 10.1114169,5.34431574 L8.27660123,6.14350594 C9.23113524,8.20987178 10.2276805,10.2527895 11.2525588,12.2781211 L13.0473173,11.3919775 Z"></path>
            <path d="M13.9661906,13.1856612 L12.1724092,14.0718048 C13.2490688,16.1430557 14.3569926,18.1947665 15.5108357,20.2181441 L17.2586979,19.245047 C16.1214639,17.2500025 15.0281952,15.2276019 13.9661906,13.1856612"></path>
            <path d="M21.0437134,7.44194575 C20.0960185,5.57195794 19.1747026,3.68731505 18.29149,1.78117805 L16.5455818,2.54128804 C17.4414955,4.47673519 18.3764894,6.38775722 19.3368855,8.28412417 L21.0437134,7.44194575 Z"></path>
            <path d="M21.9644431,9.23475016 L20.2576151,10.0779056 C21.2678384,12.0172608 22.3073719,13.9370758 23.3889165,15.8334428 L25.0517793,14.9072419 C23.9848897,13.0392081 22.9609883,11.1457722 21.9644431,9.23475016"></path>
          </g>
          <g id="Group-43" transform="translate(0.047092, 198.243036)">
            <path d="M25.531098,15.9606489 C24.9067917,10.6857982 24.5267367,5.36307424 24.395818,0.000293101538 L22.5404853,0.000293101538 C22.671404,5.42951059 23.056344,10.8176939 23.6865123,16.1580039 L25.531098,15.9606489 Z"></path>
            <path d="M16.6627247,16.9080507 C16.0052003,11.3195814 15.6085362,5.68128481 15.4766405,-7.28306304e-14 L13.5245843,-7.28306304e-14 C13.65648,5.75065218 14.0570521,11.4592931 14.7214155,17.1151758 L16.6627247,16.9080507 Z"></path>
            <path d="M12.2280008,17.381312 C11.5548442,11.6374989 11.1493871,5.83995044 11.0174914,0.000390802051 L9.01658491,0.000390802051 C9.14848061,5.91224882 9.55784575,11.7801416 10.2388183,17.5942991 L12.2280008,17.381312 Z"></path>
            <path d="M7.7943515,17.8545733 C7.10360888,11.9544393 6.69033571,5.99959308 6.55844001,0.000781604102 L4.50868326,0.000781604102 C4.64057895,6.07286847 5.05776014,12.1000131 5.75631881,18.0724454 L7.7943515,17.8545733 Z"></path>
            <path d="M2.45032886,8.1952167 C2.27739895,5.47328042 2.15820433,2.74157409 2.09958402,9.7700513e-05 L1.04360964e-14,9.7700513e-05 C0.0595973127,2.77870028 0.180745948,5.54753281 0.354652861,8.30757229 L2.45032886,8.1952167 Z"></path>
            <path d="M2.58964979,10.2064795 L0.492996787,10.317858 C0.697190858,13.0730125 0.954143207,15.8174199 1.27264688,18.5510802 L3.35952983,18.3283231 C3.04591118,15.6317889 2.78993584,12.9235307 2.58964979,10.2064795"></path>
            <path d="M18.501253,9.35824361 C18.686884,11.7929404 18.9194112,14.2198211 19.2046967,16.6359548 L21.0971556,16.4337147 C20.8157781,14.0517762 20.5861819,11.6581137 20.402505,9.25663507 L18.501253,9.35824361 Z"></path>
            <path d="M20.2606438,7.24586082 C20.104323,4.83852019 19.9939214,2.42336352 19.9362781,0.000390802051 L18.0330721,0.000390802051 C18.0897384,2.4585357 18.202094,4.90593354 18.3603688,7.34746936 L20.2606438,7.24586082 Z"></path>
          </g>
          <g id="Group-45" transform="translate(5.151846, 233.534610)">
            <path d="M27.9600304,14.299447 C26.3811901,9.58832831 25.015337,4.81956629 23.8644249,2.84217094e-14 L22.0491494,0.387871035 C23.2147165,5.26801164 24.5962018,10.095394 26.1936051,14.86611 L27.9600304,14.299447 Z"></path>
            <path d="M19.466534,17.0262683 C17.7958553,12.0318181 16.3537957,6.9748396 15.1393783,1.86412578 L13.2293333,2.27251392 C14.4584058,7.44673308 15.9170744,12.5662399 17.6082703,17.6222415 L19.466534,17.0262683 Z"></path>
            <path d="M15.2197859,18.389679 C13.5041649,13.252586 12.0230251,8.05296476 10.7773436,2.79765418 L8.81942528,3.21581237 C10.0797619,8.5365823 11.5775108,13.8016629 13.3146259,19.0012842 L15.2197859,18.389679 Z"></path>
            <path d="M3.9441697,11.9876575 C3.26808215,9.55784575 2.63205181,7.11728695 2.05268777,4.66207306 L2.33146835e-14,5.10074837 C0.586203076,7.58918042 1.23200346,10.0639344 1.91688406,12.5289183 L3.9441697,11.9876575 Z"></path>
            <path d="M2.46820805,14.4673942 C3.17555976,16.909907 3.92296869,19.3416728 4.72802091,21.7578065 L6.7269734,21.1168911 C5.93266823,18.7329986 5.19405236,16.334451 4.49549369,13.9261334 L2.46820805,14.4673942 Z"></path>
            <path d="M8.25393471,10.8379179 C7.59641026,8.4794275 6.97796602,6.11116707 6.41521106,3.73020557 L4.40941954,4.15813382 C4.97999053,6.57329049 5.60722782,8.97476909 6.27354532,11.3655006 L8.25393471,10.8379179 Z"></path>
            <path d="M8.80633341,12.7766868 L6.82594402,13.3052466 C7.51277862,15.6754611 8.23967044,18.0349284 9.02225154,20.3797407 L10.9733308,19.7534805 C10.2014967,17.4399323 9.48437497,15.1136831 8.80633341,12.7766868"></path>
            <path d="M21.1810803,7.38879667 C20.5821762,5.24622443 20.0174672,3.09485914 19.5016085,0.932746795 L17.6394368,1.33038788 C18.1621345,3.5247414 18.7346595,5.70541684 19.3423567,7.87925325 L21.1810803,7.38879667 Z"></path>
            <path d="M21.7364101,9.32658864 L19.8967094,9.81802222 C20.5259007,11.9703645 21.1873332,14.1139138 21.9005469,16.2447619 L23.7128914,15.6624669 C23.0104247,13.5628829 22.3568083,11.4486438 21.7364101,9.32658864"></path>
          </g>
          <g id="Group-44" transform="translate(1.562915, 216.204005)">
            <path d="M26.9959218,15.3673136 C25.8479407,10.297634 24.939326,5.17226514 24.2642154,1.72306613e-13 L22.4186527,0.197355036 C23.1006023,5.43410252 24.0209411,10.6229767 25.1826002,15.7542077 L26.9959218,15.3673136 Z"></path>
            <path d="M18.2725361,17.2316348 C17.0610497,11.8600606 16.1006537,6.42791213 15.393302,0.947890374 L13.4510158,1.15501546 C14.1671605,6.70440458 15.1373266,12.2020124 16.3634681,17.640023 L18.2725361,17.2316348 Z"></path>
            <path d="M13.9113806,18.1637954 C12.6676531,12.6407854 11.6828319,7.05720113 10.9588711,1.42085856 L8.96773466,1.63384567 C9.6995115,7.33857861 10.6950797,12.9915303 11.9544393,18.5829306 L13.9113806,18.1637954 Z"></path>
            <path d="M5.18887423,20.0290936 C3.87968736,14.203212 2.84503893,8.31284812 2.08688295,2.36874893 L-2.30926389e-14,2.59052909 C0.765972019,8.60888067 1.81234451,14.570566 3.13618646,20.4677689 L5.18887423,20.0290936 Z"></path>
            <path d="M7.70681184,9.78099373 C7.25738948,7.16359699 6.8607254,4.5354532 6.52268163,1.89460834 L4.48367193,2.11150348 C4.82660073,4.78947453 5.22814983,7.45376751 5.68343422,10.1073134 L7.70681184,9.78099373 Z"></path>
            <path d="M8.05882679,11.7654865 L6.03544917,12.0918063 C6.48975655,14.5802383 6.99096018,17.0589003 7.5458991,19.5248613 L9.54973661,19.096933 C9.00261374,16.6641902 8.50824915,14.2197234 8.05882679,11.7654865"></path>
            <path d="M20.92022,7.65141565 C20.5089008,5.26947715 20.1405699,2.8767916 19.8289053,0.473358984 L17.9354693,0.675599045 C18.252019,3.11224983 18.6242579,5.53815356 19.0404621,7.95428724 L20.92022,7.65141565 Z"></path>
            <path d="M21.2770222,9.63502916 L19.3972644,9.93887775 C19.8144456,12.2006446 20.2667989,14.4555725 20.7719106,16.6977992 L22.6350594,16.2991811 C22.1348328,14.0891955 21.6893184,11.8655319 21.2770222,9.63502916"></path>
          </g>
          <g id="Group-38" transform="translate(6.797122, 126.744628)">
            <path d="M23.4666861,22.8160007 C24.7358158,18.1341922 26.2091395,13.5031879 27.8847033,8.92982686 L26.1593123,8.2508083 C24.4603004,12.8808356 22.9674365,17.5685062 21.6826748,22.3079581 L23.4666861,22.8160007 Z"></path>
            <path d="M19.1770474,21.5947443 C20.4852573,16.774201 22.0045003,12.006416 23.7347763,7.29725129 L21.9634661,6.60064664 C20.2107189,11.3674546 18.6709588,16.19386 17.3461398,21.0730236 L19.1770474,21.5947443 Z"></path>
            <path d="M6.3080336,17.9299981 C7.73446109,12.6932506 9.39243879,7.51414643 11.2809897,2.4005016 L9.37289868,1.65016166 C7.46089965,6.82633482 5.78142784,12.0679673 4.33643726,17.3682201 L6.3080336,17.9299981 Z"></path>
            <path d="M2.13671021,8.89602248 C1.37953124,11.2965241 0.661432471,13.7077727 -2.48689958e-14,16.1336765 L2.01849259,16.7081555 C2.67113202,14.3154699 3.37848373,11.9374394 4.12491565,9.56917901 L2.13671021,8.89602248 Z"></path>
            <path d="M4.74238289,7.64995014 C5.49565384,5.34519505 6.28605099,3.049233 7.12920641,0.76792603 L5.17617316,2.4158453e-13 C4.32031667,2.31257114 3.51917247,4.63979735 2.75515446,6.97777062 L4.74238289,7.64995014 Z"></path>
            <path d="M17.4148233,11.9405659 C18.1006809,9.83902783 18.8178026,7.74530584 19.5837747,5.66526193 L17.7655681,4.95009418 C16.988849,7.05944824 16.2609802,9.18345739 15.5653526,11.3143056 L17.4148233,11.9405659 Z"></path>
            <path d="M14.9503279,13.234316 C14.2634932,15.4257385 13.6108538,17.6259541 13.0099957,19.8378937 L14.8877995,20.3732925 C15.4798646,18.1906631 16.122734,16.0217117 16.8007756,13.8605763 L14.9503279,13.234316 Z"></path>
            <path d="M13.1910347,10.5103281 C13.8993634,8.34039967 14.6399333,6.17926433 15.4322845,4.03278406 L13.5701127,3.30003022 C12.7660375,5.47777464 12.0137435,7.66822014 11.2956448,9.86843568 L13.1910347,10.5103281 Z"></path>
            <path d="M10.6798384,11.7883485 C9.96857871,14.0491383 9.29444517,16.3196982 8.67306991,18.6029592 L10.59777,19.1510591 C11.2103522,16.8990623 11.8747157,14.6597665 12.5742514,12.4302408 L10.6798384,11.7883485 Z"></path>
          </g>
          <g id="Group-40" transform="translate(0.633490, 162.453286)">
            <path d="M24.2892268,17.999854 C24.6448566,13.4606881 25.1802554,8.95571749 25.8964002,4.48689604 L24.0713546,4.14592126 C23.3464168,8.670432 22.803202,13.2320689 22.4417101,17.8279011 L24.2892268,17.999854 Z"></path>
            <path d="M19.8488362,17.5857992 C20.2171671,12.9108296 20.770152,8.26907829 21.5097449,3.66640714 L19.637803,3.3166393 C18.8884401,7.97695376 18.3266622,12.6753714 17.9544232,17.4089613 L19.8488362,17.5857992 Z"></path>
            <path d="M7.4140034,7.78800097 C7.69049586,5.58680842 7.99922948,3.3914779 8.35388234,1.20591743 L6.33832076,0.828793449 C5.97780587,3.04561808 5.66418722,5.27221277 5.38378675,7.50466948 L7.4140034,7.78800097 Z"></path>
            <path d="M5.14324809,9.50635758 C4.88824975,11.7153662 4.66549258,13.9292598 4.48767765,16.1529235 L6.52864136,16.3434395 C6.70450228,14.15104 6.92335143,11.9674335 7.17346474,9.78968907 L5.14324809,9.50635758 Z"></path>
            <path d="M2.99745173,7.17121763 C3.28273723,4.90163472 3.6012409,2.63889085 3.96859482,0.385917025 L1.906137,-4.26325641e-14 C1.53292104,2.28521499 1.20855534,4.57922303 0.918384819,6.88104711 L2.99745173,7.17121763 Z"></path>
            <path d="M0.677064553,8.88234441 C0.413273169,11.1597434 0.182699959,13.4420273 4.46309656e-14,15.7340814 L2.08883696,15.9285054 C2.26958291,13.6686925 2.4972251,11.4176727 2.75613146,9.17251493 L0.677064553,8.88234441 Z"></path>
            <path d="M9.60845462,10.1298823 C9.36322633,12.2705005 9.1473082,14.4169808 8.97730931,16.5712771 L10.9694228,16.757885 C11.1374676,14.6328989 11.3494778,12.5176828 11.590798,10.4073517 L9.60845462,10.1298823 Z"></path>
            <path d="M11.8305551,8.40537051 C12.0982545,6.27159131 12.396241,4.14465115 12.7401469,2.02650403 L10.7714815,1.6581731 C10.4236677,3.80660737 10.1207961,5.96383469 9.84918868,8.12790105 L11.8305551,8.40537051 Z"></path>
            <path d="M16.2480838,9.02215384 C16.5069901,6.95676501 16.7942296,4.8972382 17.1244574,2.84650444 L15.2056193,2.48794356 C14.8695295,4.56701047 14.576428,6.65682443 14.3145906,8.75250043 L16.2480838,9.02215384 Z"></path>
            <path d="M14.0746382,10.7538954 C13.8391799,12.8271003 13.6301008,14.9042132 13.464987,16.9901192 L15.4092272,17.1718421 C15.57141,15.1152463 15.7765811,13.0674436 16.0081313,11.0235488 L14.0746382,10.7538954 Z"></path>
          </g>
          <g id="Group-39" transform="translate(2.876498, 144.826160)">
            <path d="M23.983131,20.1256217 C24.7627811,15.604042 25.7251312,11.1215425 26.8711582,6.68398517 L25.0871468,6.1749655 C23.9254877,10.6682121 22.9494596,15.2064009 22.1600395,19.7846469 L23.983131,20.1256217 Z"></path>
            <path d="M19.5990159,19.3055236 C20.4030912,14.6481402 21.3976824,10.0317909 22.5818126,5.46233796 L20.749928,4.93964022 C19.5501657,9.5667365 18.5428734,14.240729 17.7280511,18.9557558 L19.5990159,19.3055236 Z"></path>
            <path d="M2.06245782,16.0258151 C2.96618756,10.8271708 4.08681245,5.67444578 5.42042444,0.574479015 L3.40095485,-7.28306304e-14 C2.05073376,5.16249509 0.914476799,10.3777485 -4.52970994e-14,15.6398981 L2.06245782,16.0258151 Z"></path>
            <path d="M16.7785975,10.5692415 C17.245606,8.45109435 17.7458326,6.33978627 18.2910015,4.23922525 L16.4122206,3.70480344 C15.8592357,5.83467462 15.3511931,7.97529285 14.8773456,10.1237271 L16.7785975,10.5692415 Z"></path>
            <path d="M14.4563541,12.095812 C14.0372189,14.0986725 13.6454398,16.107395 13.295672,18.1268646 L15.21451,18.4854255 C15.5593928,16.4952661 15.9453099,14.5158537 16.357606,12.5413263 L14.4563541,12.095812 Z"></path>
            <path d="M10.0646183,11.0682957 C9.63180504,13.1375925 9.22537091,15.2127514 8.86387901,17.2976804 L10.8305903,17.6650343 C11.1862202,15.6094155 11.5877693,13.5645438 12.0137435,11.5245571 L10.0646183,11.0682957 Z"></path>
            <path d="M12.4355167,9.55266763 C12.9181572,7.36515315 13.4359699,5.18643172 14.0006789,3.01845734 L12.0750018,2.46938046 C11.5024768,4.668619 10.977825,6.87860459 10.4873684,9.09640623 L12.4355167,9.55266763 Z"></path>
            <path d="M8.09243576,8.53609379 C8.59266239,6.28018895 9.12708419,4.03307716 9.70937925,1.79671243 L7.73875991,1.23493448 C7.1457178,3.50256338 6.60347995,5.78191634 6.09641429,8.06908534 L8.09243576,8.53609379 Z"></path>
            <path d="M5.67376187,10.0401932 C5.22531652,12.1759264 4.80520431,14.3165446 4.43198836,16.4688869 L6.44657293,16.8460109 C6.81490386,14.7239558 7.22915403,12.6126477 7.67076035,10.5081786 L5.67376187,10.0401932 Z"></path>
          </g>
          <g id="Group-52" transform="translate(74.529664, 327.881064)">
            <path d="M27.8761057,8.90149371 C23.562628,6.13070717 19.3781151,3.16061159 15.3254978,-1.44773082e-13 L14.1609077,1.44401358 C18.2633522,4.64565938 22.5006235,7.65288116 26.8678364,10.4588399 L27.8761057,8.90149371 Z"></path>
            <path d="M20.6029864,20.129432 C15.9006607,17.1056012 11.3370698,13.8658522 6.92198362,10.413116 L5.66457802,11.9694852 C10.1343765,15.4671636 14.7546337,18.7479468 19.5155797,21.8089038 L20.6029864,20.129432 Z"></path>
            <path d="M15.7522537,27.6181763 C10.7900446,24.4253236 5.97536336,21.0038516 1.31895692,17.3557145 L-4.70734562e-14,18.989267 C4.71404974,22.6823464 9.58735131,26.1468066 14.6120887,29.3787396 L15.7522537,27.6181763 Z"></path>
            <path d="M15.4479166,11.2218809 C13.5144234,9.8325796 11.6024244,8.41103714 9.72364352,6.94162143 L8.49652508,8.4618414 C10.4026621,9.95079722 12.3410403,11.3938338 14.3028666,12.8026752 L15.4479166,11.2218809 Z"></path>
            <path d="M17.0918254,12.3896951 L15.9457984,13.9704894 C17.9271648,15.3607677 19.9310023,16.7178278 21.966104,18.0260377 L23.0271315,16.3876001 C21.020363,15.0969763 19.0448586,13.7594563 17.0918254,12.3896951"></path>
            <path d="M19.707952,8.77741406 L18.5912352,10.3191281 C20.5081192,11.6634872 22.4474744,12.9765821 24.4161397,14.2418037 L25.4517652,12.6444004 C23.510456,11.3967648 21.5994339,10.102233 19.707952,8.77741406"></path>
            <path d="M18.0640432,7.61057684 C16.1930784,6.26621778 14.3426307,4.89157157 12.5244241,3.47100611 L11.3275928,4.95214589 C13.1731555,6.39322845 15.0499824,7.78936877 16.9473263,9.15229093 L18.0640432,7.61057684 Z"></path>
            <path d="M10.2137093,18.4473223 C8.15515949,16.9661825 6.12005781,15.4518246 4.11915131,13.8856854 L2.83145856,15.4811347 C4.86069821,17.0687681 6.92413303,18.6055971 9.01199299,20.106277 L10.2137093,18.4473223 Z"></path>
            <path d="M11.8566411,19.6141595 L10.6549248,21.2740912 C12.7633019,22.756208 14.8961041,24.2011986 17.0621244,25.5953849 L18.1768873,23.8748789 C16.0401771,22.5002327 13.936685,21.0757592 11.8566411,19.6141595"></path>
          </g>
          <g id="Group-55" transform="translate(125.035651, 352.672667)">
            <path d="M22.382015,4.4590514 C17.8467572,3.16451961 13.3603497,1.67849481 8.92767745,-1.70974346e-13 L8.24865888,1.72539105 C12.7370204,3.42733398 17.2810713,4.93192188 21.8739724,6.24306276 L22.382015,4.4590514 Z"></path>
            <path d="M17.4960124,21.6167269 C12.4067927,20.1648973 7.37228529,18.4942185 2.39932919,16.6017596 L1.64898925,18.5098506 C6.68447368,20.4247807 11.7805324,22.1179306 16.9352115,23.5883233 L17.4960124,21.6167269 Z"></path>
            <path d="M10.5654311,14.7853117 C8.37693966,14.0476728 6.19626421,13.2748618 4.03219786,12.4512464 L3.29944401,14.3143952 C5.49672854,15.1487576 7.70573714,15.9342697 9.92744679,16.6816786 L10.5654311,14.7853117 Z"></path>
            <path d="M12.4802635,15.4159685 L11.8413021,17.3123354 C13.9408862,17.995262 16.0492632,18.6469244 18.1693643,19.2516906 L18.7184412,17.3269905 C16.6276503,16.7310174 14.5495603,16.088148 12.4802635,15.4159685"></path>
            <path d="M15.3273541,6.96360404 L14.7196569,8.7671555 C16.6844143,9.40416285 18.6560106,10.012837 20.639331,10.5795 L21.1610517,8.74859241 C19.2070415,8.19072248 17.2628013,7.59084133 15.3273541,6.96360404"></path>
            <path d="M13.4124241,6.33294723 C11.3626673,5.64220461 9.32268062,4.92019782 7.29539498,4.15129478 L6.59879033,5.92162807 C8.65636312,6.70225517 10.72566,7.43598602 12.8047269,8.1364987 L13.4124241,6.33294723 Z"></path>
            <path d="M13.90376,11.1902259 L13.2794537,13.0406736 C15.3126014,13.6991751 17.3525881,14.3303204 19.4042988,14.9155465 L19.9396976,13.0387196 C17.917297,12.4613096 15.9066205,11.8389573 13.90376,11.1902259"></path>
            <path d="M11.9889276,10.5586898 C9.86980349,9.84547606 7.76044942,9.09709014 5.66379642,8.30083096 L4.94960567,10.1180605 C7.07654583,10.9260437 9.21618706,11.6851767 11.3655983,12.4101145 L11.9889276,10.5586898 Z"></path>
            <path d="M7.7183405,23.2387508 C5.38916028,22.4532387 3.07072711,21.6306004 0.76792603,20.7522728 L-1.0658141e-13,22.705306 C2.33504225,23.5953577 4.68571659,24.4316741 7.04811498,25.2269563 L7.7183405,23.2387508 Z"></path>
            <path d="M9.63229354,23.8694077 L8.96206803,25.8585901 C11.1984328,26.5854819 13.4426135,27.2801326 15.7004724,27.9239789 L16.2749514,25.9064633 C14.0493337,25.27141 11.8364171,24.5865294 9.63229354,23.8694077"></path>
          </g>
          <g id="Group-53" transform="translate(90.843109, 337.854919)">
            <path d="M26.0325947,7.24547002 C21.6653818,5.01594432 17.4134555,2.59297161 13.2699767,0 L12.2607304,1.55734617 C16.4559905,4.18255895 20.761652,6.63679583 25.1845543,8.89465467 L26.0325947,7.24547002 Z"></path>
            <path d="M19.9168357,19.1450994 C15.1519817,16.7113796 10.5131614,14.0646727 5.99548966,11.2294038 L4.90808295,12.9098526 C9.48144395,15.7802937 14.1779076,18.4602188 19.0023589,20.9242257 L19.9168357,19.1450994 Z"></path>
            <path d="M14.495532,11.1268183 C12.4467522,9.95148112 10.4194666,8.74097177 8.41953708,7.48649718 L7.35850951,9.12591179 C9.38677216,10.3969955 11.4423909,11.6250909 13.5204808,12.8170372 L14.495532,11.1268183 Z"></path>
            <path d="M16.2511125,12.1172084 L15.2760614,13.8084043 C17.1851294,14.8762709 19.1108065,15.9177583 21.0628627,16.9143036 L21.9558454,15.1791425 C20.0311453,14.1962753 18.1338014,13.1694429 16.2511125,12.1172084"></path>
            <path d="M16.7233968,7.26266531 C14.7410534,6.12543134 12.7802041,4.95595621 10.8447569,3.74349284 L9.81010848,5.34089623 C11.7729118,6.57094568 13.7620942,7.75898391 15.7717938,8.91184996 L16.7233968,7.26266531 Z"></path>
            <path d="M18.4789773,8.25305541 L17.5273743,9.90224006 C19.374891,10.9339575 21.2360857,11.9402728 23.1236596,12.9045768 L23.9941712,11.2114269 C22.1339534,10.262755 20.2981608,9.27011774 18.4789773,8.25305541"></path>
            <path d="M7.80920198,22.7221105 C5.55915917,21.4324638 3.3345185,20.1027598 1.14114199,18.7242056 L-8.52651283e-14,20.4857458 C2.22366367,21.8848171 4.48054551,23.2311302 6.76185248,24.5393401 L7.80920198,22.7221105 Z"></path>
            <path d="M9.5656618,23.7115236 L8.51733529,25.5287532 C10.6169193,26.7040903 12.7340894,27.8481633 14.8805697,28.9443631 L15.8399887,27.0782833 C13.7237956,25.9977156 11.6359357,24.8692747 9.5656618,23.7115236"></path>
            <path d="M11.7935266,19.8473706 L10.7696252,21.623566 C12.8057039,22.7617769 14.8603457,23.8716548 16.9423436,24.9346363 L17.8792915,23.1115448 C15.8266037,22.0641953 13.8012721,20.9689725 11.7935266,19.8473706"></path>
            <path d="M10.0370668,18.8579576 C7.85443732,17.607391 5.69525599,16.3177442 3.56538481,14.9812012 L2.45062196,16.7017072 C4.60980329,18.0577904 6.80024878,19.3660002 9.0131654,20.6331759 L10.0370668,18.8579576 Z"></path>
          </g>
          <g id="Group-56" transform="translate(142.677725, 357.672882)">
            <path d="M19.7299346,3.00331376 C15.3402506,2.17676742 10.9896468,1.17436016 6.68203116,1.70530257e-13 L6.1739885,1.78401136 C10.5372934,2.97400361 14.9435865,3.99008894 19.3879828,4.82640533 L19.7299346,3.00331376 Z"></path>
            <path d="M15.630128,24.921056 C10.5643564,23.968476 5.54450409,22.8097479 0.574479015,21.4478027 L1.98951966e-13,23.4662953 C5.0315764,24.8458266 10.1149341,26.0201867 15.244211,26.9835138 L15.630128,24.921056 Z"></path>
            <path d="M8.27777364,18.801389 C6.10784525,18.2904153 3.9457329,17.7472004 1.79534462,17.1580663 L1.23454368,19.1296627 C3.41521912,19.7266128 5.60957264,20.2786207 7.80978818,20.7964334 L8.27777364,18.801389 Z"></path>
            <path d="M10.2422378,19.2508113 L9.77425239,21.2468328 C11.8660204,21.7148182 13.9656044,22.1564246 16.0739814,22.5530886 L16.4501284,20.5375271 C14.3730155,20.146725 12.3037187,19.7129347 10.2422378,19.2508113"></path>
            <path d="M9.29483597,14.4592851 C7.19427495,13.9639435 5.10055296,13.4383147 3.01757803,12.8687207 L2.46947816,14.7934208 C4.58274025,15.3718079 6.70674939,15.9052527 8.83857458,16.4074333 L9.29483597,14.4592851 Z"></path>
            <path d="M11.2602772,14.9087074 L10.8030388,16.8568557 C12.8283704,17.310186 14.8615181,17.7371373 16.9015048,18.1211003 L17.2698357,16.153412 C15.2581822,15.776288 13.2562987,15.3551988 11.2602772,14.9087074"></path>
            <path d="M10.3128753,10.1171812 C8.27972765,9.63844868 6.25439602,9.12942901 4.23883444,8.57937512 L3.70343563,10.457179 C5.74928437,11.0160259 7.80490316,11.5318846 9.86736098,12.0174562 L10.3128753,10.1171812 Z"></path>
            <path d="M12.2773395,10.5665058 L11.8318252,12.4667808 C13.7916975,12.9054561 15.7574318,13.3177523 17.7309821,13.6890142 L18.089543,11.7701762 C16.1443258,11.4037992 14.2088787,10.9973651 12.2773395,10.5665058"></path>
            <path d="M11.3309147,5.7749796 C9.36615735,5.31187917 7.40823907,4.82044559 5.46009085,4.28895481 L4.93934712,6.12083942 C6.91485149,6.66014625 8.90305692,7.15841886 10.8961474,7.62738132 L11.3309147,5.7749796 Z"></path>
            <path d="M13.2953789,6.22440196 L12.8615886,8.07680368 C14.7540475,8.4998469 16.6533455,8.89846499 18.5604595,9.25702588 L18.9092503,7.38606106 C17.0304695,7.03336221 15.1604817,6.64060615 13.2953789,6.22440196"></path>
          </g>
          <g id="Group-49" transform="translate(36.025501, 292.860120)">
            <path d="M28.3781886,10.5487244 C25.4872304,7.13311443 22.737938,3.61687298 20.1371504,0 L18.6061833,1.0483265 C21.2392122,4.71209573 24.0236768,8.27327941 26.9507841,11.7328546 L28.3781886,10.5487244 Z"></path>
            <path d="M21.5143368,16.2418309 C18.4475177,12.6151879 15.5321344,8.88302831 12.7769799,5.04144415 L11.1649215,6.14448294 C13.9542711,10.0349174 16.9067806,13.8159272 20.0117029,17.4875125 L21.5143368,16.2418309 Z"></path>
            <path d="M5.17812717,17.258991 C4.00865204,15.7231389 2.85383198,14.1745858 1.73223009,12.6055155 L-4.97379915e-14,13.7915998 C1.13821097,15.3841181 2.30964012,16.9551424 3.49670135,18.5144426 L5.17812717,17.258991 Z"></path>
            <path d="M4.72020487,20.1174149 C6.30197617,22.1505625 7.92575869,24.1553771 9.59252944,26.1289274 L11.2084959,24.7894534 C9.56419629,22.8432592 7.96190788,20.8667778 6.40163069,18.8609863 L4.72020487,20.1174149 Z"></path>
            <path d="M8.75123032,14.5903015 C7.61790437,13.1023227 6.4992335,11.6035968 5.4118268,10.0853308 L3.72063092,11.2430819 C4.82366971,12.785773 5.95894967,14.30697 7.1088847,15.8174199 L8.75123032,14.5903015 Z"></path>
            <path d="M9.97444074,16.1922968 L8.33209512,17.4194152 C9.86892419,19.3910116 11.4448335,21.3362288 13.0617769,23.2521358 L14.6396402,21.9429489 C13.0451679,20.054398 11.4897757,18.136537 9.97444074,16.1922968"></path>
            <path d="M12.3291208,11.9169223 C11.231944,10.4787708 10.1484454,9.03084919 9.09523383,7.56143348 L7.44409516,8.69280541 C8.51293877,10.1846922 9.61206954,11.654108 10.7258554,13.1147306 L12.3291208,11.9169223 Z"></path>
            <path d="M13.5551645,13.5169636 L11.9509221,14.7157489 C13.4437859,16.6257939 14.972799,18.5084828 16.5428462,20.3657696 L18.0826063,19.0878469 C16.5350302,17.2579163 15.0265343,15.4006295 13.5551645,13.5169636"></path>
            <path d="M19.4744478,6.57964103 C18.4505464,5.23918999 17.4393461,3.88896891 16.456479,2.52116173 L14.8864317,3.59586737 C15.8829769,4.98614566 16.9098093,6.35688386 17.9493428,7.718829 L19.4744478,6.57964103 Z"></path>
            <path d="M20.7024455,8.17772831 L19.1763635,9.31789329 C20.5764119,11.1067897 22.0106554,12.8712609 23.4810481,14.6103301 L24.9465558,13.3949357 C23.4957032,11.6812687 22.0819768,9.94219957 20.7024455,8.17772831"></path>
          </g>
          <g id="Group-36" transform="translate(20.563320, 91.649334)">
            <path d="M21.7079792,26.4767412 C23.9677921,21.9580925 26.4220289,17.5547304 29.0677588,13.2715399 L27.5113897,12.2632707 C24.8314646,16.5982424 22.3459636,21.0553398 20.0587946,25.6287008 L21.7079792,26.4767412 Z"></path>
            <path d="M17.7407522,24.4383177 C20.0689554,19.7867963 22.5993987,15.2544695 25.325243,10.8471994 L23.7268626,9.81157399 C20.9668231,14.2725794 18.4051157,18.8605955 16.0485793,23.5678062 L17.7407522,24.4383177 Z"></path>
            <path d="M9.80737286,20.3614707 C12.2713798,15.4442039 14.9513049,10.6549248 17.838355,5.99754137 L16.1588832,4.90915766 C13.2347068,9.6232074 10.5215636,14.4701298 8.02824653,19.4460169 L9.80737286,20.3614707 Z"></path>
            <path d="M7.53085322,10.7154014 C6.31741285,12.913663 5.13816766,15.1324416 4.00972674,17.3815074 L5.83379531,18.3184553 C6.94562715,16.0996767 8.10826325,13.9111852 9.30509453,11.7422338 L7.53085322,10.7154014 Z"></path>
            <path d="M10.2892318,9.98313609 C11.5143962,7.81906973 12.7747328,5.67649749 14.0848967,3.56616641 L12.3643907,2.45140356 C11.0356637,4.59104479 9.756764,6.76292719 8.51499048,8.9563037 L10.2892318,9.98313609 Z"></path>
            <path d="M6.42888914,7.74862766 C7.69117976,5.52007897 8.99059658,3.31400139 10.3408177,1.14114199 L8.57927742,2.06057393e-13 C7.20951623,2.20216956 5.89251332,4.43853429 4.6126366,6.69737014 L6.42888914,7.74862766 Z"></path>
            <path d="M3.62859704,8.45656558 C2.37705347,10.7193094 1.1626361,13.0045244 -1.59872116e-14,15.3210036 L1.86607979,16.2804226 C3.01308381,13.9952076 4.2108921,11.7402798 5.44387257,9.50782309 L3.62859704,8.45656558 Z"></path>
            <path d="M18.0165607,14.4569403 C19.1684498,12.4218386 20.35258,10.407254 21.5816524,8.42197959 L19.9432149,7.36095203 C18.6955793,9.37358259 17.495817,11.4165003 16.3273189,13.4789581 L18.0165607,14.4569403 Z"></path>
            <path d="M15.3440609,15.238349 C14.2048729,17.3056918 13.0969491,19.3925748 12.0378756,21.5077909 L13.7749907,22.3997965 C14.8194092,20.3138906 15.9107239,18.2553408 17.0333028,16.2163311 L15.3440609,15.238349 Z"></path>
          </g>
          <g id="Group-51" transform="translate(59.176028, 316.230669)">
            <path d="M29.1003908,10.3972886 C25.0096703,7.1272524 21.0576846,3.66181522 17.2532266,-2.28261854e-13 L15.9420858,1.31114088 C19.79344,5.02082935 23.794276,8.53023176 27.9358007,11.8403251 L29.1003908,10.3972886 Z"></path>
            <path d="M20.6994168,20.8065943 C16.2423194,17.2405256 11.9366578,13.4575617 7.7951331,9.45867973 L6.38042968,10.8733832 C10.5737357,14.9230694 14.9311786,18.7529295 19.4429882,22.3629634 L20.6994168,20.8065943 Z"></path>
            <path d="M17.8924811,24.2843417 C13.3132581,20.6195955 8.89230986,16.7281841 4.63745253,12.6169465 L3.18757693,14.0658451 C7.49421553,18.228864 11.969876,22.1691256 16.6057653,25.8788141 L17.8924811,24.2843417 Z"></path>
            <path d="M7.61575496,21.4410614 C5.53766506,19.5964757 3.48986231,17.7108558 1.48407079,15.7695466 L-3.90798505e-14,17.2536174 C2.03314767,19.2213058 4.10830656,21.1342818 6.21472961,23.0042696 L7.61575496,21.4410614 Z"></path>
            <path d="M9.13284852,22.7682252 L7.73182317,24.3314334 C9.71709759,26.0568244 11.7267971,27.7489973 13.7745999,29.3864579 L15.0925798,27.7548593 C13.0731102,26.1379159 11.0907668,24.4701681 9.13284852,22.7682252"></path>
            <path d="M16.548122,11.4735574 C14.6507781,9.79213158 12.7807903,8.07455657 10.9479286,6.30617729 L9.5664434,7.68570853 C11.4256842,9.47948994 13.3210741,11.2224671 15.2457742,12.927341 L16.548122,11.4735574 Z"></path>
            <path d="M18.0660949,12.7997442 L16.7637471,14.2535278 C18.5751146,15.823575 20.4069992,17.3633351 22.274056,18.8552219 L23.5001974,17.336956 C21.6604968,15.8655863 19.8520603,14.3473203 18.0660949,12.7997442"></path>
            <path d="M19.5230049,8.15320548 C17.6862353,6.52649195 15.8758448,4.86362922 14.1006265,3.15289324 L12.7543134,4.49920631 C14.5539569,6.23339041 16.3907265,7.92067826 18.2528983,9.57083992 L19.5230049,8.15320548 Z"></path>
            <path d="M21.0419548,9.47948994 L19.7718482,10.8971244 C21.5245953,12.4153903 23.2988367,13.9033692 25.1043421,15.3483597 L26.3001964,13.866243 C24.5200931,12.4427465 22.7712539,10.9752848 21.0419548,9.47948994"></path>
          </g>
          <g id="Group-57" transform="translate(159.905354, 361.038078)">
            <path d="M17.6372874,1.77912634 C13.2202472,1.35510611 8.83349415,0.764018009 4.48484433,-2.84217094e-14 L4.14386954,1.82309157 C8.54820866,2.59785663 12.990651,3.19676077 17.4653344,3.62566603 L17.6372874,1.77912634 Z"></path>
            <path d="M9.4901393,5.29888501 C7.54199108,5.02434656 5.59970488,4.72342899 3.66523473,4.3834312 L3.3154669,6.25439602 C5.27729319,6.60025583 7.24986654,6.90605844 9.22732492,7.1845049 L9.4901393,5.29888501 Z"></path>
            <path d="M11.4880171,5.56795222 L11.2242257,7.45357211 C13.1596729,7.70368542 15.099028,7.9274196 17.0461992,8.11402758 L17.2230372,6.21863763 C15.3051761,6.03593767 13.3941541,5.81415751 11.4880171,5.56795222"></path>
            <path d="M8.87472377,9.71543668 C6.85818519,9.43112819 4.84750864,9.12044056 2.84464813,8.7667647 L2.48608725,10.6856028 C4.5172809,11.0441637 6.55824461,11.3607133 8.60507035,11.6489298 L8.87472377,9.71543668 Z"></path>
            <path d="M10.8717222,9.98450389 L10.6030458,11.917997 C12.6049293,12.1769034 14.6126749,12.4094306 16.6282365,12.6028776 L16.8089824,10.6586374 C14.823708,10.4690984 12.8452726,10.2395022 10.8717222,9.98450389"></path>
            <path d="M8.25842894,14.1319884 C6.17349999,13.8379098 4.09443309,13.5154981 2.02513623,13.1500982 L1.6577823,15.1177865 C3.75834332,15.4890485 5.86672039,15.8163452 7.98193648,16.1133548 L8.25842894,14.1319884 Z"></path>
            <path d="M10.2563067,14.4010556 L9.97981427,16.382422 C12.0500881,16.6510984 14.125247,16.8914416 16.209199,17.0907507 L16.3948299,15.0986372 C14.3421422,14.9022592 12.2962934,14.664847 10.2563067,14.4010556"></path>
            <path d="M7.6420364,18.547563 C5.48969411,18.2446914 3.34321384,17.9125097 1.20552663,17.5344087 L0.828402647,19.5489933 C2.99637702,19.9329563 5.17509846,20.2710001 7.35968192,20.5777797 L7.6420364,18.547563 Z"></path>
            <path d="M9.63991418,18.8176072 L9.3565827,20.8468469 C11.4942699,21.1243163 13.6387962,21.3734527 15.7911385,21.5786237 L15.9806775,19.53766 C13.8595993,19.33542 11.7473142,19.0901917 9.63991418,18.8176072"></path>
            <path d="M7.02662087,22.9641147 C4.80588822,22.6524501 2.59004059,22.3075673 0.385917025,21.9177422 L2.7000624e-13,23.9802 C2.23636474,24.3768641 4.48249952,24.726632 6.73645035,25.0431816 L7.02662087,22.9641147 Z"></path>
            <path d="M9.02361935,23.2341589 L8.73442583,25.3122488 C10.9405034,25.5994883 13.152443,25.8564407 15.3721987,26.0664968 L15.5666227,23.9776598 C13.3781312,23.7695577 11.1984328,23.5165134 9.02361935,23.2341589"></path>
          </g>
          <g id="Group-5" transform="translate(242.285157, 7.883357)">
            <path d="M1.22242881,19.1780244 C6.07032825,20.616176 10.8635154,22.2702457 15.5941742,24.1392565 L16.2907789,22.3679462 C11.5014998,20.4764642 6.6516463,18.8018775 1.74317255,17.3471168 L1.22242881,19.1780244 Z"></path>
            <path d="M2.44368522,14.8883857 C7.43031939,16.3656175 12.3602873,18.0665834 17.2257728,19.9883525 L17.9399635,18.1721 C13.0148807,16.2268827 8.02629252,14.5053997 2.97908403,13.0105819 L2.44368522,14.8883857 Z"></path>
            <path d="M3.66591864,10.5982585 C8.79128753,12.1155475 13.8580361,13.8634096 18.8583484,15.837937 L19.5901252,13.9757652 C14.5292386,11.9768127 9.40093873,10.2084335 4.21401851,8.67355841 L3.66591864,10.5982585 Z"></path>
            <path d="M6.10843145,2.01849259 C11.5112698,3.61491897 16.8515798,5.45559663 22.1225225,7.53661755 L22.8894715,5.5835843 C17.5560005,3.47813825 12.1521852,1.61498947 6.68388747,-1.69642078e-13 L6.10843145,2.01849259 Z"></path>
            <path d="M12.2405064,6.49610709 C9.9865556,5.7359971 7.72478874,5.01008229 5.44836679,4.33692576 L4.88756585,6.3085221 C7.13174662,6.97190858 9.36420334,7.68903035 11.585913,8.43839328 L12.2405064,6.49610709 Z"></path>
            <path d="M14.1477181,7.14864881 L13.4931247,9.090935 C15.837937,9.91161931 18.1720023,10.7713838 20.4894584,11.6878146 L21.2397984,9.77972362 C18.890101,8.85156875 16.5247716,7.97910317 14.1477181,7.14864881"></path>
            <path d="M6.48340602,23.587444 C4.50106262,22.9181955 2.51090318,22.2782571 0.509019671,21.684238 L-1.42108547e-13,23.4682493 C1.97452736,24.0544524 3.93733066,24.6855977 5.89231792,25.3450762 L6.48340602,23.587444 Z"></path>
            <path d="M8.39071543,24.2414512 L7.79865032,25.9990834 C9.86403916,26.7240212 11.9196579,27.4821772 13.9615987,28.2901605 L14.6396402,26.5637924 C12.5693664,25.7460391 10.4854144,24.9761591 8.39071543,24.2414512"></path>
          </g>
          <g id="Group-80" transform="translate(336.000368, 87.407666)">
            <path d="M3.68028061,11.2742484 C6.28302227,15.2457742 8.72944311,19.3179316 11.000003,23.4985365 L12.6628657,22.5723356 C10.3639727,18.3409264 7.88726469,14.2179648 5.25032785,10.1975887 L3.68028061,11.2742484 Z"></path>
            <path d="M11.0392786,6.23368351 C13.7973641,10.4318745 16.3893587,14.7394901 18.7937683,19.1594613 L20.5416305,18.1863642 C18.1069337,13.7116808 15.4836749,9.35228388 12.6904173,5.10231157 L11.0392786,6.23368351 Z"></path>
            <path d="M14.7297201,3.70578045 C17.564989,8.01828108 20.2195119,12.4480223 22.6913349,16.9901192 L24.4821853,15.9925969 C21.9800751,11.3957878 19.292334,6.91231127 16.420916,2.54705237 L14.7297201,3.70578045 Z"></path>
            <path d="M23.6802595,5.59921638 C22.5293474,3.71359649 21.3549873,1.84360867 20.1415469,1.14130927e-13 L18.4093168,1.18608422 C19.6061481,3.00429076 20.7638992,4.84789944 21.8982021,6.7071402 L23.6802595,5.59921638 Z"></path>
            <path d="M24.723994,7.32275113 L22.9419367,8.43165194 C24.1964113,10.537098 25.4147367,12.6650152 26.5881198,14.8202885 L28.4219584,13.7993181 C27.2309892,11.6147346 25.9960547,9.45750733 24.723994,7.32275113"></path>
            <path d="M12.1880413,12.7459112 C11.1406918,11.0312672 10.0728252,9.32932425 8.97076337,7.65082945 L7.35968192,8.75386824 C8.44611162,10.4089149 9.49834614,12.0864327 10.5300636,13.7766516 L12.1880413,12.7459112 Z"></path>
            <path d="M13.2317758,14.4705206 L11.5737981,15.502238 C12.7168941,17.4220531 13.827749,19.3623853 14.8965926,21.3290966 L16.6024435,20.3794476 C15.5189448,18.3853802 14.3914809,16.4176919 13.2317758,14.4705206"></path>
            <path d="M4.53134978,17.5074434 C3.55434465,15.905155 2.55779942,14.3155676 1.53096703,12.7455204 L1.98951966e-13,13.7938469 C1.01217731,15.341423 1.99406746,16.9085392 2.95739452,18.4873795 L4.53134978,17.5074434 Z"></path>
            <path d="M5.57410735,19.2329321 L3.99917509,20.2128683 C5.06606468,22.0105577 6.10559814,23.8268102 7.10409738,25.6674879 L8.72494888,24.7657122 C7.71179457,22.8986554 6.65662903,21.0570007 5.57410735,19.2329321"></path>
          </g>
          <g id="Group-21" transform="translate(211.054895, 0.938120)">
            <path d="M0.413859372,19.8486408 C5.16308129,20.3195572 9.87517702,20.9868517 14.5462385,21.8427082 L14.8960064,19.9717434 C10.1673016,19.1051399 5.39853953,18.4300293 0.591674305,17.9542278 L0.413859372,19.8486408 Z"></path>
            <path d="M0.827914144,15.4091295 C5.71391678,15.892747 10.5608392,16.5786046 15.3657504,17.4588862 L15.7243113,15.5400481 C10.8617568,14.6480425 5.95426004,13.9543688 1.0106141,13.4648893 L0.827914144,15.4091295 Z"></path>
            <path d="M1.24294592,10.9692274 C6.26475227,11.465546 11.2465014,12.1699667 16.1852623,13.0756504 L16.5535933,11.1089391 C11.555235,10.1915313 6.51193457,9.47929454 1.42759989,8.97711391 L1.24294592,10.9692274 Z"></path>
            <path d="M1.65700069,6.52971606 C6.81461076,7.03873574 11.9331406,7.76074252 17.0057512,8.69182841 L17.3828752,6.67626683 C12.2477363,5.7354109 7.06765509,5.00363406 1.84653969,4.48875235 L1.65700069,6.52971606 Z"></path>
            <path d="M2.07115317,2.08981397 C7.36554395,2.6115347 12.6189005,3.35210459 17.8253608,4.30859261 L18.2112779,2.24515778 C12.9413122,1.27792271 7.62542731,0.527582768 2.26557719,-2.23376873e-13 L2.07115317,2.08981397 Z"></path>
            <path d="M5.97145533,23.1268838 C4.04382422,22.8679774 2.11228508,22.6344732 0.171952902,22.4420032 L9.9475983e-14,24.2885429 C1.91297604,24.4790589 3.81715903,24.7096321 5.71547999,24.9636534 L5.97145533,23.1268838 Z"></path>
            <path d="M7.96747681,23.4050371 L7.71247847,25.2418068 C9.72413203,25.5368623 11.7299236,25.860251 13.726922,26.2266279 L14.0669198,24.4025594 C12.0425652,24.0322744 10.0074635,23.7040007 7.96747681,23.4050371"></path>
          </g>
          <g id="Group-85" transform="translate(273.744917, 22.558658)">
            <path d="M2.0392051,17.7419246 C6.66239336,20.2030005 11.162479,22.8692475 15.5335999,25.7328495 L16.5682483,24.1344691 C12.1433921,21.2366719 7.58957123,18.5391608 2.90971667,16.0487747 L2.0392051,17.7419246 Z"></path>
            <path d="M4.0781171,13.7751861 C8.83320105,16.3046524 13.4612743,19.0451517 17.9574519,21.9898452 L19.0194565,20.3514076 C14.4685666,17.371542 9.78382704,14.5988014 4.97012278,12.039048 L4.0781171,13.7751861 Z"></path>
            <path d="M8.15877441,5.83418611 C13.1805808,8.50043311 18.0675604,11.3874833 22.8128743,14.4943596 L23.9276372,12.7738535 C19.12468,9.62985103 14.178103,6.7086057 9.09572233,4.01109455 L8.15877441,5.83418611 Z"></path>
            <path d="M10.1977841,1.8670568 C15.3505092,4.60169415 20.3674305,7.56397369 25.237801,10.7509644 L26.378943,8.99040118 C21.4499522,5.76433025 16.3724565,2.76687852 11.1562261,2.06945572e-13 L10.1977841,1.8670568 Z"></path>
            <path d="M13.1965059,11.4701379 C11.1633583,10.2879617 9.11067051,9.13411863 7.03062659,8.02912583 L6.11614979,9.80922917 C8.16883757,10.8995669 10.1922152,12.0368009 12.1980067,13.203345 L13.1965059,11.4701379 Z"></path>
            <path d="M14.9358682,12.4900335 L13.9363919,14.2232406 C16.1121823,15.5216804 18.2625706,16.8592005 20.3816948,18.2475248 L21.4700785,16.5670759 C19.3216442,15.1611656 17.1419458,13.8060594 14.9358682,12.4900335"></path>
            <path d="M6.4413948,23.1856018 C4.59583212,22.1118731 2.73561435,21.0635466 0.84804045,20.0591854 L5.68434189e-14,21.70837 C1.86119477,22.6990532 3.69503339,23.7337016 5.51519394,24.7927752 L6.4413948,23.1856018 Z"></path>
            <path d="M8.17890072,24.2074514 L7.25269986,25.8146249 C9.22820422,26.9968011 11.1831915,28.2131724 13.1088686,29.4754631 L14.1171379,27.9181169 C12.1650816,26.6392172 10.1837152,25.4062367 8.17890072,24.2074514"></path>
          </g>
          <g id="Group-81" transform="translate(326.763663, 72.852830)">
            <path d="M3.43221901,12.7343825 C6.38277449,16.4303929 9.17994017,20.2309429 11.810038,24.1438484 L13.3810622,23.0681657 C10.7177462,19.107387 7.88443137,15.2599408 4.8977267,11.5189881 L3.43221901,12.7343825 Z"></path>
            <path d="M10.2969501,7.04078745 C13.4214125,10.9478309 16.3846691,14.966253 19.1691337,19.1028927 L20.8202724,17.9715208 C18.0025896,13.7840768 15.0002528,9.71778149 11.8376872,5.76384175 L10.2969501,7.04078745 Z"></path>
            <path d="M13.7374737,4.18754167 C16.9488895,8.19912472 19.9883525,12.3318564 22.8500005,16.5828057 L24.5411964,15.4240776 C21.6453532,11.1223241 18.56681,6.93878811 15.315337,2.8783548 L13.7374737,4.18754167 Z"></path>
            <path d="M17.1703766,1.34045103 C20.4697229,5.45755064 23.5912543,9.6997069 26.5291087,14.0620348 L28.2613388,12.8769276 C25.2873352,8.4608644 22.1257466,4.16790387 18.7863431,-1.21680443e-13 L17.1703766,1.34045103 Z"></path>
            <path d="M11.9990885,13.3512636 C10.8130042,11.7646072 9.60640291,10.191629 8.36756041,8.64112184 L6.86492652,9.88778039 C8.08520593,11.4158164 9.27422117,12.9663235 10.4436963,14.5305087 L11.9990885,13.3512636 Z"></path>
            <path d="M13.2003163,14.9693795 L11.6449241,16.1496016 C12.96095,17.9511991 14.2457118,19.7733137 15.4894393,21.6237614 L17.1005207,20.5197456 C15.8392071,18.6448727 14.5349053,16.796379 13.2003163,14.9693795"></path>
            <path d="M4.81565827,18.7982625 C3.70773446,17.3161458 2.58320156,15.845753 1.42740449,14.3968544 L-2.55795385e-13,15.5809847 C1.13821097,17.0083892 2.24613479,18.4582648 3.33744951,19.9188874 L4.81565827,18.7982625 Z"></path>
            <path d="M6.01493206,20.4183324 L4.53672331,21.5389573 C5.76579576,23.2262452 6.96751206,24.9330731 8.13014816,26.6643262 L9.66013819,25.6159997 C8.479916,23.8593445 7.2615906,22.1290684 6.01493206,20.4183324"></path>
          </g>
          <g id="Group-84" transform="translate(288.538924, 32.662551)">
            <path d="M2.42404742,16.7349254 C6.9563742,19.7802504 11.3441042,23.0444245 15.5852835,26.5176777 L16.7801608,25.036538 C12.4881772,21.5212735 8.04671192,18.2189962 3.45967285,15.137522 L2.42404742,16.7349254 Z"></path>
            <path d="M4.84848564,12.9925073 C9.5097771,16.1218547 14.0245178,19.4768903 18.3848917,23.047844 L19.6110331,21.5286011 C15.1979009,17.9146591 10.628448,14.5205433 5.91049021,11.3540697 L4.84848564,12.9925073 Z"></path>
            <path d="M9.6991207,5.50415378 C14.6202955,8.80252309 19.3861265,12.3383046 23.9887977,16.1046594 L25.2755134,14.50921 C20.61813,10.697913 15.7946557,7.12109727 10.8138835,3.78364775 L9.6991207,5.50415378 Z"></path>
            <path d="M20.0091627,4.73847486 C17.7913611,3.11273833 15.5471803,1.52803602 13.2648963,1.20792265e-13 L12.1237543,1.76154024 C14.3747741,3.26905915 16.5906218,4.83324436 18.7771592,6.43748678 L20.0091627,4.73847486 Z"></path>
            <path d="M21.6283533,5.93921417 L20.3963498,7.63920309 C22.5584622,9.25907759 24.6932184,10.9199863 26.7888944,12.6346303 L28.1068743,11.0020547 C25.9828651,9.26396261 23.8187988,7.58155978 21.6283533,5.93921417"></path>
            <path d="M14.7177029,12.0426629 C12.626912,10.5087648 10.5116959,9.01199299 8.36033057,7.56993342 L7.27292386,9.24940524 C9.394979,10.6729017 11.481862,12.1491565 13.5443198,13.6625374 L14.7177029,12.0426629 Z"></path>
            <path d="M16.3359165,13.2443792 L15.1625334,14.8652307 C17.198612,16.3942437 19.2092886,17.9603829 21.18577,19.5773264 L22.4421986,18.0209573 C20.4393381,16.3815427 18.4003284,14.7939093 16.3359165,13.2443792"></path>
            <path d="M6.7843236,22.9929364 C4.88502563,21.597773 2.96227954,20.2348509 1.00826929,18.921756 L5.68434189e-14,20.4781252 C1.92665411,21.773634 3.82302106,23.117993 5.69691689,24.4945932 L6.7843236,22.9929364 Z"></path>
            <path d="M8.40156019,24.1961182 L7.31415348,25.6987521 C9.16167018,27.0909844 10.9896468,28.5164348 12.7844052,29.9868276 L13.9499723,28.543791 C12.1288347,27.0528812 10.276433,25.6078906 8.40156019,24.1961182"></path>
          </g>
          <g id="Group-13" transform="translate(226.761034, 3.557276)">
            <path d="M0.819707301,19.5985274 C5.52887201,20.5178893 10.1950485,21.6326521 14.8104207,22.942816 L15.3321415,21.1119084 C10.6591259,19.7851354 5.93628315,18.6576715 1.16947514,17.7265856 L0.819707301,19.5985274 Z"></path>
            <path d="M1.64019621,15.2144123 C6.48418763,16.1601533 11.2832368,17.3052033 16.0314817,18.6534704 L16.5659035,16.7766435 C11.7600153,15.4117674 6.90234582,14.2520623 1.99875709,13.2955743 L1.64019621,15.2144123 Z"></path>
            <path d="M2.45980581,10.830688 C7.43960094,11.8008541 12.3724998,12.9781453 17.2536174,14.3645156 L17.8017173,12.4398155 C12.8610024,11.0368361 7.86752918,9.84586687 2.82813674,8.86397671 L2.45980581,10.830688 Z"></path>
            <path d="M3.27941541,6.44706143 C8.39403725,7.44262965 13.4617628,8.651185 18.4747761,10.0746815 L19.0365541,8.10308512 C13.9629665,6.66200256 8.83368955,5.43976914 3.65653939,4.43149985 L3.27941541,6.44706143 Z"></path>
            <path d="M6.11214407,23.3996636 C4.19526001,22.9551263 2.27251392,22.5369681 0.341951794,22.1588671 L-1.98951966e-13,23.9819586 C1.90418299,24.3551746 3.79957294,24.7674708 5.68812385,25.2061461 L6.11214407,23.3996636 Z"></path>
            <path d="M8.07279796,23.8653042 L7.64975474,25.6708097 C9.63796017,26.1573583 11.6193266,26.673217 13.5889689,27.2320639 L14.0970116,25.4490295 C12.0990361,24.8813896 10.0893365,24.3577148 8.07279796,23.8653042"></path>
            <path d="M11.2556853,1.45182962 C9.00759647,0.930108881 6.75266863,0.440629312 4.48503973,-4.90718577e-14 L4.10009971,2.06245782 C6.33548744,2.4972251 8.56012812,2.98084264 10.7779298,3.49474734 L11.2556853,1.45182962 Z"></path>
            <path d="M13.2173162,1.91600475 L12.7385836,3.95892247 C15.0687409,4.52558545 17.389128,5.13035162 19.6968142,5.78494506 L20.2712932,3.76742947 C17.9313659,3.10208898 15.5787375,2.48950676 13.2173162,1.91600475"></path>
          </g>
          <g id="Group-82" transform="translate(316.236238, 59.170361)">
            <path d="M3.15240474,14.1008219 C6.49180827,17.5662591 9.67391396,21.1489369 12.6957908,24.8469013 L14.1612985,23.6315069 C11.1022955,19.8886003 7.87915555,16.2619572 4.49871781,12.7554858 L3.15240474,14.1008219 Z"></path>
            <path d="M6.30578649,10.9479286 C9.74289053,14.5110663 13.0187887,18.1963297 16.128596,21.9998106 L17.6312299,20.7531521 C14.4833194,16.9037519 11.166387,13.1745233 7.68531773,9.5674204 L6.30578649,10.9479286 Z"></path>
            <path d="M15.3154347,14.1613962 C16.7604252,15.8037418 18.1800137,17.4646505 19.5605219,19.1529154 L21.101259,17.8759697 C19.7002337,16.1632797 18.2611051,14.4789229 16.7955974,12.8141061 L15.3154347,14.1613962 Z"></path>
            <path d="M15.4556349,11.3076619 C13.957886,9.6428452 12.433758,7.99756857 10.8734809,6.38062508 L9.45780043,7.79532851 C10.9985375,9.39077788 12.5001944,11.0126064 13.9754721,12.654952 L15.4556349,11.3076619 Z"></path>
            <path d="M18.7921074,8.26839439 C17.2484393,6.55179638 15.6774151,4.85473847 14.0653566,3.18796773 L12.616458,4.63784334 C14.2050683,6.28214297 15.7545985,7.95575275 17.2767725,9.64890263 L18.7921074,8.26839439 Z"></path>
            <path d="M18.6166373,11.1550537 C20.1065701,12.8491806 21.5711008,14.5618706 22.9955743,16.3038708 L24.5744146,14.9946839 C23.129424,13.2292356 21.6443762,11.4921205 20.1319722,9.77454549 L18.6166373,11.1550537 Z"></path>
            <path d="M22.1248673,5.23381646 C20.534303,3.46446018 18.9144285,1.71659801 17.2535197,-7.46069873e-14 L15.7694489,1.48407079 C17.4069095,3.17819768 19.0043129,4.90163472 20.5733832,6.64656588 L22.1248673,5.23381646 Z"></path>
            <path d="M23.4653183,6.73938136 L21.9128572,8.15213078 C23.4496863,9.89706193 24.9591592,11.6625102 26.4285749,13.4562916 L28.0445414,12.1168176 C26.5546085,10.297634 25.0246185,8.50873765 23.4653183,6.73938136"></path>
            <path d="M6.79614536,21.9196962 L5.42443016,23.1683088 C6.73068602,24.6582416 8.01349375,26.1638065 9.26308331,27.6928195 L10.6904878,26.5086893 C9.42233514,24.9572052 8.11998731,23.4301461 6.79614536,21.9196962"></path>
            <path d="M5.45852764,20.4117865 C4.10244453,18.9023136 2.72389029,17.4094498 1.31114088,15.9419881 L0,17.2541059 C1.39223231,18.7000735 2.75124644,20.1724203 4.08778945,21.6603991 L5.45852764,20.4117865 Z"></path>
          </g>
          <g id="Group-77" transform="translate(356.741014, 138.580947)">
            <path d="M4.24860449,6.09651199 C5.71215817,11.052859 6.94904666,16.0678263 7.96024697,21.1326209 L9.82144174,20.7349798 C8.79754036,15.6076569 7.54404279,10.5321153 6.060949,5.51421694 L4.24860449,6.09651199 Z"></path>
            <path d="M16.9897284,2.00579153 C18.5900627,7.38811277 19.9412608,12.8339393 21.0452766,18.3354552 L23.0500912,17.90655 C21.9333743,12.3385977 20.5616591,6.82633482 18.9417846,1.37855423 L16.9897284,2.00579153 Z"></path>
            <path d="M21.2374535,0.641892368 C22.8827302,6.16587935 24.2730085,11.7553257 25.4082884,17.4033923 L27.4599992,16.964717 C26.3110412,11.24826 24.9012228,5.59140034 23.235429,-1.15463195e-13 L21.2374535,0.641892368 Z"></path>
            <path d="M3.98432461,15.2168548 L2.1915202,15.6946104 C2.69956287,17.8098265 3.17341035,19.9318816 3.59840758,22.0656608 L5.4127061,21.6777898 C4.98184684,19.5137234 4.49920631,17.3613811 3.98432461,15.2168548"></path>
            <path d="M3.50158637,13.2590343 C2.96227954,11.1272091 2.38877753,9.00417695 1.76740227,6.89286887 L2.84217094e-14,7.46050885 C0.61355922,9.54152977 1.17729118,11.6352518 1.70878197,13.7377668 L3.50158637,13.2590343 Z"></path>
            <path d="M12.2144204,10.9348368 C11.6370104,8.65743783 11.0224741,6.39078594 10.3551796,4.1358581 L8.49593888,4.73280824 C9.15346333,6.9564719 9.75920651,9.19283664 10.3278235,11.4379944 L12.2144204,10.9348368 Z"></path>
            <path d="M10.8129065,13.3949357 C11.3580754,15.6537715 11.8661181,17.9214004 12.3223795,20.2007534 L14.2314475,19.7923653 C13.768347,17.4807711 13.2524883,15.1818781 12.6995034,12.891778 L10.8129065,13.3949357 Z"></path>
            <path d="M16.5708863,9.77220068 C15.9739361,7.42348035 15.3388828,5.08355308 14.6481402,2.75730387 L12.7429802,3.36890908 C13.4229757,5.66291712 14.049236,7.97158023 14.6373931,10.2880594 L16.5708863,9.77220068 Z"></path>
            <path d="M17.0565555,11.7290442 L15.1230624,12.244903 C15.6867943,14.5760372 16.2134001,16.9159645 16.6833395,19.2676158 L18.6412578,18.8494576 C18.1635023,16.4645881 17.6290805,14.0924196 17.0565555,11.7290442"></path>
          </g>
          <g id="Group-76" transform="translate(360.721138, 157.523514)">
            <path d="M6.22596517,3.77114209 L4.3637934,4.16976018 C5.3554536,9.45926594 6.0989545,14.803484 6.59625011,20.1936213 L8.48870904,19.9913812 C7.9845744,14.5357846 7.23130345,9.12513018 6.22596517,3.77114209"></path>
            <path d="M19.4565686,0.942712247 L17.4517541,1.3716175 C18.5401378,7.11543064 19.3539831,12.9188411 19.8991519,18.7730558 L21.9371846,18.5551837 C21.3851767,12.6306246 20.5586304,6.75589275 19.4565686,0.942712247"></path>
            <path d="M2.86751005,11.0586233 C2.5529144,8.93656819 2.20900859,6.81939808 1.81527553,4.71395204 L2.22044605e-16,5.10182307 C0.38884804,7.17795897 0.726891814,9.26484192 1.03660244,11.3575869 L2.86751005,11.0586233 Z"></path>
            <path d="M1.32472125,13.3522406 C1.65494898,15.7800983 1.93730347,18.2177261 2.16201465,20.6670779 L4.00660032,20.4697229 C3.77798112,17.9871529 3.49171862,15.5153299 3.15660586,13.054254 L1.32472125,13.3522406 Z"></path>
            <path d="M11.7759405,9.60737991 C11.4388737,7.33877401 11.0617497,5.07798415 10.6367525,2.82891834 L8.72670749,3.23632948 C9.14486569,5.45510813 9.51612764,7.68463382 9.84830938,9.92099856 L11.7759405,9.60737991 Z"></path>
            <path d="M10.1339857,11.9173131 C10.4886385,14.5073537 10.7885791,17.1081414 11.0308764,19.7196761 L12.9712086,19.512551 C12.7259803,16.8648671 12.4221317,14.2289073 12.0616168,11.6027175 L10.1339857,11.9173131 Z"></path>
            <path d="M16.2259057,8.88234441 C15.8780919,6.54046312 15.4863129,4.20737488 15.0476376,1.88503369 L13.0897193,2.30416889 C13.5215556,4.59426891 13.9074726,6.89511598 14.2513784,9.2037791 L16.2259057,8.88234441 Z"></path>
            <path d="M16.5121682,10.8777797 L14.5376409,11.2001914 C14.9040178,13.8703464 15.2147054,16.5532025 15.4648188,19.2468056 L17.4549782,19.0338185 C17.1999799,16.3040662 16.8853842,13.5850609 16.5121682,10.8777797"></path>
            <path d="M25.1248592,7.432078 C24.7526203,4.94462295 24.3354391,2.46693795 23.8664766,2.55795385e-13 L21.8137889,0.438675302 C22.2759123,2.87239507 22.6872314,5.31588489 23.0536084,7.77012177 L25.1248592,7.432078 Z"></path>
            <path d="M23.3416295,9.76487314 C23.7343855,12.597211 24.0655903,15.4432269 24.3332897,18.2999899 L26.4191956,18.0772328 C26.1485652,15.1813896 25.8124754,12.2982474 25.4138574,9.42780637 L23.3416295,9.76487314 Z"></path>
          </g>
          <g id="Group-78" transform="translate(351.086011, 120.173975)">
            <path d="M4.08925496,7.96210328 C6.05694328,12.7689685 7.80089743,17.6422701 9.32014041,22.574192 L11.1324849,21.9918969 C9.59370184,17.0003777 7.82727657,12.0684558 5.83516312,7.20199329 L4.08925496,7.96210328 Z"></path>
            <path d="M12.2688396,4.39994259 C14.3547455,9.48134625 16.2042162,14.6330943 17.8143207,19.8463936 L19.7194807,19.2347884 C18.0898361,13.9579837 16.2159403,8.74370738 14.1036552,3.60075239 L12.2688396,4.39994259 Z"></path>
            <path d="M20.8090368,8.42676692 C19.9903065,6.20701127 19.140312,3.99604867 18.2365823,1.80071815 L16.3578014,2.61944845 C17.2478531,4.78449181 18.0861235,6.96516725 18.8921527,9.15463574 L20.8090368,8.42676692 Z"></path>
            <path d="M19.5839701,11.0481694 C20.4603437,13.5121763 21.290798,15.9898613 22.0616551,18.4831784 L24.0127343,17.8559411 C23.2311302,15.3294058 22.3899288,12.8185027 21.5008541,10.3203006 L19.5839701,11.0481694 Z"></path>
            <path d="M25.030969,6.82340381 C24.1878136,4.5362348 23.302647,2.26176687 22.3705841,-7.10542736e-14 L20.447838,0.838270399 C21.3652458,3.0687731 22.2386884,5.31295388 23.0691428,7.56885872 L25.030969,6.82340381 Z"></path>
            <path d="M25.7188784,8.71830525 L23.7570521,9.46376016 C24.6617588,12.0000655 25.5137073,14.5520029 26.3089895,17.1195723 L28.3059879,16.47768 C27.5009357,13.8759153 26.6362862,11.2897827 25.7188784,8.71830525"></path>
            <path d="M12.3785573,11.628315 C11.6125852,9.54240907 10.814372,7.46431917 9.96926261,5.40186135 L8.17938922,6.18151144 C9.0137516,8.21563611 9.79730971,10.2644159 10.5544887,12.3210117 L12.3785573,11.628315 Z"></path>
            <path d="M13.0684206,13.5226303 L11.243375,14.2153269 C12.0660133,16.5347371 12.8446864,18.8648943 13.5676702,21.2097066 L15.4259339,20.6127565 C14.6922031,18.235703 13.902783,15.8733046 13.0684206,13.5226303"></path>
            <path d="M3.94905472,14.8292769 C3.23388697,12.8772207 2.49038607,10.9329805 1.70096593,9.00241834 L8.54871729e-14,9.74298823 C0.777696081,11.6471712 1.51044993,13.5640553 2.21584763,15.4877784 L3.94905472,14.8292769 Z"></path>
            <path d="M4.63696403,16.7246669 L2.90277993,17.3831683 C3.66972896,19.5560277 4.39662077,21.7396342 5.07270832,23.9369187 L6.83913359,23.3702557 C6.15327599,21.141707 5.41563712,18.9278134 4.63696403,16.7246669"></path>
          </g>
          <g id="Group-79" transform="translate(344.046591, 102.982886)">
            <path d="M10.3406223,23.2569231 C10.3455073,23.2686472 10.3503923,23.2803713 10.3552773,23.2920953 L12.1011855,22.5319853 C12.0982545,22.5261233 12.0963005,22.5212383 12.0943465,22.5153762 C10.1100491,17.8247746 7.92351158,13.2484826 5.56111318,8.77868417 L3.89727345,9.70488503 C6.22743068,14.1150862 8.38368099,18.6298269 10.3406223,23.2569231"></path>
            <path d="M11.692602,5.36502825 C14.1683329,10.0390208 16.456479,14.8253689 18.5335919,19.7299346 L20.3674305,18.9307444 C18.2658924,13.9665814 15.9474593,9.12161296 13.4414411,4.39193115 L11.692602,5.36502825 Z"></path>
            <path d="M19.496235,1.02097036 C22.1116777,5.94703021 24.5190184,10.9971697 26.7114179,16.1684578 L28.635141,15.3301874 C26.4153853,10.096371 23.9777575,4.98565716 21.3300736,-1.72306613e-13 L19.496235,1.02097036 Z"></path>
            <path d="M20.4674758,8.27503802 C19.4728846,6.22918929 18.4528912,4.19799563 17.3899097,2.19318111 L15.5990593,3.19070334 C16.6473858,5.16718471 17.652724,7.17102223 18.6326602,9.18853782 L20.4674758,8.27503802 Z"></path>
            <path d="M21.3423839,10.0911929 L19.5065913,11.0046926 C20.5891129,13.2947927 21.6306004,15.6073638 22.6222606,17.9492451 L24.5010415,17.1305148 C23.4957032,14.7573693 22.4395606,12.412557 21.3423839,10.0911929"></path>
            <path d="M12.3891089,12.2952187 C11.456069,10.3744266 10.497627,8.46828964 9.50108176,6.58560076 L7.7952308,7.53427274 C8.77809796,9.39155948 9.72188491,11.2713173 10.6412467,13.1647533 L12.3891089,12.2952187 Z"></path>
            <path d="M13.264017,14.110885 L11.5161548,14.9813966 C12.5332172,17.1347159 13.5131533,19.3085523 14.4442392,21.5107219 L16.2341126,20.7310718 C15.2893486,18.4986151 14.2957344,16.2944915 13.264017,14.110885"></path>
            <path d="M7.94099997,24.2696867 C7.06560338,22.1994128 6.14331054,20.1545411 5.18975353,18.1292094 L3.52786781,18.9557558 C4.46579273,20.9468922 5.37147649,22.9575688 6.232218,24.9926704 L6.26641318,25.0727849 L7.9664021,24.332215 C7.95858606,24.3107209 7.94979302,24.2902038 7.94099997,24.2696867"></path>
            <path d="M4.31640865,16.3119799 C3.44492008,14.5162445 2.55096039,12.7332101 1.62085151,10.9716699 L2.26929586e-13,11.8744226 C0.916430809,13.6105607 1.79768943,15.3681929 2.65647694,17.1385262 L4.31640865,16.3119799 Z"></path>
          </g>
          <g id="Group-86" transform="translate(258.117720, 14.217476)">
            <path d="M3.63621768,19.3881782 C7.80705257,21.1526495 11.887026,23.0773496 15.8819999,25.1446924 L16.7525115,23.4515426 C12.7155263,21.3627056 8.59158768,19.4174884 4.37776457,17.635431 C3.69581499,17.3472145 3.01288841,17.063883 2.32898482,16.7834826 L1.63140316,18.5547929 C2.30162868,18.8283543 2.96892318,19.1058238 3.63621768,19.3881782"></path>
            <path d="M5.37176959,15.2802625 C9.64519001,17.087722 13.826772,19.0602953 17.9214004,21.1784424 L18.8134061,19.4423043 C14.6757894,17.3016861 10.4502422,15.3085956 6.13187958,13.482573 C5.41573482,13.1797015 4.69763605,12.8817149 3.97856028,12.5886134 L3.26436953,14.4048659 C3.96781322,14.6921054 4.67027991,14.9832529 5.37176959,15.2802625"></path>
            <path d="M7.1073215,11.1718582 C11.4833275,13.0232829 15.7665179,15.0417755 19.9588469,17.2117039 L20.8743007,15.4316006 C16.6370295,13.2401781 12.3088968,11.1992144 7.88697159,9.32922655 C7.13467764,9.01169989 6.38238369,8.69905825 5.62813573,8.39227864 L4.89635889,10.2544504 C5.63399776,10.555368 6.37163663,10.8611706 7.1073215,11.1718582"></path>
            <path d="M13.8289214,7.01431061 C12.4405971,6.38707332 11.0464108,5.77058308 9.64147739,5.17558696 C8.85498827,4.84340522 8.06752213,4.5170855 7.27810199,4.19662782 L6.52776205,6.10374183 C7.30057311,6.41833748 8.07240716,6.73781816 8.8422872,7.06316086 C10.2345195,7.65229495 11.6140507,8.26292316 12.989674,8.88429842 L13.8289214,7.01431061 Z"></path>
            <path d="M15.661783,7.85346031 L14.8215586,9.72344812 C17.2416003,10.847981 19.6362398,12.0174562 22.0005922,13.2396896 L22.9375402,11.416598 C20.5419236,10.1777555 18.1150429,8.99362529 15.661783,7.85346031"></path>
            <path d="M17.5089089,3.74075723 L16.6481674,5.65471027 C19.1405074,6.81246135 21.6054914,8.01515466 24.0392111,9.27353726 L24.9976532,7.40648046 C22.5326692,6.13246578 20.0344671,4.91316338 17.5089089,3.74075723"></path>
            <path d="M15.676145,2.90170523 C14.2575335,2.26078986 12.83306,1.63062156 11.3949085,1.02292437 C10.5742242,0.676087548 9.75256288,0.336089764 8.92797055,1.56763491e-13 L8.16102152,1.95303325 C8.96802776,2.28228398 9.77405699,2.61544272 10.5771552,2.95544051 C12.0006517,3.55727567 13.4114471,4.18158194 14.8163804,4.81565827 L15.676145,2.90170523 Z"></path>
            <path d="M6.43944079,23.4660022 C5.17421915,22.8934772 3.90411249,22.3307223 2.62325877,21.7884844 C1.97648137,21.514923 1.32872697,21.2462466 0.679018563,20.9805012 L-1.13686838e-13,22.7068693 C0.635053332,22.9657756 1.26815265,23.229567 1.90027497,23.4962894 C3.16940463,24.0336422 4.42681023,24.5905351 5.68030781,25.1571981 L6.43944079,23.4660022 Z"></path>
            <path d="M8.2713254,24.3061289 L7.51219242,25.9973248 C9.64597162,26.9948471 11.7572797,28.0314495 13.8441626,29.1110402 L14.6912261,27.4608785 C12.576987,26.3666328 10.4353918,25.3163522 8.2713254,24.3061289"></path>
          </g>
          <g id="Group-83" transform="translate(302.872664, 44.948586)">
            <path d="M14.2109304,25.9616641 C14.513802,26.2645357 14.8069035,26.5752234 15.1078211,26.880049 L16.4531571,25.5347129 C16.1522396,25.2289103 15.859138,24.9191997 15.5562664,24.6163281 C11.8426699,20.9027316 7.98349969,17.3816051 3.99634177,14.0392706 L2.79951049,15.5213873 C6.73586415,18.8207337 10.5452071,22.2969179 14.2109304,25.9616641"></path>
            <path d="M17.3632374,22.8087709 C17.667086,23.1126195 17.9592105,23.4223301 18.2601281,23.7271557 L19.6406364,22.3476245 C19.3397188,22.0418219 19.0466172,21.7311342 18.7437457,21.4282626 C14.9168166,17.6013336 10.9384517,13.9737135 6.82721413,10.5317245 L5.60107269,12.0509674 C9.65857498,15.4499683 13.5861356,19.0316691 17.3632374,22.8087709"></path>
            <path d="M26.8213309,13.3507751 C27.1251795,13.6536466 27.4231661,13.9594493 27.7240837,14.2642749 L29.2071775,12.7802041 C28.9072369,12.4753785 28.6082733,12.1695759 28.3054017,11.8667043 C24.1374979,7.6988004 19.8074111,3.74388365 15.3258886,1.11022302e-13 L14.0088857,1.63355257 C18.4317879,5.33053997 22.7061853,9.23660647 26.8213309,13.3507751"></path>
            <path d="M18.4471269,8.70423637 C16.4940937,6.92999506 14.5127273,5.19483396 12.4952117,3.50852311 L11.2075189,5.10397248 C13.1976784,6.76781221 15.1526656,8.4805022 17.0793197,10.2303184 L18.4471269,8.70423637 Z"></path>
            <path d="M25.1182156,15.0537927 C23.4182267,13.3538038 21.6830656,11.6977801 19.9283644,10.0700895 L18.5605572,11.5971486 C20.2888793,13.200414 21.9957072,14.8300585 23.669317,16.5036683 C23.9712116,16.8065399 24.2711522,17.1123425 24.5710927,17.4161911 L26.0209683,15.9672925 C25.7200508,15.6624669 25.4210872,15.3566643 25.1182156,15.0537927"></path>
            <path d="M15.4359971,12.064157 C13.5406072,10.343651 11.6159071,8.66027114 9.65896578,7.02281055 L8.40253719,8.58015672 C10.3321223,10.1961232 12.2304433,11.8570319 14.1004311,13.5540898 L15.4359971,12.064157 Z"></path>
            <path d="M21.930541,18.2414673 C20.2881954,16.5991217 18.6126316,15.0017183 16.9185047,13.4297171 L15.5829387,14.9206269 C17.2506864,16.467226 18.8998711,18.0402043 20.5158376,19.6561707 C20.8187091,19.9590423 21.1118107,20.26973 21.4137053,20.5745556 L22.8274317,19.1598521 C22.5265141,18.8550265 22.2343896,18.5443389 21.930541,18.2414673"></path>
            <path d="M6.41110764,22.1347351 C4.6886476,20.5695728 2.94078543,19.0386058 1.16459011,17.548673 L-2.27373675e-13,18.9917096 C1.75079319,20.4621023 3.47423023,21.9725522 5.17324215,23.5162203 L6.41110764,22.1347351 Z"></path>
            <path d="M12.3694711,27.8031234 C10.9020094,26.3366387 9.40621456,24.9082572 7.89283362,23.5013698 L6.65496812,24.8828551 C8.14294693,26.2662943 9.61529366,27.6712277 11.0573532,29.1142643 C11.3612018,29.4171359 11.6543034,29.7278235 11.9552209,30.0326491 L13.2663618,28.7215082 C12.9654442,28.4166826 12.6723427,28.105995 12.3694711,27.8031234"></path>
          </g>
          <g id="Group-74" transform="translate(362.670947, 198.243231)">
            <path d="M5.34372954,0.000195401025 C5.23137395,4.74160128 4.92850236,9.451743 4.4399998,14.1267125 L6.33538974,14.3035505 C6.82975434,9.56996062 7.13457994,4.80022159 7.24693553,0.000195401025 L5.34372954,0.000195401025 Z"></path>
            <path d="M2.49956992,5.70473294 C2.60801749,3.80836598 2.69301693,1.90711401 2.73991318,1.70530257e-13 L0.885557447,1.70530257e-13 C0.839638206,1.87975786 0.75463876,3.75267669 0.647168196,5.6216875 L2.49956992,5.70473294 Z"></path>
            <path d="M0.522697743,7.63304795 C0.382009005,9.66619562 0.212010113,11.6925043 5.68434189e-14,13.7129509 L1.84653969,13.8839268 C2.06148082,11.836124 2.23343372,9.77855121 2.37509946,7.71707039 L0.522697743,7.63304795 Z"></path>
            <path d="M11.5054078,6.11116707 C11.6197174,4.0789964 11.7086248,2.04291772 11.7555211,-7.10542736e-14 L9.80346484,-7.10542736e-14 C9.7565686,2.01360757 9.66863814,4.0203761 9.55530554,6.02323661 L11.5054078,6.11116707 Z"></path>
            <path d="M9.4330822,8.03567177 C9.28555443,10.2104852 9.10480848,12.3794366 8.8810743,14.5405719 L10.8243375,14.7222949 C11.0519797,12.5308724 11.2346797,10.3296798 11.3831844,8.12360223 L9.4330822,8.03567177 Z"></path>
            <path d="M16.0078382,6.31448183 C16.1260558,4.21587482 16.2169173,2.11042877 16.2638135,9.7700512e-05 L14.262907,9.7700512e-05 C14.2160108,2.08111862 14.1251493,4.1543235 14.0098627,6.22362036 L16.0078382,6.31448183 Z"></path>
            <path d="M13.8878348,8.23605552 C13.737376,10.4831673 13.551745,12.7234401 13.3201948,14.9549198 L15.3132853,15.1405507 C15.5477665,12.8768299 15.7353515,10.605293 15.8877643,8.32691699 L13.8878348,8.23605552 Z"></path>
            <path d="M18.3434667,8.43731857 C18.1890998,10.7547747 17.9985838,13.0663689 17.7601946,15.3691699 L19.8011583,15.5587089 C20.0424786,13.2236667 20.2359256,10.8798314 20.3912694,8.53013406 L18.3434667,8.43731857 Z"></path>
            <path d="M20.5112456,6.5176989 C20.6314173,4.35070153 20.7252098,2.17979614 20.772106,9.77005121e-05 L18.7223492,9.77005121e-05 C18.674476,2.14755497 18.5826375,4.28915021 18.4634429,6.42488341 L20.5112456,6.5176989 Z"></path>
            <path d="M25.0145554,6.71993896 C25.137658,4.48650524 25.2324275,2.24720949 25.2793238,9.77005119e-05 L23.1816937,9.77005119e-05 C23.1338205,2.21594533 23.040028,4.42299991 22.9179024,6.62516947 L25.0145554,6.71993896 Z"></path>
            <path d="M22.7982192,8.63770233 C22.6409214,11.0274569 22.4464974,13.4103724 22.2012691,15.7825408 L24.2901061,15.9779418 C24.5382654,13.5715782 24.7356204,11.1554445 24.8948722,8.73344883 L22.7982192,8.63770233 Z"></path>
          </g>
          <g id="Group-75" transform="translate(363.055496, 177.608492)">
            <path d="M6.32835531,1.91483235 L4.43589638,2.11707241 C4.81595137,6.8408922 5.01037539,11.5979302 5.01037539,16.3862323 C5.01037539,17.1307102 5.00549037,17.8751881 4.99669732,18.618689 L6.89990331,18.618689 C6.90967336,17.8751881 6.91455838,17.1307102 6.91455838,16.3862323 C6.91455838,11.5305168 6.71524934,6.70508848 6.32835531,1.91483235"></path>
            <path d="M24.2636292,7.28306304e-14 L22.1757693,0.222757169 C22.6193296,5.57283724 22.8450178,10.9619975 22.8450178,16.385353 C22.8450178,17.1308079 22.8401328,17.8752858 22.8313397,18.6187867 L24.9299467,18.6187867 C24.9377628,17.8752858 24.9436248,17.1308079 24.9436248,16.385353 C24.9436248,10.8867681 24.7150056,5.42335546 24.2636292,7.28306304e-14"></path>
            <path d="M0.477755507,11.5951945 C0.521720738,13.1886899 0.552007897,14.7851163 0.552007897,16.3854507 C0.552007897,17.1309056 0.546145866,17.8753835 0.53735282,18.6188844 L2.39170855,18.6188844 C2.4014786,17.8753835 2.40734063,17.1309056 2.40734063,16.3854507 C2.40734063,14.7518981 2.37509946,13.1232306 2.33015723,11.496517 L0.477755507,11.5951945 Z"></path>
            <path d="M2.2686059,9.48134625 C2.17481341,7.11015481 2.03412467,4.74775641 1.84458568,2.39317406 L1.41886503e-13,2.59052909 C0.185630974,4.91189327 0.324365702,7.2420505 0.416204184,9.58002377 L2.2686059,9.48134625 Z"></path>
            <path d="M20.2745173,8.52104791 C20.1650927,5.83037579 20.0009559,3.14947373 19.7801527,0.479318715 L17.741143,0.696213853 C17.9580381,3.33119668 18.120221,5.97594956 18.2276915,8.63047249 L20.2745173,8.52104791 Z"></path>
            <path d="M18.2900245,10.6454479 C18.3486448,12.5545159 18.386748,14.4674919 18.386748,16.385353 C18.386748,17.1308079 18.381863,17.8752858 18.3730699,18.6187867 L20.4228267,18.6187867 C20.4306427,17.8752858 20.4365047,17.1308079 20.4365047,16.385353 C20.4365047,14.4313427 20.3964475,12.4812405 20.3378272,10.5360233 L18.2900245,10.6454479 Z"></path>
            <path d="M15.7730639,8.76100037 C15.6675473,6.15044267 15.5082955,3.54965503 15.2962854,0.957660425 L13.3061259,1.16967054 C13.514228,3.72649295 13.6715258,6.29308542 13.7750884,8.86749393 L15.7730639,8.76100037 Z"></path>
            <path d="M13.8372259,10.8829578 C13.8919382,12.7138654 13.9280874,14.546727 13.9280874,16.3854507 C13.9280874,17.1309056 13.9232024,17.8753835 13.9144093,18.6188844 L15.9153158,18.6188844 C15.9241089,17.8753835 15.9289939,17.1309056 15.9289939,16.3854507 C15.9289939,14.5105778 15.8918677,12.642544 15.8352014,10.7764642 L13.8372259,10.8829578 Z"></path>
            <path d="M11.2716104,9.00095283 C11.1700019,6.47050955 11.0166121,3.94885932 10.812418,1.43600214 L8.87110885,1.64312722 C9.07237191,4.12178923 9.22185369,6.60924428 9.32248522,9.10451538 L11.2716104,9.00095283 Z"></path>
            <path d="M9.38345034,11.120077 C9.43523161,12.8718471 9.46942679,14.6255713 9.46942679,16.3861346 C9.46942679,17.1306125 9.46454176,17.8760674 9.45574872,18.6185913 L11.407805,18.6185913 C11.416598,17.8760674 11.421483,17.1306125 11.421483,16.3861346 C11.421483,14.5913762 11.3863108,12.8024798 11.3335526,11.0165144 L9.38345034,11.120077 Z"></path>
          </g>
          <g id="Group-67" transform="translate(313.011730, 303.238162)">
            <path d="M17.6678676,5.6024405 C14.3616823,9.52707009 10.886475,13.3305511 7.22465981,16.9923663 C6.92081122,17.2962149 6.61110059,17.5893164 6.30529799,17.889257 L7.68580623,19.2697652 C7.99063183,18.9698246 8.30131946,18.6767231 8.60516805,18.3728745 C12.3148565,14.661232 15.837937,10.8069468 19.1880876,6.82858193 L17.6678676,5.6024405 Z"></path>
            <path d="M28.0876273,14.0109374 C24.4834554,18.3029209 20.6809514,22.4522617 16.6820694,26.4501667 C16.3801749,26.7540153 16.0733952,27.0529789 15.7695466,27.3529194 L17.2526404,28.8369902 C17.557466,28.5360726 17.8632686,28.2380861 18.1661402,27.9342375 C22.2177805,23.8825972 26.0701117,19.6775672 29.7211799,15.3298943 L28.0876273,14.0109374 Z"></path>
            <path d="M4.83129035,6.62516947 C3.54945963,7.99590766 2.25101981,9.35492179 0.918384819,10.6865798 C0.61551323,10.9904284 0.3048256,11.2845069 -3.41060513e-13,11.5844475 L1.31114088,12.8955884 C1.61694348,12.5946708 1.92665411,12.3015693 2.2305027,11.9986977 C3.58658582,10.6416375 4.90749675,9.26015229 6.2117986,7.86498897 L4.83129035,6.62516947 Z"></path>
            <path d="M7.5839046,6.38765952 C9.14906681,4.67301552 10.6790568,2.93296939 12.1699667,1.16459011 L10.7269301,-2.79776202e-14 C9.25653737,1.74297715 7.74706445,3.45859815 6.20437336,5.14784001 L7.5839046,6.38765952 Z"></path>
            <path d="M10.9365954,9.40074333 C12.5545159,7.62747902 14.1362872,5.82685858 15.6789783,3.99692797 L14.1978385,2.8010737 C12.6766415,4.60462516 11.1163643,6.37984348 9.52091496,8.12868265 L10.9365954,9.40074333 Z"></path>
            <path d="M8.14871126,9.60581671 C6.81412226,11.0351752 5.45999315,12.4508556 4.07166887,13.8401569 C3.76879728,14.1430285 3.45713264,14.4361301 3.15328405,14.7370476 L4.49862011,16.0833607 C4.80344571,15.7814661 5.11413334,15.4883646 5.41700493,15.185493 C6.82975434,13.7727436 8.20733157,12.332638 9.56439169,10.8778774 L8.14871126,9.60581671 Z"></path>
            <path d="M17.6420747,15.4258362 C19.3684427,13.5333773 21.0537766,11.6125852 22.6961222,9.66052899 L21.138776,8.40312339 C19.5189015,10.3278235 17.8560388,12.2222364 16.1540959,14.0883162 L17.6420747,15.4258362 Z"></path>
            <path d="M14.7825761,15.5661342 C13.3405165,17.1127333 11.8779398,18.6427233 10.3762829,20.1453572 C10.0743884,20.4482288 9.76272373,20.7413303 9.45789813,21.0422479 L10.8726016,22.4579283 C11.1784042,22.1560338 11.4881148,21.8629322 11.7909864,21.5600606 C13.3180454,20.0330016 14.8050472,18.4766325 16.2705549,16.9026772 L14.7825761,15.5661342 Z"></path>
            <path d="M20.9995528,18.4418511 C22.7796561,16.4927258 24.5206793,14.5123365 26.2128521,12.4977519 L24.6174028,11.2110361 C22.947701,13.1963105 21.231103,15.1493438 19.4744478,17.0720899 L20.9995528,18.4418511 Z"></path>
            <path d="M18.102928,18.5486377 C16.6061561,20.1528802 15.0869132,21.7405135 13.529567,23.2978597 C13.2266954,23.6007312 12.9208928,23.9006718 12.6160672,24.2006124 L14.0659428,25.650488 C14.3707684,25.3495704 14.675594,25.0496298 14.9794426,24.7477353 C16.5631679,23.16401 18.106836,21.5490205 19.628033,19.9183989 L18.102928,18.5486377 Z"></path>
          </g>
          <g id="Group-70" transform="translate(344.012591, 259.803542)">
            <path d="M14.6566401,3.26378333 C14.5980198,3.40349506 14.5423305,3.54125278 14.4827332,3.68096452 C12.4925738,8.38719821 10.3001742,12.9810763 7.93289083,17.4704149 L9.66902894,18.3624206 C12.0587835,13.8300938 14.2707231,9.19225044 16.2813996,4.4410745 C16.345882,4.28670769 16.4084103,4.13234088 16.4728926,3.97797407 L14.6566401,3.26378333 Z"></path>
            <path d="M18.8079349,4.89626119 C18.7346595,5.0691911 18.6652921,5.24212101 18.5910398,5.41602792 C16.5569151,10.2258242 14.3166423,14.9213108 11.8995316,19.5083499 L13.678658,20.4238037 C16.1192168,15.7908454 18.3809837,11.0513935 20.4346484,6.19567801 C20.5137858,6.00711602 20.5909692,5.81660002 20.6701067,5.62803803 L18.8079349,4.89626119 Z"></path>
            <path d="M2.95544051,7.52196247 C2.00383751,9.49844384 1.02194736,11.4573391 0,13.3937633 L1.64918465,14.2408267 C2.68578709,12.2780234 3.68233232,10.290795 4.64565938,8.28402647 L2.95544051,7.52196247 Z"></path>
            <path d="M5.5067917,6.4609349 C6.35776316,4.63491232 7.18724051,2.79521167 7.97470665,0.932062891 C8.01085584,0.84804045 8.04505102,0.763041004 8.0802232,0.678041558 L6.35385514,-5.68434189e-14 C6.32454499,0.069367364 6.29621184,0.139711733 6.26592468,0.210056102 C5.48529758,2.0585498 4.66168226,3.88554939 3.81657283,5.6988709 L5.5067917,6.4609349 Z"></path>
            <path d="M7.02193125,9.35570339 C6.03906409,11.4025291 5.02297876,13.4288378 3.96585921,15.4316983 L5.6590091,16.3012328 C6.73078372,14.2700392 7.76152413,12.2144204 8.75709235,10.1382845 L7.02193125,9.35570339 Z"></path>
            <path d="M10.5053453,1.63130546 C10.4613801,1.73584501 10.4183919,1.84038456 10.3744266,1.94492411 C9.57914447,3.82761299 8.74087407,5.68587674 7.88013255,7.53241643 L9.61627066,8.31499753 C10.4828742,6.45477977 11.3260296,4.58283795 12.1281508,2.686471 C12.1779781,2.56727637 12.2268284,2.44710474 12.2766556,2.32791012 L10.5053453,1.63130546 Z"></path>
            <path d="M21.9498857,13.8772831 C22.8585004,11.9174108 23.7436671,9.94581449 24.5877995,7.94979302 C24.682569,7.72605884 24.7734305,7.50232467 24.866246,7.27859049 L22.958155,6.52922756 C22.8712015,6.73537564 22.7871791,6.94347774 22.7002256,7.15157983 C21.8619552,9.13294622 20.9836276,11.0889105 20.0818519,13.0341277 L21.9498857,13.8772831 Z"></path>
            <path d="M19.228731,14.8609319 C18.1530484,17.1168367 17.0382855,19.3483164 15.8766264,21.553417 L17.700695,22.4903649 C18.8779862,20.2549772 20.0083811,17.9902793 21.0977418,15.7031103 L19.228731,14.8609319 Z"></path>
            <path d="M27.1096452,8.16023992 C27.0080366,8.40253719 26.9103361,8.64483446 26.8077506,8.88713173 C25.9558021,10.9007393 25.0628194,12.8908987 24.1473656,14.8673801 L26.0603416,15.7300756 C26.9826345,13.7379622 27.8824562,11.7331477 28.7402667,9.70390802 C28.8496913,9.44597867 28.9552078,9.18707231 29.0626784,8.92816595 L27.1096452,8.16023992 Z"></path>
            <path d="M23.296101,16.6946728 C22.1881772,19.018968 21.0401962,21.3198151 19.8443419,23.592329 L21.7113987,24.550771 C22.9228851,22.2479699 24.0884522,19.9148817 25.2090771,17.5573683 L23.296101,16.6946728 Z"></path>
          </g>
          <g id="Group-66" transform="translate(301.462162, 316.231157)">
            <path d="M16.4123183,6.30559109 C12.9517662,9.6430406 9.37983542,12.8329623 5.69261807,15.8607012 L6.93927661,17.3633351 C10.6724132,14.296516 14.2892862,11.0665371 17.7928266,7.68512233 L16.4123183,6.30559109 Z"></path>
            <path d="M7.1851888,5.24749454 C8.6135703,3.95882477 10.0272967,2.65061491 11.418552,1.31114088 L10.1074111,-5.68434189e-14 C8.73667295,1.31895692 7.34346363,2.60762668 5.93559925,3.87773335 L7.1851888,5.24749454 Z"></path>
            <path d="M4.43071825,5.21886829 C2.9720496,6.50265302 1.49774886,7.76592065 2.84217094e-14,8.99597011 L1.18413021,10.4243516 C2.70337319,9.17573905 4.20112205,7.89293132 5.68030781,6.58862947 L4.43071825,5.21886829 Z"></path>
            <path d="M10.2221115,8.57830041 C11.7022743,7.24273441 13.165828,5.88665129 14.6059335,4.498327 L13.2596205,3.15201394 C11.840032,4.52079812 10.3979725,5.85636413 8.94028081,7.17239004 L10.2221115,8.57830041 Z"></path>
            <path d="M7.43520442,8.51342727 C5.92475449,9.84508526 4.39867248,11.1542721 2.84621134,12.4292638 L4.06160571,13.8937945 C5.63556097,12.6012167 7.1850911,11.2715127 8.71801215,9.91933765 L7.43520442,8.51342727 Z"></path>
            <path d="M22.3726358,21.9042595 C24.0579696,20.3830626 25.7237634,18.8364634 27.360247,17.2527381 L25.8771532,15.7696443 C24.2621637,17.3299215 22.6198181,18.8540495 20.9589094,20.3537524 L22.3726358,21.9042595 Z"></path>
            <path d="M19.4559824,21.6972322 C17.7384074,23.2164751 16.0003153,24.707385 14.23389,26.1592146 L15.573364,27.775181 C17.3651914,26.3018573 19.1277087,24.7884764 20.8697088,23.2477393 L19.4559824,21.6972322 Z"></path>
            <path d="M16.4514962,18.4017939 C14.7857025,19.8741406 13.0993916,21.3191312 11.3867017,22.7279726 L12.6958885,24.3048588 C14.4339806,22.8764773 16.1427626,21.4099926 17.8329815,19.9171288 L16.4514962,18.4017939 Z"></path>
            <path d="M19.3357131,18.5735514 C20.9692656,17.0992506 22.5842551,15.6005248 24.1728654,14.0656497 L22.7239668,12.6157741 C21.1578276,14.128178 19.5643323,15.6054098 17.9552048,17.0582164 L19.3357131,18.5735514 Z"></path>
            <path d="M16.29498,15.2389352 C17.8767513,13.8105537 19.4419135,12.3587241 20.9806966,10.8726993 L19.5659932,9.45799583 C18.0487042,10.9225265 16.5060131,12.352862 14.9467129,13.7597494 L16.29498,15.2389352 Z"></path>
            <path d="M13.444079,15.1034246 C11.8310436,16.528875 10.198468,17.9308774 8.53853631,19.2928225 L9.81645901,20.8335596 C11.4998388,19.4501204 13.1558625,18.0285779 14.7923461,16.5826103 L13.444079,15.1034246 Z"></path>
          </g>
          <g id="Group-69" transform="translate(335.433705, 274.978191)">
            <path d="M15.5721916,4.0789964 C13.0759435,8.68362157 10.3735473,13.1622131 7.48552018,17.5206329 L9.12298077,18.5816605 C12.0471571,14.1685283 14.7827715,9.63522456 17.3093067,4.97197909 L15.5721916,4.0789964 Z"></path>
            <path d="M23.5065479,8.1568204 C20.8744961,13.026214 18.0294572,17.7666428 14.9782702,22.374199 L16.6987762,23.4879849 C19.7861124,18.8237624 22.6663235,14.0247132 25.3296395,9.09376832 L23.5065479,8.1568204 Z"></path>
            <path d="M27.4736773,10.1957324 C24.7722581,15.1970216 21.8529668,20.0673922 18.7216653,24.799028 L20.4832056,25.939193 C23.6526102,21.1499139 26.6070737,16.218969 29.3407341,11.1541744 L27.4736773,10.1957324 Z"></path>
            <path d="M6.07404087,6.478521 C7.17805667,4.62221125 8.25276231,2.74831542 9.28545672,0.84804045 L7.63529507,-2.79776202e-14 C6.61725572,1.87291883 5.55622816,3.71945852 4.46784444,5.54938912 L6.07404087,6.478521 Z"></path>
            <path d="M3.42733398,7.27526868 C2.31647916,9.09445222 1.17729118,10.8960497 -1.98951966e-13,12.671268 L1.55636917,13.6805143 C2.75222344,11.8779398 3.9060665,10.0509402 5.03353041,8.20537756 L3.42733398,7.27526868 Z"></path>
            <path d="M9.97629705,8.73696605 C11.117439,6.81617397 12.2302479,4.87681879 13.2971375,2.91010747 L11.6030106,2.0395959 C10.5507761,3.97797407 9.45457631,5.88997311 8.3280894,7.78340904 L9.97629705,8.73696605 Z"></path>
            <path d="M7.28855595,9.51075411 C6.13862091,11.393443 4.96132973,13.2575688 3.74202734,15.0962924 L5.34040772,16.1299638 C6.57631921,14.2658381 7.77021947,12.3743561 8.93676359,10.4643111 L7.28855595,9.51075411 Z"></path>
            <path d="M17.7799301,13.2546378 C18.9972785,11.203904 20.1823857,9.13265312 21.3186427,7.03306911 L19.5395163,6.1176153 C18.4179144,8.18886617 17.2494163,10.2308069 16.048677,12.2532075 L17.7799301,13.2546378 Z"></path>
            <path d="M15.0092413,13.979771 C13.7840768,15.9914245 12.5276482,17.982561 11.2282314,19.9443873 L12.9077032,21.032771 C14.2256831,19.0416345 15.4996978,17.0221649 16.7404943,14.9821782 L15.0092413,13.979771 Z"></path>
          </g>
          <g id="Group-73" transform="translate(360.343525, 213.959531)">
            <path d="M10.9876928,0.828109545 C10.4386159,5.59100954 9.69804599,10.3167833 8.76793711,15.0005459 L10.6867752,15.3591068 C11.6276311,10.6177009 12.376994,5.8323298 12.931933,1.0098325 L10.9876928,0.828109545 Z"></path>
            <path d="M15.4290604,1.24216432 C14.8663054,6.14184503 14.1061954,11.0024455 13.1516614,15.8200578 L15.1193497,16.1883887 C16.0846308,11.3111792 16.8535338,6.38902733 17.4221508,1.42779529 L15.4290604,1.24216432 Z"></path>
            <path d="M19.870428,1.6571961 C19.2939949,6.69170351 18.5153219,11.6890847 17.5353857,16.6405467 L19.5509473,17.0176707 C20.5416305,12.0036804 21.3300736,6.94572485 21.9123687,1.84673509 L19.870428,1.6571961 Z"></path>
            <path d="M24.3117956,2.07125087 C23.7226615,7.24351601 22.9244483,12.3747469 21.920087,17.4600586 L23.9825448,17.8459756 C24.9986302,12.6971586 25.8066134,7.50242237 26.4025865,2.26567489 L24.3117956,2.07125087 Z"></path>
            <path d="M7.63871458,6.56225033 C7.93865516,4.57892993 8.21123959,2.58877048 8.4418128,0.590795 L6.54642285,0.413957072 C6.31780365,2.38359941 6.04912724,4.34347169 5.75309469,6.29845895 L7.63871458,6.56225033 Z"></path>
            <path d="M5.44143005,8.29106091 C5.12097237,10.2607032 4.77218154,12.2244835 4.38431051,14.1804478 L6.25527532,14.5302156 C6.64900839,12.5459182 7.00268425,10.5528278 7.32704995,8.55485229 L5.44143005,8.29106091 Z"></path>
            <path d="M1.02390137,7.67378907 C0.713213743,9.57601805 0.376146974,11.473362 -1.70308212e-13,13.3599589 L1.82309157,13.7019107 C2.20510057,11.7860036 2.54607536,9.86032654 2.86164802,7.93074142 L1.02390137,7.67378907 Z"></path>
            <path d="M3.17448506,5.93921417 C3.46367858,4.02233011 3.72746996,2.10056102 3.95315814,0.171952902 L2.10564145,0 C1.88386129,1.90027497 1.62300092,3.79468791 1.33673841,5.68226182 L3.17448506,5.93921417 Z"></path>
          </g>
          <g id="Group-72" transform="translate(356.579222, 229.294603)">
            <path d="M12.1281508,1.64029391 C11.1364906,6.35043562 9.95431443,11.0175891 8.58064523,15.6339383 L10.4584491,16.1683601 C11.8497044,11.4933906 13.0445817,6.76859382 14.0469889,1.99885479 L12.1281508,1.64029391 Z"></path>
            <path d="M16.5122659,2.45990351 C15.4961806,7.30584894 14.2827402,12.1058751 12.8699908,16.855097 L14.7956679,17.4041739 C16.2240494,12.5953547 17.4521449,7.73377718 18.4799543,2.82823444 L16.5122659,2.45990351 Z"></path>
            <path d="M20.8973581,3.28039241 C19.8558706,8.26116455 18.6101891,13.1950404 17.1612905,18.0781121 L19.1319098,18.638913 C20.5993715,13.6962441 21.8587311,8.69983985 22.9129196,3.65751639 L20.8973581,3.28039241 Z"></path>
            <path d="M3.9076297,5.87180081 C4.36096008,4.03503117 4.79084234,2.1923995 5.1826214,0.340974789 L3.35855282,2.4158453e-13 C2.9726358,1.82406857 2.54763857,3.6393441 2.10114723,5.44875759 L3.9076297,5.87180081 Z"></path>
            <path d="M1.60815044,7.40403795 C1.1049928,9.34046211 0.573502009,11.2710242 -5.68434189e-14,13.1908393 L1.78498837,13.698882 C2.36532941,11.7507338 2.90463624,9.79183848 3.41463292,7.82708117 L1.60815044,7.40403795 Z"></path>
            <path d="M8.29741144,6.8993171 C8.7653969,4.99708812 9.21091123,3.0880201 9.61441435,1.17015904 L7.74344953,0.8194142 C7.34385444,2.70991912 6.90615614,4.59065399 6.44403271,6.46552683 L8.29741144,6.8993171 Z"></path>
            <path d="M5.95220833,8.42022098 C5.43244161,10.4269895 4.88238772,12.424965 4.29032261,14.4121934 L6.12123022,14.9339142 C6.72208837,12.9173756 7.2789813,10.8891129 7.80558706,8.85401126 L5.95220833,8.42022098 Z"></path>
            <path d="M25.8586878,11.0095777 C26.3892016,8.8425803 26.8904052,6.6706979 27.3447126,4.48611444 L25.2812778,4.10019742 C24.8328324,6.25351671 24.3374908,8.39511195 23.8147931,10.5318222 L25.8586878,11.0095777 Z"></path>
            <path d="M23.3264859,12.4874933 C22.7422368,14.7678233 22.1218386,17.0393602 21.4506361,19.2991731 L23.4691287,19.8746291 C24.1491242,17.5825751 24.7783155,15.27782 25.3703806,12.9652488 L23.3264859,12.4874933 Z"></path>
          </g>
          <g id="Group-71" transform="translate(351.139551, 244.413171)">
            <path d="M13.4355791,2.44485763 C11.9348992,7.28396402 10.2222092,12.0693351 8.30044016,16.7921779 L10.1176697,17.5053917 C12.0619099,12.7239286 13.795117,7.87896014 15.313383,2.97927943 L13.4355791,2.44485763 Z"></path>
            <path d="M17.7261948,3.66699334 C16.1854577,8.64483446 14.4258715,13.5660093 12.4513441,18.4236788 L14.3135159,19.1564326 C16.3124684,14.2372118 18.0925717,9.25546267 19.6518719,4.21509322 L17.7261948,3.66699334 Z"></path>
            <path d="M26.3065469,6.11048316 C24.6866724,11.3657937 22.8332937,16.559553 20.7512958,21.6868759 L22.704329,22.4548019 C24.8117291,17.2639737 26.6866019,12.0047551 28.3250395,6.68496218 L26.3065469,6.11048316 Z"></path>
            <path d="M4.63207901,6.53030227 C5.3364997,4.53330379 6.00965624,2.52653526 6.63787053,0.509019671 L4.85385917,-7.06101844e-14 C4.23346091,1.99015944 3.56909743,3.96664081 2.87542379,5.93628315 L4.63207901,6.53030227 Z"></path>
            <path d="M2.19337651,7.8326501 C1.49481784,9.73976411 0.768903035,11.6400391 -2.8643754e-14,13.527613 L1.72539105,14.2056545 C2.50504115,12.2907245 3.24170301,10.3630934 3.95003173,8.42764622 L2.19337651,7.8326501 Z"></path>
            <path d="M8.90266612,7.97675836 C9.63151194,5.90941551 10.3271396,3.83327961 10.974894,1.74444265 L9.14398638,1.22272192 C8.50404802,3.28127172 7.81819043,5.32809746 7.10009166,7.36613015 L8.90266612,7.97675836 Z"></path>
            <path d="M6.41814208,9.26376721 C5.69613529,11.2382946 4.94579535,13.2050059 4.15051318,15.1590161 L5.92084647,15.8556208 C6.7278527,13.8732774 7.4899167,11.8782329 8.22169354,9.87341841 L6.41814208,9.26376721 Z"></path>
            <path d="M21.7153067,12.3150519 C22.5154739,10.037653 23.278515,7.75048397 23.9878207,5.4506139 L22.0172013,4.88785895 C21.3157117,7.15744186 20.5634177,9.41236969 19.7739976,11.6575275 L21.7153067,12.3150519 Z"></path>
            <path d="M19.0943928,13.5553599 C18.3020416,15.7331044 17.4764723,17.9010787 16.6010757,20.055375 L18.5091667,20.805715 C19.3962874,18.6211315 20.2326038,16.421893 21.035702,14.2128844 L19.0943928,13.5553599 Z"></path>
          </g>
          <g id="Group-68" transform="translate(325.038566, 289.324729)">
            <path d="M16.7620862,4.85122126 C13.6952671,9.36498494 10.4222999,13.7439219 6.93927661,17.9733771 L8.45851959,19.1995185 C11.9835541,14.9163281 15.2975555,10.4846328 18.4015008,5.91224882 L16.7620862,4.85122126 Z"></path>
            <path d="M24.2527845,9.70283331 C21.0169435,14.4774574 17.5583453,19.1035766 13.880898,23.5743521 L15.4753704,24.8620449 C19.1987369,20.3355801 22.6993463,15.6508405 25.9732905,10.8185732 L24.2527845,9.70283331 Z"></path>
            <path d="M7.07693664,6.29191302 C8.35876736,4.55186689 9.61617296,2.79423466 10.8305903,1.0092463 L9.27324416,8.52651283e-14 C8.07445887,1.75860923 6.83659337,3.49181632 5.57234874,5.20646032 L7.07693664,6.29191302 Z"></path>
            <path d="M4.37014393,6.82428311 C2.95250949,8.70110996 1.49970287,10.5525347 -1.70530257e-13,12.3726952 L1.44303657,13.5372853 C2.96423355,11.6917227 4.43658028,9.8139188 5.87473183,7.90973581 L4.37014393,6.82428311 Z"></path>
            <path d="M10.7330852,8.92904525 C12.0588812,7.1274478 13.359275,5.30924126 14.6157036,3.45977055 L13.0173232,2.42512213 C11.7784807,4.24723669 10.497627,6.04004109 9.18941712,7.81525941 L10.7330852,8.92904525 Z"></path>
            <path d="M7.98711461,9.4337661 C6.52258393,11.3750753 5.01995004,13.2909823 3.46944291,15.1726942 L4.95155968,16.3685485 C6.52258393,14.4594805 8.04671192,12.5162173 9.53175972,10.5475519 L7.98711461,9.4337661 Z"></path>
            <path d="M18.0462617,14.2042867 C19.4609651,12.2805636 20.8473354,10.3382774 22.1858324,8.36375009 L20.5063606,7.27634338 C19.1854497,9.22156059 17.8195965,11.1374676 16.4234562,13.0338346 L18.0462617,14.2042867 Z"></path>
            <path d="M15.2221307,14.6527321 C13.6618535,16.723006 12.0615191,18.7659237 10.4094034,20.7736692 L11.9667496,22.0300978 C13.6413364,19.9949961 15.2641419,17.9237453 16.8449362,15.8231842 L15.2221307,14.6527321 Z"></path>
            <path d="M25.3605129,19.4796259 C26.8651008,17.4357312 28.3374475,15.3693653 29.758013,13.2697813 L27.9964727,12.1286393 C26.5944704,14.1989132 25.1416637,16.2369459 23.6585699,18.2525075 L25.3605129,19.4796259 Z"></path>
            <path d="M22.4590031,19.8716981 C20.8029794,22.0728906 19.1029904,24.242819 17.3502432,26.3746442 L18.9828188,27.6936011 C20.7609681,25.5314888 22.4824512,23.3312732 24.160946,21.1007705 L22.4590031,19.8716981 Z"></path>
          </g>
          <g id="Group-65" transform="translate(289.173196, 326.491665)">
            <path d="M19.2575527,10.2965593 C15.4667728,13.3428613 11.5685223,16.2347965 7.55889326,18.9596638 L8.6902652,20.6108025 C12.7497215,17.850763 16.6977992,14.9217016 20.5354754,11.8372964 L19.2575527,10.2965593 Z"></path>
            <path d="M22.1132409,13.7390369 C18.2169445,16.8732693 14.2034074,19.8404339 10.0823998,22.6424846 L11.2401509,24.3336805 C15.4139168,21.4954806 19.4772811,18.4911898 23.4224278,15.3169002 L22.1132409,13.7390369 Z"></path>
            <path d="M24.9602339,17.1728191 C20.9584209,20.393028 16.8364362,23.442261 12.602096,26.3214951 L13.7872032,28.0537252 C18.0743017,25.1373649 22.2490447,22.0500287 26.2997079,18.7878086 L24.9602339,17.1728191 Z"></path>
            <path d="M7.16906822,5.08335767 C8.7654946,3.89141142 10.3453119,2.67601704 11.9026581,1.4283815 L10.7185278,-5.68434189e-14 C9.18365279,1.23004945 7.62728362,2.42688073 6.05332836,3.6012409 L7.16906822,5.08335767 Z"></path>
            <path d="M4.43071825,4.79709517 C2.96911858,5.86105376 1.49481784,6.90742625 0,7.92253457 L1.0483265,9.45350161 C2.56561546,8.4227612 4.06141031,7.35977962 5.5454811,6.27921195 L4.43071825,4.79709517 Z"></path>
            <path d="M7.11181572,8.35974437 C5.59648077,9.46376016 4.06942175,10.5492129 2.51989162,11.6014474 L3.59557427,13.1724716 C5.16855252,12.103628 6.71905966,11.0015662 8.25588872,9.88094135 L7.11181572,8.35974437 Z"></path>
            <path d="M9.87869424,8.68606408 C11.5317869,7.45015259 13.1672935,6.19079299 14.780329,4.8972382 L13.5639576,3.43270751 C11.9753472,4.7067222 10.3632888,5.94751871 8.73462123,7.1648671 L9.87869424,8.68606408 Z"></path>
            <path d="M12.5892973,12.2877935 C14.2990562,11.0098708 15.9902521,9.70556893 17.6570229,8.36707191 L16.4113413,6.86443802 C14.7680187,8.18437195 13.1002709,9.46815668 11.4159141,10.7275163 L12.5892973,12.2877935 Z"></path>
            <path d="M9.79281549,11.9233706 C8.22569926,13.0674436 6.64295096,14.1909995 5.03968555,15.2813372 L6.14370134,16.8914416 C7.77139188,15.7854718 9.3756343,14.6443298 10.9661986,13.4836478 L9.79281549,11.9233706 Z"></path>
          </g>
          <g id="Group-64" transform="translate(276.014695, 335.532480)">
            <path d="M19.0423184,11.0391809 C14.9701611,13.7415771 10.7905331,16.2769054 6.50734265,18.6441888 L7.48043975,20.393028 C11.8173655,17.9944804 16.0497517,15.426911 20.1727134,12.6903196 L19.0423184,11.0391809 Z"></path>
            <path d="M21.5668997,14.7258121 C17.3814097,17.5053917 13.0845412,20.1149724 8.68215606,22.5496691 L9.67870129,24.3405195 C14.1377527,21.8745586 18.4873795,19.2317597 22.7256278,16.4170079 L21.5668997,14.7258121 Z"></path>
            <path d="M7.75908162,4.56749897 C9.36527804,3.57876978 10.9577964,2.57147749 12.5297976,1.53096703 L11.4814711,-2.27373675e-13 C9.93291802,1.02487838 8.36287078,2.01849259 6.78109948,2.99061269 L7.75908162,4.56749897 Z"></path>
            <path d="M5.05893255,4.03796219 C3.38923078,5.03646143 1.70487395,6.01346656 -2.55795385e-13,6.9552995 L0.902752737,8.57712801 C2.63302882,7.61966298 4.34278779,6.62800278 6.03593767,5.61387146 L5.05893255,4.03796219 Z"></path>
            <path d="M12.5105506,12.2299548 C14.2330107,11.1699042 15.9408156,10.0883595 17.6251725,8.97066567 L16.5211567,7.36056122 C14.861225,8.460669 13.1788222,9.52560458 11.4817642,10.5710001 L12.5105506,12.2299548 Z"></path>
            <path d="M9.75989041,11.6183496 C7.97099403,12.6911012 6.16548855,13.7384507 4.33848897,14.748674 L5.28813795,16.4535479 C7.14249368,15.4286696 8.97340129,14.366665 10.7886768,13.2773043 L9.75989041,11.6183496 Z"></path>
            <path d="M10.1347673,8.39823837 C11.798607,7.374337 13.4497457,6.32991852 15.0774362,5.25032785 L14.0017536,3.68028061 C12.3965341,4.74326219 10.7707976,5.77204859 9.13236002,6.78129488 L10.1347673,8.39823837 Z"></path>
            <path d="M7.40892298,7.82766738 C5.6796239,8.86426981 3.93566975,9.87644713 2.16924448,10.8524752 L3.09544534,12.515338 C4.88727275,11.5246548 6.65760604,10.4968454 8.41230724,9.44558786 L7.40892298,7.82766738 Z"></path>
            <path d="M19.6424927,23.7306729 C21.5378826,22.5631518 23.4186175,21.3692515 25.2729732,20.1362711 L24.086889,18.405995 C22.2589124,19.6204124 20.4055337,20.7967265 18.5365229,21.9476386 L19.6424927,23.7306729 Z"></path>
            <path d="M16.8154306,22.9970398 C14.8477423,24.180193 12.8614909,25.334036 10.8508143,26.4458679 L11.8708077,28.2797065 C13.9107944,27.1512656 15.926356,25.9817904 17.9214004,24.7800741 L16.8154306,22.9970398 Z"></path>
          </g>
          <g id="Group-62" transform="translate(245.880829, 350.438061)">
            <path d="M19.0875538,12.2675695 C14.1527009,14.3456594 9.15141162,16.1990381 4.08954806,17.8257516 L4.70115327,19.7318886 C9.82652216,18.083681 14.8903397,16.2068541 19.886744,14.1023851 L19.0875538,12.2675695 Z"></path>
            <path d="M8.5561224,4.05457128 C10.5423738,3.30423134 12.5208092,2.52555825 14.4845895,1.69998892 L13.7449966,1.41664458e-13 C11.8085725,0.812868265 9.85847023,1.5807943 7.90055196,2.31941017 L8.5561224,4.05457128 Z"></path>
            <path d="M6.01151254,3.02031365 C4.01744508,3.74525145 2.01458457,4.43892509 2.27373675e-13,5.08667949 L0.566662974,6.85310476 C2.6115347,6.19558031 4.64370537,5.49115961 6.66610598,4.75449775 L6.01151254,3.02031365 Z"></path>
            <path d="M10.1484454,8.27269321 C12.2040641,7.49695114 14.2518669,6.6889679 16.2850146,5.83408841 L15.5258816,4.08915726 C13.5210671,4.93133568 11.5035515,5.72759486 9.47626583,6.49161286 L10.1484454,8.27269321 Z"></path>
            <path d="M7.58576091,7.19261404 C5.52232608,7.94490799 3.4491212,8.66300676 1.36321525,9.33323228 L1.94551031,11.1455768 C4.06170341,10.4655812 6.16421845,9.73673539 8.25794043,8.97369439 L7.58576091,7.19261404 Z"></path>
            <path d="M11.740866,12.4908151 C13.8668292,11.6886939 15.9839993,10.8523775 18.0865143,9.96818791 L17.3058872,8.17831452 C15.2336593,9.05078009 13.1467764,9.87341841 11.0511004,10.6647926 L11.740866,12.4908151 Z"></path>
            <path d="M9.16098627,11.3658914 C7.02720707,12.1435875 4.88365783,12.8870884 2.72643051,13.5797851 L3.32338064,15.4390258 C5.51187212,14.7346051 7.68570853,13.9813342 9.85075189,13.191914 L9.16098627,11.3658914 Z"></path>
            <path d="M16.517151,25.1450832 C18.8541472,24.2638246 21.1784424,23.3444628 23.4880826,22.3694117 L22.6498122,20.4456886 C20.3724132,21.4070616 18.0803592,22.3137224 15.7756041,23.1813029 L16.517151,25.1450832 Z"></path>
            <path d="M13.8866624,23.8837696 C11.5428271,24.7406031 9.1862907,25.5573794 6.81705327,26.3194434 L7.45894564,28.3174189 C9.86237825,27.5446078 12.2501788,26.7151305 14.6282093,25.8475499 L13.8866624,23.8837696 Z"></path>
            <path d="M14.9247303,20.927059 C17.1904052,20.0721795 19.447287,19.1811509 21.6875598,18.2363869 L20.8688295,16.356629 C18.6598209,17.2877149 16.4351802,18.1670195 14.2017465,19.0091979 L14.9247303,20.927059 Z"></path>
            <path d="M12.311437,19.7114692 C10.0379461,20.5419236 7.75273108,21.3332977 5.45286101,22.0728906 L6.0800983,24.0239699 C8.41220954,23.2746069 10.7286887,22.4705317 13.0353978,21.6293303 L12.311437,19.7114692 Z"></path>
          </g>
          <g id="Group-63" transform="translate(261.475100, 343.457066)">
            <path d="M19.2817824,11.6920158 C14.7475016,14.1335516 10.0969572,16.3816404 5.34187323,18.4450752 L6.14106342,20.2798908 C10.9557447,18.1890998 15.6649094,15.9136549 20.2548795,13.4408549 L19.2817824,11.6920158 Z"></path>
            <path d="M23.62203,19.4890052 C18.8356818,22.0712297 13.9291621,24.4531682 8.90735574,26.6328667 L9.74660314,28.5565897 C14.8289838,26.3485582 19.7970549,23.9373095 24.6430003,21.3228438 L23.62203,19.4890052 Z"></path>
            <path d="M16.4080195,20.4908262 C18.4411672,19.487442 20.4586828,18.4567016 22.4498192,17.3829729 L21.452297,15.5911455 C19.4885167,16.6492421 17.5003112,17.6653274 15.4954967,18.6550336 L16.4080195,20.4908262 Z"></path>
            <path d="M13.6850085,19.5395163 C11.5209422,20.5790498 9.3373357,21.5843881 7.1273501,22.5438071 L7.9460804,24.422588 C10.1873302,23.4494908 12.4022008,22.4304745 14.5975313,21.376286 L13.6850085,19.5395163 Z"></path>
            <path d="M8.38690511,4.33301774 C10.1650544,3.45664414 11.9314797,2.55779942 13.6744569,1.62085151 L12.7717041,-5.68434189e-14 C11.0541291,0.923269845 9.31408297,1.80843649 7.56133578,2.67210902 L8.38690511,4.33301774 Z"></path>
            <path d="M5.74967517,3.55346535 C3.85135421,4.46110311 1.93740117,5.33943072 -1.13242749e-13,6.17965513 L0.740569886,7.88062105 C2.7063042,7.02769558 4.64859039,6.1356899 6.5742675,5.21437406 L5.74967517,3.55346535 Z"></path>
            <path d="M12.3960456,12.4095283 C14.3021826,11.4706264 16.1946416,10.5063223 18.0616984,9.50098406 L17.1120494,7.7941561 C15.2713717,8.7848393 13.4062689,9.73546529 11.5274881,10.6606891 L12.3960456,12.4095283 Z"></path>
            <path d="M9.71592518,11.5432179 C7.68473153,12.516315 5.63399776,13.4571709 3.56176989,14.3569926 L4.34141998,16.146866 C6.44393501,15.2333662 8.52300192,14.2788322 10.5844827,13.292057 L9.71592518,11.5432179 Z"></path>
            <path d="M10.3914265,8.37078453 C12.2340582,7.46314676 14.0630118,6.53206088 15.8675403,5.56091778 L14.9423164,3.89707805 C13.1631901,4.85356607 11.3606156,5.77195089 9.54534009,6.66591058 L10.3914265,8.37078453 Z"></path>
            <path d="M7.73231167,7.54834161 C5.76755436,8.48822054 3.78521096,9.39878932 1.78039644,10.2683239 L2.54148344,12.013255 C4.57560811,11.1310194 6.58530766,10.2077496 8.57839811,9.25321556 L7.73231167,7.54834161 Z"></path>
          </g>
          <g id="Group-60" transform="translate(212.667442, 360.242210)">
            <path d="M18.8766184,17.4448173 C13.2715399,18.5840053 7.60784122,19.4633099 1.89333824,20.0846852 L2.11121038,22.1227179 C7.89605773,21.4945036 13.6301008,20.6034749 19.3045466,19.4496319 L18.8766184,17.4448173 Z"></path>
            <path d="M11.3077596,16.3265373 C13.669181,15.9425743 16.0208324,15.5166 18.3627137,15.0407985 L17.9445555,13.0838573 C15.6368694,13.5518427 13.3164822,13.9651159 10.988279,14.3441939 L11.3077596,16.3265373 Z"></path>
            <path d="M8.99684941,14.6597665 C6.48203821,15.0407985 3.95647996,15.3739573 1.42017465,15.6504497 L1.63316177,17.6406092 C4.20463926,17.3611857 6.76634671,17.0250959 9.31633009,16.6391789 L8.99684941,14.6597665 Z"></path>
            <path d="M10.5882931,11.8689514 C12.8744851,11.4976894 15.152861,11.0912553 17.4195129,10.6310859 L17.0121018,8.72201787 C14.776714,9.17534825 12.5305793,9.57494334 10.2776054,9.94132027 L10.5882931,11.8689514 Z"></path>
            <path d="M8.28607818,10.2574791 C5.84942739,10.6248331 3.40398356,10.9491988 0.946815668,11.2159212 L1.15394076,13.1582073 C3.64628083,12.8865999 6.12591985,12.5573492 8.59676581,12.1851102 L8.28607818,10.2574791 Z"></path>
            <path d="M9.86990119,7.41898613 C12.0808638,7.05944824 14.2849874,6.66669218 16.4783639,6.22215485 L16.0787688,4.36096008 C13.9186104,4.79865838 11.745751,5.18555241 9.5660526,5.53922827 L9.86990119,7.41898613 Z"></path>
            <path d="M7.57520925,5.85431242 C5.21769588,6.20994229 2.85041246,6.52453794 0.473358984,6.78246729 L0.675599045,8.67590323 C3.0868477,8.41308885 5.48734929,8.09458518 7.87905784,7.73407028 L7.57520925,5.85431242 Z"></path>
            <path d="M9.15141162,2.96814158 C11.2871448,2.62130476 13.416039,2.24222677 15.5351631,1.81332152 L15.1472921,1.70308212e-13 C13.0594321,0.42304322 10.9608251,0.794305168 8.85537907,1.13723397 L9.15141162,2.96814158 Z"></path>
            <path d="M6.86443802,1.45202502 C4.58508506,1.79593082 2.29791606,2.09782541 -7.10542736e-14,2.34891573 L0.197355036,4.19350141 C2.52848927,3.93948007 4.84887644,3.63074645 7.16047057,3.28293263 L6.86443802,1.45202502 Z"></path>
            <path d="M12.7455204,25.2274448 C15.2564236,24.8200336 17.7575567,24.3647493 20.2479427,23.8586606 L19.8082904,21.8059728 C17.3540536,22.3061995 14.8861386,22.7536678 12.4104076,23.1561939 L12.7455204,25.2274448 Z"></path>
            <path d="M10.4194666,23.469031 C7.74735755,23.8744881 5.06254747,24.2252329 2.36699032,24.5183345 L2.58974749,26.6052174 C5.32243083,26.3082079 8.04339011,25.951601 10.7545793,25.5402818 L10.4194666,23.469031 Z"></path>
          </g>
          <g id="Group-61" transform="translate(229.787307, 356.130581)">
            <path d="M19.6252974,16.9881651 C14.3856189,18.6099937 9.08536607,19.9953869 3.72942397,21.145322 L4.15832922,23.1501365 C9.58070767,21.9855464 14.9473968,20.582567 20.2515577,18.9392444 L19.6252974,16.9881651 Z"></path>
            <path d="M20.988708,21.2349133 C15.6073638,22.9016841 10.1625142,24.3261575 4.66197536,25.5063797 L5.10065067,27.5590675 C10.6695799,26.3641902 16.1818428,24.9211537 21.6296234,23.2328888 L20.988708,21.2349133 Z"></path>
            <path d="M8.79138523,3.47325323 C10.7815447,2.93785442 12.7648651,2.37509946 14.7374384,1.76642527 L14.1707755,-5.70654635e-14 C12.2275123,0.599881148 10.272525,1.15482006 8.31167571,1.68240283 L8.79138523,3.47325323 Z"></path>
            <path d="M6.36225739,2.19572132 C4.25094931,2.73698216 2.13182519,3.24209381 1.13686838e-13,3.70030922 L0.38884804,5.51363073 C2.54998338,5.0495533 4.70037166,4.53662561 6.8419669,3.98754872 L6.36225739,2.19572132 Z"></path>
            <path d="M9.95919946,7.82874208 C12.0197033,7.27478017 14.0743451,6.69053111 16.1162858,6.0593858 L15.5339907,4.24704129 C13.5213602,4.86841655 11.4970055,5.44387257 9.46581187,5.99001843 L9.95919946,7.82874208 Z"></path>
            <path d="M7.51766365,6.50441163 C5.33210318,7.06423557 3.13872667,7.58791032 0.932649094,8.0617578 L1.33126719,9.92295257 C3.56763192,9.44226605 5.7932496,8.91175226 8.01007423,8.34313528 L7.51766365,6.50441163 Z"></path>
            <path d="M11.1270137,12.1832539 C13.2588389,11.6097519 15.382848,11.0059628 17.4951331,10.3533233 L16.897206,8.49505958 C14.816185,9.13792895 12.7224631,9.73194807 10.620925,10.297634 L11.1270137,12.1832539 Z"></path>
            <path d="M8.67209291,10.8122226 C6.41325705,11.3915867 4.14465115,11.9328475 1.86529819,12.4223271 L2.27270933,14.3323721 C4.58528046,13.8360535 6.88710454,13.2859996 9.17818156,12.6988195 L8.67209291,10.8122226 Z"></path>
            <path d="M12.2939486,16.5377658 C14.4970952,15.9466777 16.6914487,15.3213944 18.8731011,14.6462839 L18.2614959,12.7411239 C16.1111076,13.4064643 13.9470413,14.0219776 11.7761359,14.6052496 L12.2939486,16.5377658 Z"></path>
            <path d="M9.82759687,15.1200336 C7.49450863,15.7189378 5.15262734,16.2787617 2.79804498,16.7838734 L3.21522617,18.7408146 C5.60400371,18.228864 7.97910317,17.661224 10.3454096,17.0535268 L9.82759687,15.1200336 Z"></path>
          </g>
          <g id="Group-59" transform="translate(195.006120, 362.797957)">
            <path d="M18.0234975,22.1707865 C12.0647432,22.7550356 6.05420767,23.0608382 0.000683903589,23.0911254 L0.000683903589,25.1897324 C6.12846006,25.1594452 12.214225,24.8487576 18.2472317,24.2586465 L18.0234975,22.1707865 Z"></path>
            <path d="M6.75452494,0.61942125 C4.50936716,0.720052778 2.25932436,0.785512122 0.000488502563,0.798213189 L0.000488502563,2.65256892 C2.29254253,2.63889085 4.57580351,2.5734315 6.85222546,2.47182297 L6.75452494,0.61942125 Z"></path>
            <path d="M8.86505142,2.37119144 C11.2030247,2.24027276 13.5322049,2.06929686 15.8525921,1.84458568 L15.6562141,5.66213743e-14 C13.3690451,0.221780164 11.072106,0.389825046 8.76735091,0.518789722 L8.86505142,2.37119144 Z"></path>
            <path d="M6.98929928,5.07221982 C4.66695809,5.17578236 2.33777787,5.24417272 0.000781604102,5.25687379 L0.000781604102,7.16007977 C2.37099604,7.14737871 4.73339444,7.07898835 7.0899308,6.97249479 L6.98929928,5.07221982 Z"></path>
            <path d="M9.10265907,6.87264486 C11.5207468,6.73684115 13.9310184,6.56098023 16.33152,6.326499 L16.1292799,4.43404007 C13.7639505,4.66461328 11.3868971,4.83852019 9.00202754,4.97236989 L9.10265907,6.87264486 Z"></path>
            <path d="M9.34016901,11.3798626 C11.8393481,11.2391739 14.3297342,11.0515889 16.8103502,10.8102686 L16.6042021,8.86895944 C14.1577813,9.10734869 11.7006134,9.28614063 9.23758347,9.42487536 L9.34016901,11.3798626 Z"></path>
            <path d="M7.22397591,9.52501838 C4.82347431,9.63248895 2.41711068,9.70283331 1.27897692e-13,9.71553438 L1.27897692e-13,11.6675906 C2.45032886,11.6548896 4.89284167,11.5894302 7.32656145,11.4809826 L7.22397591,9.52501838 Z"></path>
            <path d="M9.57777666,15.880339 C12.1580472,15.7367193 14.7285477,15.5422953 17.2892781,15.2921819 L17.077268,13.3020225 C14.5517098,13.5492048 12.0163815,13.7406978 9.47323711,13.8823635 L9.57777666,15.880339 Z"></path>
            <path d="M7.45962954,13.9835813 C4.98096754,14.0949599 2.49546649,14.1613962 0.000195401025,14.1740973 L0.000195401025,16.1750038 C2.53063868,16.1613257 5.05131191,16.0948894 7.56416909,15.9825338 L7.45962954,13.9835813 Z"></path>
            <path d="M9.81538431,20.3817925 C12.4757693,20.2342647 15.1273612,20.0339786 17.768206,19.7750723 L17.5503339,17.7370396 C14.9456382,17.9920379 12.3311725,18.1884159 9.70791374,18.3349667 L9.81538431,20.3817925 Z"></path>
            <path d="M7.69440388,18.4363798 C5.13855846,18.5516664 2.57392001,18.6190798 0.000488502563,18.6327579 L0.000488502563,20.6825146 C2.6100692,20.6688366 5.20987984,20.6004462 7.80187444,20.4832056 L7.69440388,18.4363798 Z"></path>
          </g>
          <g id="group-002" transform="translate(177.285494, 362.997364)">
            <path d="M9.95460754,0.463882034 L9.86765408,2.31628375 C11.8079863,2.39249015 13.7541805,2.44134041 15.7062367,2.45306447 L15.7062367,0.598708742 C13.7834906,0.587961685 11.8666066,0.538134424 9.95460754,0.463882034"></path>
            <path d="M7.94051147,0.374192964 C6.04414452,0.278446461 4.15070858,0.158274831 2.26411168,-2.84217094e-14 L2.09313578,1.84751669 C4.00708883,2.00774554 5.92788091,2.12987118 7.85355801,2.22659468 L7.94051147,0.374192964 Z"></path>
            <path d="M7.73250708,4.82796853 C5.76579576,4.72929102 3.80494647,4.60423436 1.84995921,4.4410745 L1.67409828,6.33548744 C3.65741869,6.50255532 5.64757814,6.62956599 7.64359961,6.72922051 L7.73250708,4.82796853 Z"></path>
            <path d="M9.74572384,4.91863461 L9.65681637,6.81890958 C11.6684699,6.89706999 13.6840315,6.94982827 15.7064321,6.96057533 L15.7064321,5.05834634 C13.7143187,5.04662228 11.7270902,4.99484101 9.74572384,4.91863461"></path>
            <path d="M7.52450268,9.28174411 C5.48940101,9.18013557 3.45918435,9.0511709 1.43678374,8.881172 L1.25506079,10.8244352 C3.30872556,10.9973651 5.36825237,11.1292608 7.4326642,11.2318463 L7.52450268,9.28174411 Z"></path>
            <path d="M9.53771945,9.37241018 L9.44685797,11.3225124 C11.5278789,11.4036038 13.6137848,11.4573391 15.7055528,11.4690632 L15.7055528,9.51602993 C13.644072,9.50528288 11.5894302,9.4525246 9.53771945,9.37241018"></path>
            <path d="M9.32971506,13.8261858 L9.23592256,15.8251382 C11.3872879,15.9091607 13.5425612,15.963873 15.7056505,15.975597 L15.7056505,13.9746905 C13.5748023,13.9639435 11.4498162,13.9092312 9.32971506,13.8261858"></path>
            <path d="M7.31561899,13.7355197 C5.21310396,13.6309801 3.11351994,13.4971304 1.02272897,13.3202925 L0.837097993,15.312406 C2.95817612,15.4921749 5.0880473,15.6279786 7.2218265,15.7344722 L7.31561899,13.7355197 Z"></path>
            <path d="M7.1076146,18.1902723 C4.93475519,18.0818247 2.76873483,17.94309 0.608576493,17.761367 L0.418060494,19.8013537 C2.60948299,19.9869847 4.80774453,20.1266964 7.01186809,20.237098 L7.1076146,18.1902723 Z"></path>
            <path d="M9.12171067,18.2799613 L9.02596416,20.3277641 C11.2466968,20.4156945 13.4723145,20.4713838 15.7057482,20.4831079 L15.7057482,18.4333511 C13.5045557,18.4226041 11.3102021,18.3659378 9.12171067,18.2799613"></path>
            <path d="M6.89961021,22.6440478 C4.65836044,22.5326692 2.42199571,22.3890495 0.19442402,22.2004875 L-2.55795385e-13,24.2903015 C2.25981286,24.4817945 4.52744176,24.6263912 6.80093269,24.7407008 L6.89961021,22.6440478 Z"></path>
            <path
              transform="translate(8.815029, 22.733737)"
              d="M0.0986775178,0 L0,2.096653 C2.29107702,2.18653747 4.58801608,2.24515778 6.89081716,2.25688184 L6.89081716,0.158274831 C4.62025724,0.146550769 2.35751337,0.0898844717 0.0986775178,0"
            ></path>
          </g>

          {/* MAIN SQUARE */}
          <TooltipControl id="group-0001" transform="translate(93.150991, 73.935351)" text="䷁" desc="The Receptive Earth">
            {renderHexagram(['split', 'split', 'split', 'split', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0015" transform="translate(93.150991, 104.853656)" text="䷎" desc="Humbling">
            {renderHexagram(['split', 'split', 'split', 'line', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0007" transform="translate(93.150991, 135.771960)" text="䷆" desc="Leading">
            {renderHexagram(['split', 'split', 'split', 'split', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0046" transform="translate(93.150991, 166.689287)" text="䷭" desc="Ascending">
            {renderHexagram(['split', 'split', 'split', 'line', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0024" transform="translate(93.150991, 197.607591)" text="䷗" desc="Returning">
            {renderHexagram(['split', 'split', 'split', 'split', 'split', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0036" transform="translate(93.150991, 228.524919)" text="䷣" desc="Brilliance Injured">
            {renderHexagram(['split', 'split', 'split', 'line', 'split', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0019" transform="translate(93.150991, 259.443223)" text="䷒" desc="Nearing">
            {renderHexagram(['split', 'split', 'split', 'split', 'line', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0011" transform="translate(93.150991, 290.361527)" text="䷊" desc="Peace">
            {renderHexagram(['split', 'split', 'split', 'line', 'line', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0023" transform="translate(119.047488, 73.935351)" text="䷖" desc="Stripping">
            {renderHexagram(['line', 'split', 'split', 'split', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0052" transform="translate(119.047488, 104.853656)" text="䷳" desc="The Keeping Still Mountain">
            {renderHexagram(['line', 'split', 'split', 'line', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0004" transform="translate(119.047488, 135.771960)" text="䷃" desc="Youthful Folly">
            {renderHexagram(['line', 'split', 'split', 'split', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0018" transform="translate(119.047488, 166.689287)" text="䷑" desc="Correcting">
            {renderHexagram(['line', 'split', 'split', 'line', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0027" transform="translate(119.047488, 197.607591)" text="䷚" desc="Swallowing">
            {renderHexagram(['line', 'split', 'split', 'split', 'split', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0022" transform="translate(119.047488, 228.524919)" text="䷕" desc="Grace">
            {renderHexagram(['line', 'split', 'split', 'line', 'split', 'line'])}
          </TooltipControl>

          <TooltipControl id="group-0041" transform="translate(119.047488, 259.443223)" text="䷨" desc="Decreasing">
            {renderHexagram(['line', 'split', 'split', 'split', 'line', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0026" transform="translate(119.047488, 290.361527)" text="䷙" desc="Great Accumulating">
            {renderHexagram(['line', 'split', 'split', 'line', 'line', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0008" transform="translate(144.943986, 73.935351)" text="䷇" desc="Holding Together">
            {renderHexagram(['split', 'line', 'split', 'split', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0039" transform="translate(144.943986, 104.853656)" text="䷦" desc="Obstruction">
            {renderHexagram(['split', 'line', 'split', 'line', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0029" transform="translate(144.943986, 135.771960)" text="䷜" desc="The Abysmal Water">
            {renderHexagram(['split', 'line', 'split', 'split', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0048" transform="translate(144.943986, 166.689287)" text="䷯" desc="The Well">
            {renderHexagram(['split', 'line', 'split', 'line', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0003" transform="translate(144.943986, 197.607591)" text="䷂" desc="Sprouting">
            {renderHexagram(['split', 'line', 'split', 'split', 'split', 'line'])}
          </TooltipControl>

          <TooltipControl id="group-0063" transform="translate(144.943986, 228.524919)" text="䷾" desc="After Completion">
            {renderHexagram(['split', 'line', 'split', 'line', 'split', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0060" transform="translate(144.943986, 259.443223)" text="䷻" desc="Limitation">
            {renderHexagram(['split', 'line', 'split', 'split', 'line', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0005" transform="translate(144.943986, 290.361527)" text="䷄" desc="Waiting">
            {renderHexagram(['split', 'line', 'split', 'line', 'line', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0020" transform="translate(170.839507, 73.935351)" text="䷓" desc="Contemplation">
            {renderHexagram(['line', 'line', 'split', 'split', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0053" transform="translate(170.839507, 104.853656)" text="䷴" desc="Development">
            {renderHexagram(['line', 'line', 'split', 'line', 'split', 'split'])}
          </TooltipControl>

          <TooltipControl id="group-0059" transform="translate(170.839507, 135.771960)" text="䷺" desc="Dispersion">
            {renderHexagram(['line', 'line', 'split', 'split', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0057" transform="translate(170.839507, 166.689287)" text="䷸" desc="The Gentle Wind">
            {renderHexagram(['line', 'line', 'split', 'line', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0042" transform="translate(170.839507, 197.607591)" text="䷩" desc="Increasing">
            {renderHexagram(['line', 'line', 'split', 'split', 'split', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0037" transform="translate(170.839507, 228.524919)" text="䷤" desc="The Family">
            {renderHexagram(['line', 'line', 'split', 'line', 'split', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0061" transform="translate(170.839507, 259.443223)" text="䷼" desc="Inner Truth">
            {renderHexagram(['line', 'line', 'split', 'split', 'line', 'line'])}
          </TooltipControl>

          <TooltipControl id="group-0009" transform="translate(170.839507, 290.361527)" text="䷈" desc="Small Accumulating">
            {renderHexagram(['line', 'line', 'split', 'line', 'line', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0016" transform="translate(196.736005, 73.935351)" text="䷏" desc="Enthusiasm">
            {renderHexagram(['split', 'split', 'line', 'split', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0062" transform="translate(196.736005, 104.853656)" text="䷽" desc="Small Exceeding">
            {renderHexagram(['split', 'split', 'line', 'line', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0040" transform="translate(196.736005, 135.771960)" text="䷧" desc="Deliverance">
            {renderHexagram(['split', 'split', 'line', 'split', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0032" transform="translate(196.736005, 166.689287)" text="䷟" desc="Persevering">
            {renderHexagram(['split', 'split', 'line', 'line', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0051" transform="translate(196.736005, 197.607591)" text="䷲" desc="The Arousing Thunder">
            {renderHexagram(['split', 'split', 'line', 'split', 'split', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0055" transform="translate(196.736005, 228.524919)" text="䷶" desc="Abundance">
            {renderHexagram(['split', 'split', 'line', 'line', 'split', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0054" transform="translate(196.736005, 259.443223)" text="䷵" desc="Returning Maiden">
            {renderHexagram(['split', 'split', 'line', 'split', 'line', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0034" transform="translate(196.736005, 290.361527)" text="䷡" desc="Great Power">
            {renderHexagram(['split', 'split', 'line', 'line', 'line', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0035" transform="translate(222.632503, 73.935351)" text="䷢" desc="Progress">
            {renderHexagram(['line', 'split', 'line', 'split', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0056" transform="translate(222.632503, 104.853656)" text="䷷" desc="The Wanderer">
            {renderHexagram(['line', 'split', 'line', 'line', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0064" transform="translate(222.632503, 135.771960)" text="䷿" desc="Before Completion">
            {renderHexagram(['line', 'split', 'line', 'split', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0050" transform="translate(222.632503, 166.689287)" text="䷱" desc="Holding">
            {renderHexagram(['line', 'split', 'line', 'line', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0021" transform="translate(222.632503, 197.607591)" text="䷔" desc="Biting Through">
            {renderHexagram(['line', 'split', 'line', 'split', 'split', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0030" transform="translate(222.632503, 228.524919)" text="䷝" desc="The Clinging Fire">
            {renderHexagram(['line', 'split', 'line', 'line', 'split', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0038" transform="translate(222.632503, 259.443223)" text="䷥" desc="Opposition">
            {renderHexagram(['line', 'split', 'line', 'split', 'line', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0014" transform="translate(222.632503, 290.361527)" text="䷍" desc="Great Possession">
            {renderHexagram(['line', 'split', 'line', 'line', 'line', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0045" transform="translate(248.529001, 73.935351)" text="䷬" desc="Gathering Together">
            {renderHexagram(['split', 'line', 'line', 'split', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0031" transform="translate(248.529001, 104.853656)" text="䷞" desc="Influence">
            {renderHexagram(['split', 'line', 'line', 'line', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0047" transform="translate(248.529001, 135.771960)" text="䷮" desc="Oppression">
            {renderHexagram(['split', 'line', 'line', 'split', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0028" transform="translate(248.529001, 166.689287)" text="䷛" desc="Great Exceeding">
            {renderHexagram(['split', 'line', 'line', 'line', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0017" transform="translate(248.529001, 197.607591)" text="䷐" desc="Following">
            {renderHexagram(['split', 'line', 'line', 'split', 'split', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0049" transform="translate(248.529001, 228.524919)" text="䷰" desc="Revolution">
            {renderHexagram(['split', 'line', 'line', 'line', 'split', 'line'])}
          </TooltipControl>

          <TooltipControl id="group-0058" transform="translate(248.529001, 259.443223)" text="䷹" desc="The Joyous Lake">
            {renderHexagram(['split', 'line', 'line', 'split', 'line', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0043" transform="translate(248.529001, 290.361527)" text="䷪" desc="Breakthrough">
            {renderHexagram(['split', 'line', 'line', 'line', 'line', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0012" transform="translate(274.425499, 73.935351)" text="䷋" desc="Standstill">
            {renderHexagram(['line', 'line', 'line', 'split', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0033" transform="translate(274.425499, 104.853656)" text="䷠" desc="Retiring">
            {renderHexagram(['line', 'line', 'line', 'line', 'split', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0006" transform="translate(274.425499, 135.771960)" text="䷅" desc="Conflict">
            {renderHexagram(['line', 'line', 'line', 'split', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0044" transform="translate(274.425499, 166.689287)" text="䷫" desc="Coupling">
            {renderHexagram(['line', 'line', 'line', 'line', 'line', 'split'])}
          </TooltipControl>
          <TooltipControl id="group-0025" transform="translate(274.425499, 197.607591)" text="䷘" desc="Innocence">
            {renderHexagram(['line', 'line', 'line', 'split', 'split', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0013" transform="translate(274.425499, 228.524919)" text="䷌" desc="Fellowship">
            {renderHexagram(['line', 'line', 'line', 'line', 'split', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0010" transform="translate(274.425499, 259.443223)" text="䷉" desc="Treading">
            {renderHexagram(['line', 'line', 'line', 'split', 'line', 'line'])}
          </TooltipControl>
          <TooltipControl id="group-0002" transform="translate(274.425499, 290.361527)" text="䷀" desc="The Creative Heaven">
            {renderHexagram(['line', 'line', 'line', 'line', 'line', 'line'])}
          </TooltipControl>
        </g>
      </g>
    </svg>
  );
};

const splitArray = [
  <>
    <polygon points="9.9475983e-14 2.01556158 9.17603215 2.01556158 9.17603215 -7.0832229e-14 9.9475983e-14 -7.0832229e-14" />
    <polygon points="11.1915937 2.01556158 20.4223382 2.01556158 20.4223382 -7.0832229e-14 11.1915937 -7.0832229e-14" />
  </>,
  <>
    <polygon points="9.9475983e-14 6.35053332 9.17603215 6.35053332 9.17603215 4.33497175 9.9475983e-14 4.33497175" />
    <polygon points="11.1915937 6.35151033 20.4223382 6.35151033 20.4223382 4.33594875 11.1915937 4.33594875" />
  </>,
  <>
    <polygon points="9.9475983e-14 10.6855051 9.17603215 10.6855051 9.17603215 8.66994349 9.9475983e-14 8.66994349" />
    <polygon points="11.1915937 10.6855051 20.4223382 10.6855051 20.4223382 8.66994349 11.1915937 8.66994349" />
  </>,
  <>
    <polygon points="9.9475983e-14 15.0214538 9.17603215 15.0214538 9.17603215 13.0058922 9.9475983e-14 13.0058922" />
    <polygon points="11.1915937 15.0214538 20.4223382 15.0214538 20.4223382 13.0058922 11.1915937 13.0058922" />
  </>,
  <>
    <polygon points="9.9475983e-14 19.3564256 9.17603215 19.3564256 9.17603215 17.340864 9.9475983e-14 17.340864" />
    <polygon points="11.1915937 19.3564256 20.4223382 19.3564256 20.4223382 17.340864 11.1915937 17.340864" />
  </>,
  <>
    <polygon points="9.9475983e-14 23.6913973 9.17603215 23.6913973 9.17603215 21.6758357 9.9475983e-14 21.6758357" />
    <polygon points="11.1915937 23.6913973 20.4223382 23.6913973 20.4223382 21.6758357 11.1915937 21.6758357" />
  </>,
];

const lineArray = [
  <polygon points="8.34887715e-14 2.01556158 20.4223382 2.01556158 20.4223382 -5.68434189e-14 8.34887715e-14 -5.68434189e-14" />,
  <polygon points="8.34887715e-14 6.35053332 20.4223382 6.35053332 20.4223382 4.33497175 8.34887715e-14 4.33497175" />,
  <polygon points="8.34887715e-14 10.6855051 20.4223382 10.6855051 20.4223382 8.66994349 8.34887715e-14 8.66994349" />,
  <polygon points="8.34887715e-14 15.0204768 20.4223382 15.0204768 20.4223382 13.0049152 8.34887715e-14 13.0049152" />,
  <polygon points="8.34887715e-14 19.3554486 20.4223382 19.3554486 20.4223382 17.339887 8.34887715e-14 17.339887" />,
  <polygon points="8.34887715e-14 23.6904203 20.4223382 23.6904203 20.4223382 21.6748587 8.34887715e-14 21.6748587" />,
];

const renderHexagram = (items: [string, string, string, string, string, string]) => (
  <>
    {items.map((item: string, i: number) => {
      if (item === 'split') {
        return <g key={`split-${i}`}>{splitArray[i]}</g>;
      } else {
        return <g key={`line-${i}`}>{lineArray[i]}</g>;
      }
    })}
  </>
);
