/* eslint-disable */
import React, {useEffect, useState} from 'react';
import ReactTooltip from 'react-tooltip';
import {useWeb3React} from '@web3-react/core';
import {Web3Provider} from '@ethersproject/providers';
import {InjectedConnector} from '@web3-react/injected-connector';
import {useMetaMask} from 'metamask-react';

import {truncate} from '../utils/helpers';

interface Props {
  balance?: string | number;
  metamaskStatus?: string;
  isAvailable?: boolean;
  onConnect?: () => void;
}

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [
    1, // Mainet
    3, // Ropsten
    4, // Rinkeby
    5, // Goerli
    42, // Kovan
  ],
});

const Header: React.FC<Props> = () => {
  const [activateButton, setActivateButton] = useState<boolean>(false);
  const [balance, setBalance] = useState<any>(false);
  const [buttonText, setButtonText] = useState<string>('Connect Wallet');
  const {chainId, library, activate, active} = useWeb3React<Web3Provider>();
  const {status, account} = useMetaMask();

  const onConnect = () => {
    activate(injectedConnector);
  };

  // const updateButtons = () => {
  //   if (isMetaMaskConnected()) {
  //     btnConnect.innerText = truncate(accounts[0], 15);
  //     btnConnect.disabled = true;
  //   } else {
  //     btnConnect.innerText = 'Connect Wallet';
  //     btnConnect.onclick = onClickConnect;
  //     btnConnect.disabled = false;
  //   }

  //   if (!(isMetaMaskInstalled() && window.ethereum)) {
  //     btnConnect.innerText = 'Please install MetaMask';
  //     btnConnect.disabled = true;
  //     document.getElementById('isMetamaskInstalled').style.display = 'block';
  //     document.getElementById('isMetamaskInstalled').classList.add('visible');
  //   }
  // };

  useEffect(() => {
    const result = status !== 'connected' && status !== 'unavailable';
    const text = status === 'connected' ? truncate(account, 15) : status === 'unavailable' ? 'Please install Metamask' : 'Connect Wallet';
    setActivateButton(result);
    setButtonText(text);
  }, [status, account]);

  return (
    <nav className="header_menu_9 pt-30 pb-30 position-sticky bg-main">
      <div className="container mw-970 px-xl-0">
        <div className="row align-items-center justify-content-between">
          <div className="col-6 col-sm-4 col-md-3" data-aos="fade-down" data-aos-delay="0">
            <span className="text-adaptive color-white bold f-20">Project Hexagrams</span>
          </div>
          <div className="col-6 col-sm-8 col-md-9 d-flex align-items-center justify-content-end f-18 text-adaptive medium" data-aos="fade-down" data-aos-delay="100">
            <a href="https://twitter.com/HexagramsArt" target="_blank" className="link mx-20 color-white d-none d-md-inline-block">
              Twitter
            </a>
            {/* <a href="https://opensea.io/collection/TODO_OPENSEA" target="_blank" className="link mx-20 color-white d-none d-md-inline-block">OpenSea</a> */}
            <a href="https://etherscan.io/address/TODO_CONTRACT_ADDRESS#code" target="_blank" className="link mx-20 color-white d-none d-md-inline-block">
              Smart Contract
            </a>
            <div className="account_box">
              <button className="btn sm border-white color-white" id="btnConnect" disabled={!activateButton} onClick={() => onConnect()}>
                {buttonText}
              </button>
              <div id="walletBalance" className="header_balance ml-20 f-16 lh-18 d-none d-md-inline-block"></div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
