import React from 'react';

const LeadSection = () => {
  const token1 = `
<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 360 360">
<style>.base-black { fill: black; font-family: "New York", serif; font-size: 18px; }</style>
<style>.base-white { fill: white; font-family: "New York", serif; font-size: 18px; }</style>
<style>.gram-black { fill: black; font-family: "New York", serif; font-size: 160px; }</style>
<style>.gram-white { fill: white; font-family: "New York", serif; font-size: 160px; }</style>
<rect width="100%" height="100%" fill="black" />
<rect width="100%" height="50%" fill="white" />
<circle cx="90" cy="180" r="90" stroke="black" stroke-width="0" fill="white" />
<circle cx="270" cy="180" r="90"  stroke-width="0" fill="black" />
<text class="gram-black" x="90" y = "191" alignment-baseline="central" text-anchor="middle">䷇</text>
<text class="gram-white" x="270" y = "191" alignment-baseline="central" text-anchor="middle">䷪</text>
<text class="base-black" x="100" y = "90" alignment-baseline="central" text-anchor="middle">8 Holding Together 比</text>
<text class="base-white" x="270" y = "270" alignment-baseline="central" text-anchor="middle">43 Breakthrough 夬</text>
</svg>
`;

  //   const token2 = `
  // <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 360 360">
  // <style>.base-black { fill: black; font-family: "New York", serif; font-size: 18px; }</style>
  // <style>.base-white { fill: white; font-family: "New York", serif; font-size: 18px; }</style>
  // <style>.gram-black { fill: black; font-family: "New York", serif; font-size: 160px; }</style>
  // <style>.gram-white { fill: white; font-family: "New York", serif; font-size: 160px; }</style>
  // <rect width="100%" height="100%" fill="black" />
  // <rect width="100%" height="50%" fill="white" />
  // <circle cx="90" cy="180" r="90" stroke="black" stroke-width="0" fill="white" />
  // <circle cx="270" cy="180" r="90"  stroke-width="0" fill="black" />
  // <text class="gram-black" x="90" y = "191" alignment-baseline="central" text-anchor="middle">䷟</text>
  // <text class="gram-white" x="270" y = "191" alignment-baseline="central" text-anchor="middle">䷢</text>
  // <text class="base-black" x="90" y = "90" alignment-baseline="central" text-anchor="middle">32 Persevering 恆</text>
  // <text class="base-white" x="270" y = "270" alignment-baseline="central" text-anchor="middle">35 Progress 晉</text>
  // </svg>
  // `;

  const token2 = `
<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 360 360">
<style>.base-black { fill: black; font-family: "New York", serif; font-size: 18px; }</style>
<style>.base-white { fill: white; font-family: "New York", serif; font-size: 18px; }</style>
<style>.gram-black { fill: black; font-family: "New York", serif; font-size: 160px; }</style>
<style>.gram-white { fill: white; font-family: "New York", serif; font-size: 160px; }</style>
<rect width="100%" height="100%" fill="white" />
<rect width="100%" height="50%" fill="black" />
<circle cx="90" cy="180" r="90" stroke-width="0" fill="black" />
<circle cx="270" cy="180" r="90"  stroke-width="0" fill="white" />
<text class="gram-white" x="90" y = "191" alignment-baseline="central" text-anchor="middle">䷟</text>
<text class="gram-black" x="270" y = "191" alignment-baseline="central" text-anchor="middle">䷢</text>
<text class="base-white" x="90" y = "90" alignment-baseline="central" text-anchor="middle">32 Persevering 恆</text>
<text class="base-black" x="270" y = "270" alignment-baseline="central" text-anchor="middle">35 Progress 晉</text>
</svg>
`;

  const token3 = `
<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 360 360">
<style>.base-black { fill: black; font-family: "New York", serif; font-size: 18px; }</style>
<style>.base-white { fill: white; font-family: "New York", serif; font-size: 18px; }</style>
<style>.gram-black { fill: black; font-family: "New York", serif; font-size: 160px; }</style>
<style>.gram-white { fill: white; font-family: "New York", serif; font-size: 160px; }</style>
<rect width="100%" height="100%" fill="black" />
<rect width="100%" height="50%" fill="white" />
<circle cx="90" cy="180" r="90" stroke="black" stroke-width="0" fill="white" />
<circle cx="270" cy="180" r="90"  stroke-width="0" fill="black" />
<text class="gram-black" x="90" y = "191" alignment-baseline="central" text-anchor="middle">䷀</text>
<text class="gram-white" x="270" y = "191" alignment-baseline="central" text-anchor="middle">䷁</text>
<text class="base-black" x="90" y = "90" alignment-baseline="central" text-anchor="middle">1 The Creative 乾</text>
<text class="base-black" x="90" y = "65" alignment-baseline="central" text-anchor="middle">Heaven </text>
<text class="base-white" x="270" y = "270" alignment-baseline="central" text-anchor="middle">2 The Receptive 坤</text>
<text class="base-white" x="270" y = "295" alignment-baseline="central" text-anchor="middle">Earth</text>
</svg>
`;

  return (
    <header className="pt-80 pb-120 header_9 bg-main color-white">
      <div className="container  px-xl-0 text-center text-lg-left">
        <div className="row flex-column-reverse flex-lg-row justify-content-center align-items-center mb-50 text-center">
          <div className="col-lg-7 col-md-7 col-sm-8 pt-20 left">
            <h1 className="text-adaptive" data-aos="fade-up" data-aos-delay="0">
              Bringing wisdom from 3000 years past to be forever on-chain
            </h1>
            <div className="mw-500 mt-15 f-18 medium color-white op-7 text-adaptive mx-auto" data-aos="fade-up" data-aos-delay="250">
              Project Hexagrams (for fortune telling) is the first NFT project to bring I Ching divinations on chain.
            </div>
            <div className="mt-40" data-aos="fade-up" data-aos-delay="500">
              <a href="#mintSection" className="btn md action-1 mb-2">
                ䷼ Mint a Diviner Hexagrams Token
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" data-aos="fade-up" data-aos-delay="600">
            <div dangerouslySetInnerHTML={{__html: token1}}></div>
            <p className="mt-10 text-center">Diviner Hexagrams #555</p>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" data-aos="fade-up" data-aos-delay="700">
            <div dangerouslySetInnerHTML={{__html: token2}}></div>
            <p className="mt-10 text-center">Diviner Hexagrams #2083</p>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" data-aos="fade-up" data-aos-delay="800">
            <div dangerouslySetInnerHTML={{__html: token3}}></div>
            <p className="mt-10 text-center">Diviner Hexagrams #66</p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default LeadSection;
