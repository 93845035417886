/* eslint-disable prefer-const */
import React from 'react';

const CallToActionSection: React.FC = () => {
  return (
    <section className="pt-105 pb-100 text-center call_to_action_4">
      <div className="container px-xl-0">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <h2>Own a piece of timeless divinations that set the foundation for Confucianism, Taoism and Buddhism!</h2>
            <div className="mt-40">
              <a href="#mintSection" className="btn md action-3 mb-2">
                ䷼ Mint a Diviner Hexagrams Token
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToActionSection;
