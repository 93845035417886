/* eslint-disable prefer-const */
import React from 'react';
import ReactTooltip from 'react-tooltip';

import {Hexagram} from '../components/symbols';

const HexagramSection = () => {
  const token3 = `
<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 360 360">
<style>.base-black { fill: black; font-family: "New York", serif; font-size: 18px; }</style>
<style>.base-white { fill: white; font-family: "New York", serif; font-size: 18px; }</style>
<style>.gram-black { fill: black; font-family: "New York", serif; font-size: 160px; }</style>
<style>.gram-white { fill: white; font-family: "New York", serif; font-size: 160px; }</style>
<rect width="100%" height="100%" fill="black" />
<rect width="100%" height="50%" fill="white" />
<circle cx="90" cy="180" r="90" stroke="black" stroke-width="0" fill="white" />
<circle cx="270" cy="180" r="90"  stroke-width="0" fill="black" />
<text class="gram-black" x="90" y = "191" alignment-baseline="central" text-anchor="middle">䷀</text>
<text class="gram-white" x="270" y = "191" alignment-baseline="central" text-anchor="middle">䷁</text>
<text class="base-black" x="90" y = "90" alignment-baseline="central" text-anchor="middle">1 The Creative 乾</text>
<text class="base-black" x="90" y = "65" alignment-baseline="central" text-anchor="middle">Heaven </text>
<text class="base-white" x="270" y = "270" alignment-baseline="central" text-anchor="middle">2 The Receptive 坤</text>
<text class="base-white" x="270" y = "295" alignment-baseline="central" text-anchor="middle">Earth</text>
</svg>
`;

  const token4 = `
<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 360 360">
<rect width="100%" height="100%" fill="black" />
<rect width="100%" height="50%" fill="white" />
<circle cx="90" cy="180" r="90" stroke="black" stroke-width="0" fill="white" />
<circle cx="270" cy="180" r="90"  stroke-width="0" fill="black" />
<text class="gram-black" x="90" y = "191" alignment-baseline="central" text-anchor="middle">䷷</text>
<text class="gram-white" x="270" y = "191" alignment-baseline="central" text-anchor="middle">䷿</text>
<text class="base-black" x="90" y = "90" alignment-baseline="central" text-anchor="middle">56 The Wanderer 旅</text>
<text class="base-white" x="270" y = "270" alignment-baseline="central" text-anchor="middle">64 未濟</text>
<text class="base-white" x="270" y = "295" alignment-baseline="central" text-anchor="middle">Before Completion</text>
</svg>
`;

  //   const token5 = `
  // <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 360 360">
  // <rect width="100%" height="100%" fill="black" />
  // <rect width="100%" height="50%" fill="white" />
  // <circle cx="90" cy="180" r="90" stroke="black" stroke-width="0" fill="white" />
  // <circle cx="270" cy="180" r="90"  stroke-width="0" fill="black" />
  // <text class="gram-black" x="90" y = "191" alignment-baseline="central" text-anchor="middle">䷑</text>
  // <text class="gram-white" x="270" y = "191" alignment-baseline="central" text-anchor="middle">䷰</text>
  // <text class="base-black" x="90" y = "90" alignment-baseline="central" text-anchor="middle">18 Arresting Decay 蠱</text>
  // <text class="base-white" x="270" y = "270" alignment-baseline="central" text-anchor="middle">49 Revolution 革</text>
  // </svg>
  // `;
  const token5 = `
<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 360 360">
<rect width="100%" height="100%" fill="white" />
<rect width="100%" height="50%" fill="black" />
<circle cx="90" cy="180" r="90" stroke-width="0" fill="black" />
<circle cx="270" cy="180" r="90"  stroke-width="0" fill="white" />
<text class="gram-white" x="90" y = "191" alignment-baseline="central" text-anchor="middle">䷑</text>
<text class="gram-black" x="270" y = "191" alignment-baseline="central" text-anchor="middle">䷰</text>
<text class="base-white" x="90" y = "90" alignment-baseline="central" text-anchor="middle">18 Arresting Decay 蠱</text>
<text class="base-black" x="270" y = "270" alignment-baseline="central" text-anchor="middle">49 Revolution 革</text>
</svg>
`;
  return (
    <section className="pt-120 pb-55 feature_5">
      <div className="container px-xl-0">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10">
            <h2 className="small">Wait, what's a hexagram?</h2>
            <div className="mt-25 mb-30 f-22 color-heading text-adaptive description">
              A hexagram is a figure composed of six stacked horizontal lines (爻 yáo), where each line is either Yang (an unbroken, or solid line), or Yin (broken, an
              open line with a gap in the center). Each hexagram is accompanied by a description, often cryptic, akin to parables, and widely used for fortune-telling.
              <br />
              <br />
              The lower trigram of the Hexagram represents the attitude towards motivation, the upper towards intention. There are sixty-four possible combinations of six
              yin or yang lines.
              <br />
              <br />
              <div className="row justify-content-center">
                <div className="col-lg-5 col-sm-5 col-xs-12">
                  <div className="animated_image border-main">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350">
                      <rect width="100%" height="100%" fill="#ffffff" />
                      <text
                        style={{
                          fill: 'black',
                          fontSize: '250px',
                        }}
                        className="gram"
                        x="175"
                        y="170"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                      >
                        ䷊
                      </text>
                      <foreignObject width="100%" height="40" x="0" y="260" requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility">
                        <p style={{textAlign: 'center', color: 'black', fontSize: 20}}>
                          <span className="base">11 - Peace - 泰</span>
                        </p>
                      </foreignObject>
                    </svg>
                  </div>
                  <p className="text-center f-16 mt-10">Creator Hexagram #704</p>
                </div>
              </div>
            </div>
            <br />
            <br />
            <h2 className="small">So, why do hexagrams matter?</h2>
            <div className="mt-25 mb-30 f-22 color-heading text-adaptive description">
              Hexagrams arise from <i>I Ching, Book of Changes</i> (易經), an ancient text created at around 1000 BCE that laid the foundation stone for Confucianism,
              Taoism, and Buddhism.
              <br />
              <br />
              The <i>I Ching</i> is more than a book to be understood, its highest purpose is to be consulted or used like an introspective tool. A tool invented by the
              ancient Chinese scholars to help a person reach their creative state by proper alignment of their inner and outer attitude.
              <br />
              <br />
              Here are all 64 hexagrams and their names:
              <br />
              <br />
              <div className="svg-container">
                <ReactTooltip id="svgTooltip" />
                <Hexagram />
              </div>
            </div>
            <br />
            <br />
            <h2 className="small">Why do I see two hexagrams in each NFT token?</h2>
            <div className="mt-25 mb-55 f-22 color-heading text-adaptive description">
              Life is about changes, and so are hexagrams! Each Hexagram selected can change into another by means of <strong>changing lines</strong>, whereby a yin line
              can change into a yang, or a yang to a yin.
              <br />
              <br />
              The changing lines then form a second hexagram. The two hexagrams together are your divination. There are many ways it can work together with the primary
              hexagram, but the two most useful ways to think of it are: the backdrop to the reading, and what it’s about for you, or the relationship you have to the
              basic situation as represented in the primary hexagram.
              <br />
              <br />
              <div className="row justify-content-center">
                <div className="col-lg-5 col-sm-5 col-xs-12">
                  <div className="animated_image border-main">
                    <div dangerouslySetInnerHTML={{__html: token4}}></div>
                  </div>
                  <p className="text-center f-16 mt-10">Diviner Hexagrams #3648</p>
                </div>
              </div>
            </div>
            <h2 className="small">How do hexagrams do fortune-telling exactly?</h2>
            <div className="mt-25 mb-55 f-22 color-heading text-adaptive description">
              The traditional process of getting hexagram divination is accomplished by tossing yarrow stalks. It’s a complicated procedure involving multiple rounds of
              meditating and counting.
              <br />
              <br />
              Followed by asking a real-life question, a significant question involving a problem you are facing or a decision you must make. The best form of the
              question to ask is "What should my attitude be towards ... such and such an action".
              <br />
              <br />
              Then the hexagrams will give you an idea as to whether you should go forward with the action, and if so, how, with what inner attitude.
              <br />
              <br />
              When brought on chain, you can try virtually tossing yarrow stalks and formulate your own hexagram divinations.
              {/* TODO_PROJECT_NAME is an NFT project that brings hexagrams and its divination properties to the on-chain world.
              <br />
              <br />
              Each token is a reading that capture a specific changing hexagram combination. There are 4096 such possible readings. Here are some of the exmaple readings
              / NFTs:
              <br />
              <br />
              <p>[ Insert three NFT tokens ]</p> */}
            </div>
            <h2 className="small">Can you show me some more tokens?</h2>

            {/* <h2 className="small">Roadmaps</h2>
            <div className="mt-25 mb-55 f-22 color-heading text-adaptive description">
              <p>25% minted - on-chain divination is implemented. Anyone can use this collection to perform on-chain I Ching divination on whatever topic they desire</p>
              <br />
              <p>
                50% minted - on-chain divination tips is implemented. Anyone who uses the on-chain divination can add an additional tip to each divination
                request/transaction. The resulting hexagram owner receives the 90% that tip
              </p>
              <br />
              <p>75% minted - 10% of all on-chain divination tips are donated toward carbon offset of this collection</p>
              <br />
              <p>100% minted - Mystery companion collection is created based on the hexagrams</p>
            </div> */}
          </div>

          <div className="col-xl-10 col-lg-10">
            <div className="mt-40 mb-55 f-22 color-heading text-adaptive description">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-6 col-xs-12">
                  <div className="animated_image border-main">
                    <div dangerouslySetInnerHTML={{__html: token4}}></div>
                  </div>
                  <p className="text-center f-16 mt-10">Diviner Hexagrams #3648</p>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12">
                  <div className="animated_image border-main">
                    <div dangerouslySetInnerHTML={{__html: token5}}></div>
                  </div>
                  <p className="text-center f-16 mt-10">Diviner Hexagrams #1201</p>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12">
                  <div className="animated_image border-main">
                    <div dangerouslySetInnerHTML={{__html: token3}}></div>
                  </div>
                  <p className="text-center f-16 mt-10">Diviner Hexagrams #66</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HexagramSection;
