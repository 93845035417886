/* eslint-disable prefer-const */
import React from 'react';

const TotalSupplySection: React.FC = () => {
  return (
    <section className="pt-50 mt-70 pb-50 mb-70 call_to_action_4 feature_5">
      <div className="container px-xl-0">
        <div className="row justify-content-center">
          <div className="mb-20 mt-20 col-xl-4 col-lg-5 col-md-6 col-sm-10 holder">
            <div className="px-45 pt-40 pb-45 radius10 block bg-white">
              <div className="mb-20 f-40 semibold text-uppercase sp-20 title">
                <span id="txtTotalSupply">?</span>/8192
              </div>
              <div className="color-heading text-adaptive">Total supply</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TotalSupplySection;
