/* eslint-disable prefer-const */
import React from 'react';
export interface Props {
  onClickMint: () => void;
  isAvailable?: boolean;
}

const MintPassSection: React.FC<Props> = ({onClickMint, isAvailable}) => {
  return (
    <section className="pt-20 pb-20 feature_5" id="mintSection">
      <div className="container px-xl-0">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10">
            <h2 className="small">I heard about the mint pass, how does that work?</h2>
            <div className="mt-10 mb-40 f-22 color-heading text-adaptive description">
              <p>
                With each mint pass, you reserve the right to mint a random Diviner Hexagrams Token before the public mint. Each mint pass is called a Creator Hexagram
                Token, and contains a single hexagram, and can be used in any way you'd like.
                <br />
                <br />
                Mint passes will be available [x] days before public Launch. Project Hexagrams (for fortune telling) community members and partner community members will
                enjoy a --% discount on the mint pass!
                <br />
              </p>
            </div>
            <button className="btn md action-3 mb-2" onClick={onClickMint} id="btnMint" disabled>
              Get a mint pass (0.?? ETH + gas)
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MintPassSection;
